import styled from 'styled-components';
import { FlexColumn } from '../../../../globalStyle';

export const GlobalContainer = styled(FlexColumn)`
	width: 100%;
	height: 100%;
	opacity: ${props => (props.highlighted ? '1' : '0.05')};
	margin-bottom: ${props => props.stylePage.marginBottomParaph ? props.stylePage.marginBottomParaph : null};
	justify-content: ${props => props.justifyContent || props.stylePage.justifyContentGlobal};
`;

export const StyleEmojiGlobal = styled.div`
	margin-right: 20px;
`;

export const StyleEmojiGlobalSpan = styled.span`
	font-size: 30px;
`;

export const StyleInformation = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: ${props => props.stylePage.informationHeight && props.stylePage.informationHeight};
	padding: ${props => props.paddingParagraph ? props.paddingParagraph : null};
	position: relative;

	@media (max-width: 1250px) {
		padding: ${props =>
			props.paddingParagraph
				? `${Math.round(props.paddingParagraph.slice(0, 2) / 2)}px 0`
				: null};
	}
`;

export const Separator = styled.div`
	height: 2px;
	width: ${props => props.stylePage.widthSeparator || '90%'};
	background-color: ${props => props.theme.blueGrey};
	margin: ${props => !props.emojiGlobal && (props.stylePage.marginSeparator || '20px auto;')};
	position: ${props => props.emojiGlobal ? 'absolute' : (props.stylePage.positionSeparator || null)};
	bottom: ${props => props.emojiGlobal ? '0' : props.stylePage.bottomSeparator};
	opacity: 0.5;

	@media (max-width: 1250px) {
		width: ${props => props.emojiGlobal && '79%'};
	}
	@media (max-width: 1080px) {
		opacity: ${props => !props.isCongrats && '0'};
		margin: ${props => !props.isCongrats && '1em auto'};
	}
`;

export const WrapperContent = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: ${props => props.margin};

	@media (max-width: 599px) {
		align-items: ${props => props.isIframe && 'center'};
	}
`;

export const StyleSquare = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	margin-right: 1em;
	border-radius: 10px;
	background-image: linear-gradient(
		to bottom,
		${props => props.theme.blueGrey},
		#ffffff
	);
`;

export const Picto = styled.img`
	margin: 0px 4px 0px 4px;
	max-width: ${props => (props.maxWidth ? props.maxWidth : '30px')};

	@media (max-width: 600px) {
		width: 30px;
	}
`;

export const TitleInfo = styled.h4`
	margin: ${props => (props.margin ? props.margin : '5px 0')};
	font-family: ${props => props.theme.mainFontFamily};
	font-size: 15px;
	font-weight: bold;
	line-height: 1.56;
	text-align: left;
	color: ${props => props.theme.darkBlueColor};
	margin-bottom: ${props => props.stylePage.marginTitle};

	@media (max-width: 600px) {
		font-size: 12px;
		margin-bottom: 20px;
	}

	@media (min-width: 599px) and (max-width: 1230px) {
		font-size: 14px;
	}
`;

export const ContainerParagraph = styled.div`
	${props =>
		props.isGrid
			? `
		display: grid;
		grid-template-columns: 50px auto;
		grid-gap: 0 20px;
		align-items: center;
		`
			: `
		display: flex;
		align-items: center;
		justify-content: flex-start;
		`}

	@media (max-width: 1080px) {
		/* justify-content: ${props => (props.isGrid ? 'flex-start' : 'center')}; */
		justify-content: flex-start;
	}
`;

export const Url = styled.a`
	width: 100%;
	cursor: pointer;
	font-family: ${props => props.theme.mainFontFamily};
	text-decoration: underline;
	font-size: 13px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.79;
	text-align: ${props => (props.align ? props.align : 'center')};
	color: ${props => (props.color ? props.color : '#7187a8')};
	margin-top: 3px;
`;

export const SentenceUrl = styled.a`
	width: 100%;
	cursor: pointer;
	font-family: ${props => props.theme.mainFontFamily};
	text-decoration: underline;
	font-size: 14px;
	font-weight: bold;
	color: ${props => (props.color ? props.color : '#7187a8')};
`;

export const ContainerParagraphs = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: ${props => props.stylePage.justifyContentParaph || 'flex-start'};
	width: 100%;
	height: ${props => props.stylePage.height && props.stylePage.height};
`;

export const Sentence = styled.span`
	margin: 0;
	color: ${props => (props.color ? props.color : props.theme.darkBlueColor)};
	font-size: 12px;
	font-family: ${props => props.family ? props.family : props.theme.mainFontFamily};
	font-weight: ${props => props.weight ? props.weight : '500'};
	opacity: ${props => props.opacity ? props.opacity : '1'};
	padding: ${props => props.padding && props.padding};

	@media (min-width: 599px) and (max-width: 1230px) {
		font-size: 12px;
	}

	@media (max-width: 600px) {
		font-size: 12px;
	}
`;

export const ContainerSentences = styled.p`
	margin: 0;
	width: ${props => props.stylePage.widthContainerSentences};
`;

export const ContainerIframe = styled.div`
	height: 292px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(243, 243, 243);

	@media (max-width: 599px) {
		max-width: 220px;
		height: 100%;
		max-height: 180px;
		background-color: unset;
	}
`;
