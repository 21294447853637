import styled from 'styled-components';
import { FlexColumn, DivGlobal } from '../../../globalStyle';

const props = {
	background: String
};

export const Wrapper = styled('div', props)`
	box-sizing: border-box;
	padding:0em 1.3em;
	width: 100%;
	display: ${props => props.screenWidth > 780 ? 'none' : 'flex'};
	@media (min-width: 1050px){
		background-color: ${props => props.background ? 'rgba(138, 208, 134, 0.16)' : 'white'};
		height: 30%;
	}
	@media (max-width: 1080px) {
		padding: 1em 2em 2em 2em;
	}
	@media (max-width: 780px) {
		margin-top: ${props => props.isBook && '0px'};
	}
	@media (max-width: 520px) {
		margin-top: ${props => props.isBook && '0px'};
	}
`;

export const Text = styled.p`
	color: rgba(112,112,112, 0.5);
	font-family: Raleway;
	font-size: 12px;
	font-weight: 200;
	line-height: 16px;
		span {
			color: rgb(112,112,112);
		}
`;

export const TextCheckBox = styled.p`
	margin: 0;
	color: rgba(112,112,112, 0.5);
	font-family: Raleway;
	font-size: 12px;
	font-weight: 200;
	line-height: 16px;
		span {
			color: rgb(112,112,112);
		}
`;

export const Container = styled(FlexColumn, props)`
	height: 100%;

	@media (max-width: 780px) {
		position: relative;
		width: 100%;
	}

`;

export const DivCheckboxText = styled(DivGlobal)`
	align-items: center;
	justify-content: flex-start;
`;
