import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import Navbar from '../components/Navbar/index';
import NavbarTab from '../components/NavbarTab/index';
import styled from 'styled-components';
import { UserContext } from '../components/context/UserContext';
import { getEstimations, generateEstimations, generateCSV } from '../controllers/products/API';
import { getEstimatorInfo } from '../controllers/estimator/API';
import { Grid, Table, Card, Button, Page, Icon, Form, Text, Tag } from "tabler-react";
import { translateOffer } from '../controllers/tools';
import { Fade } from '../globalStyle'
import Lazy from '../pages/Lazy';
import Modal from '../components/modal/index';
import moment from 'moment';
import Fuse from 'fuse.js';
import clearToken from '../controllers/toolsAPI';
import { getCorrectId } from '../controllers/tools';
import DatePicker from '../components/datePicker'


const Wrapper = styled.div`
	width: 90%;
	height: 100%;
	margin: 2em auto auto auto;
	@media (max-width: 800px) {
		overflow-x: auto;
	}
`;

const Date_Wrapper = styled.div`
	display: grid;
	grid: 1fr / auto 1fr;
	grid-gap: 1em;
	align-items: center;
	line-height: 1.375;
`

const ContainerPageIndex = styled.div`
	width: 100px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	small {
		text-align: center;
	}
`

const Div = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : ''};
	align-items: center;
	padding: ${(props) => props.padding ? props.padding : ''};
	justify-content: ${(props) => props.justify ? props.justify : 'flex-start'};
`;

const Dive = styled.div`
	height: 100%;
	overflow: scroll;
	${(props) => {
		return props.showModal ?
			`
				overflow-y: hidden;
				height: 100vh;
			` : ''
	}}
`

const Products = () => {
	const [sortState, setSortState] = useState({
		model: 'desc',
		date: 'desc',
		status: 'desc',
		location: 'desc',
		offer: 'desc',
		created_at: 'asc',
		paid_at: 'asc',
		master_id: 'desc'
	});
	const [user] = useContext(UserContext)
	const [estimator, setEstimator] = useState(null);
	const [products, setProducts] = useState({ data: [], id: 0, currentCount: 0, currentFilteredProducts: [] });
	const [initialProducts, setInitialProducts] = useState([]);
	const [checkedProducts, setChecked] = useState([]);
	const [ready, setReady] = useState(false);
	const [page, setPages] = useState({
		number: 1,
		from: 0,
		to: 30,
		productsPerPage: 30
	})
	const [filterDate, setFilterDate] = useState ({ start: null, end: null, key: 0 });
	const [sortBy, setSortBy] = useState ('created_at')
	const [showModal, setShowModal] = useState({ status: false, data: null });

	useEffect(() => {
		const fetch = async () => {
			try {
				const estimData = await getEstimatorInfo({ id: getCorrectId({ user }) })
				const data = await getEstimations({ user_id: user.role === 'zacker' ? user.id : null });
				setEstimator(estimData);
				setInitialProducts(data.estimations);
				sortProducts({ by: 'created_at', productsToSort: { data: data.estimations, id: 1 } });
				setReady (true)
			} catch (error) {
				clearToken();
			}
		}
		fetch();
	}, [showModal])

	useEffect(() => {
		sortProducts ({ by: 'paid_at', productsToSort: { data: initialProducts, id: products.id + 1 }})
	}, [filterDate, sortBy])

	const handleCheck = ({ id }) => {
		const productToFind = products.data.find((p) => p.id === id);
		const alreadyExist = !!checkedProducts.find((p) => p.id === id);
		setChecked((prev) => {
			if (alreadyExist) {
				return prev.filter((p) => p.id !== id);
			}
			return [...prev, productToFind]
		})
	}
	const selectAll = () => {
		if (checkedProducts.length === products.data.filter((product) => product.status === 'A expédier').length){
			return setChecked([]);
		}
		setChecked(products.data.filter((product) => product.status === 'A expédier'))
	}

	const getImgUrl = ({ category, model }) => {
		if (Object.keys(estimator.images[category]).includes(model)) return estimator.images[category][model];
		let regex = new RegExp (model.slice(0,5), 'i')
		let genericModel = Object.entries(estimator.images[category]).find (([key, value]) => regex.test(key) )
		if (genericModel) return genericModel[1];
		return estimator.images[category][model] || estimator.images[category].default;
	}

	const generateProducts = (products) => {
		const arrToReturn = [];
		products.map((product) => {
			arrToReturn.push(
				<Table.Row key={product.id}>
					<Table.Col>
						<Text.Small>
							<strong>
								{product.product_id.split('_')[0]}
							</strong>
						</Text.Small>
					</Table.Col>
					<Table.Col>
						<img
							style={{maxWidth: '30px'}}
							alt={product.category}
							src={getImgUrl({category: product.category, model: product.model})}
						/>
					</Table.Col>
					<Table.Col>
						<Text.Small>
							{product.created_at}
						</Text.Small>
					</Table.Col>
					<Table.Col>
						<Text.Small>
							{product.model}
						</Text.Small>
					</Table.Col>
					<Table.Col>
						<Text.Small>
							<a href={`https://hellozack.fr/suivi/${product.id}`} target="_blank" >
								{product.status}
							</a>
						</Text.Small>
					</Table.Col>
					<Table.Col>
						<Text.Small>
							{product.location}
						</Text.Small>
					</Table.Col>
					<Table.Col>
						<Text.Small>
							{product.seller.lastname}
						</Text.Small>
					</Table.Col>
					<Table.Col>
						<Text.Small>
							{product.paid_at || '-'}
							{/* {product.current_expedition.master_id} */}
						</Text.Small>
					</Table.Col>
					<Table.Col>
						<Text.Small>
							<strong>{translateOffer({ parameter: estimator.forms.iphone.comission, offer: product.offer})}€</strong>
						</Text.Small>
					</Table.Col>
				</Table.Row>
			)
		});
		return arrToReturn;

	}
	const filterProducts = ({ start_date, end_date, products } = { }) => {
		if (!start_date || !end_date) return products
		if (!start_date) start_date = moment('01-01-1970', 'DD-MM-YYYY')
		if (!end_date) end_date = moment ()
		const filtered_products = products.filter(p => {
			if (p[sortBy] && moment(p[sortBy], 'DD-MM-YYYY').isBetween(start_date, end_date)) return p;
			return false
		})

		return filtered_products
	}

	const sortProducts = ({ by, productsToSort = products, nextPages = page }) => {
		let copyProducts = filterProducts({ products: productsToSort.data, start_date: filterDate.start, end_date: filterDate.end, by: sortBy }) || []
		if(['model', 'status', 'location'].includes(by)){
			copyProducts = copyProducts.sort((a, b) => {
				if (a[by] > b[by]) {
					return {
						desc : -1,
						asc: 1
					}[sortState[by]];
				}
				if (b[by] > a[by]) {
					return {
						desc : 1,
						asc: -1
					}[sortState[by]];
				}
				return 0;
			});
		}
		if(['offer'].includes(by)) {

			copyProducts = copyProducts.sort((a, b) => {
				return {
					desc: a[by] - b[by],
					asc: b[by] - a[by]
				}[sortState[by]]
			})
		}

		if (['master_id'.includes(by)]) {
			copyProducts = copyProducts.sort((a, b) => {
				return {
					desc: a.current_expedition[by] - b.current_expedition[by],
					asc: b.current_expedition[by] - a.current_expedition[by]
				}[sortState[by]]
			})
		}

		if(['created_at', 'paid_at'].includes(by)) {
			copyProducts = copyProducts.sort((a, b) => {
				if (!a[by] || !b[by]) return -1;
				return {
					desc: moment(a[by], 'DD-MM-YYYY').format('x') - moment(b[by], 'DD-MM-YYYY').format('x'),
					asc: moment(b[by], 'DD-MM-YYYY').format('x') - moment(a[by], 'DD-MM-YYYY').format('x')
				}[sortState[by]]
			})
		}

		setSortState((prev) => {
			return {
				...prev,
				[by]: prev[by] === 'desc' ? 'asc' : 'desc'
			}
		});

		return setProducts((prev) => { return {
				data: copyProducts.slice(nextPages.from, nextPages.to),
				// data: filterProducts({ products: copyProducts.slice(nextPages.from, nextPages.to), start_date: filterDate.start, end_date: filterDate.end }),
				id: prev.id + 1,
				currentCount: copyProducts.length,
				currentFilteredProducts: copyProducts
			}
		} );
	}

	const generateTitles = () => {
		return(
			<Table.Header>
				<tr>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						ID
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						LOGO
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						CREE LE
						<Icon name="chevron-down" onClick={() => { sortProducts( { by: 'created_at' }) }} />
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						MODEL
						<Icon name="chevron-down" onClick={() => { sortProducts({ by: 'model' }) }} />
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						STATUS
						<Icon name="chevron-down" onClick={() => { sortProducts({ by: 'status' }) }} />
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						LIEU
						<Icon name="chevron-down" onClick={() => { sortProducts({ by: 'location' }) }} />
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						CLIENT
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						PAYEE LE
						<Icon name="chevron-down" onClick={() => { sortProducts({ by: 'paid_at' }) }} />
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						PRIX
						<Icon name="chevron-down" onClick={() => { sortProducts({ by: 'offer' }) }} />
					</Table.ColHeader>
				</tr>
			</Table.Header>
		)
	}

	const handlePageChange = async ({ next } = { }) => {
		let maxLength = initialProducts.length
		const nextPages = {
			number: next ? page.number + 1 : page.number - 1,
			from: next ? page.from + page.productsPerPage : page.from - page.productsPerPage,
			to: next ? (page.to + page.productsPerPage > maxLength ? maxLength : page.to + page.productsPerPage) : page.to - page.productsPerPage,
			productsPerPage: page.productsPerPage
		}
		setPages(nextPages)
		sortProducts({ productsToSort: { data: initialProducts, id: 1 }, nextPages });
	}

	const handleSearch = (e) => {
		var options = {
			shouldSort: true,
			threshold: 0.1,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 3,
			keys: [
			  "product_id",
			  "model",
			  "seller.lastname",
			  "status",
			  "location"
			]
		  };
		  var fuse = new Fuse(initialProducts, options); // "list" is the item array
		  var result = fuse.search(e.target.value);
		  if (e.target.value.length){
			  setProducts((prev) => { return { data: result, id: prev.id + 1, currentCount: result.length, currentFilteredProducts: result } } )
		  } else {
			  sortProducts({ by: sortBy, productsToSort: { data: initialProducts, id: 1 } })
		  }
	}

	const fieldToExport = [
		{ label: 'id', value: (row) => row['product_id'].split ('_')[0]},
		'created_at',
		'model',
		'status',
		'location',
		{ label: 'seller_firstname', value: 'seller.firstname' },
		{ label: 'seller_lastname', value: 'seller.lastname' },
		{ label: 'seller_mail', value: 'seller.mail' },
		{ loabel: 'seller_phone', value: 'seller.phone'},
		{ label: 'price', value: 'offer' },

	]

	const handleCheckSort = useCallback((box_name) => {
		setSortBy(box_name)
	},[])

	return ready ? (
		<Dive showModal={showModal.status} >
			<Modal data={showModal.data} show={showModal.status} setShowModal={setShowModal} />
			<Navbar />
			<NavbarTab onSearch={handleSearch} isSearchBar />
			<Wrapper>
				<Date_Wrapper>
					<DatePicker filterDate={filterDate} setFilterDate={setFilterDate} />
					<Form.Group>
						<Form.Radio isInline checked={sortBy === 'created_at'} label="Créé le" name="created_at" onClick={() => handleCheckSort('created_at')} />
						<Form.Radio isInline checked={sortBy === 'paid_at'} label="Payé le" name="paid_at" onClick={() => handleCheckSort('paid_at')} />
					</Form.Group>
				</Date_Wrapper>
				<Fade>
					<Grid.Row>
						<Grid.Col lg={12}>
							<Card classNames="card-min">
								<Div padding="0em 1em" justify="space-between">
									<Page.Title>Produits <Tag>{products.currentCount}</Tag></Page.Title>
									<div style={{ display: 'flex'}}>
										<Button
											onClick={() => generateCSV({ jsonToParse: products.currentFilteredProducts, fields: fieldToExport})}
											color="primary" style={{ marginRight: '1em'}}>
												Export CSV
										</Button>
										<ContainerPageIndex>
											{page.from > 0 ? <Button color="secondary" icon="chevron-left" onClick={() => handlePageChange()}/> : <small></small>}
											<Text.Small color="gray">{page.number}</Text.Small>
											{page.to < initialProducts.length ? <Button color="secondary" icon="chevron-right" onClick={() => handlePageChange({ next: true})}/> : <small></small>}
										</ContainerPageIndex>
									</div>
								</Div>
								<Table classNames="card-table table-vcenter">
									{generateTitles()}
									<Table.Body>
										{generateProducts(products.data)}
									</Table.Body>
								</Table>
								<Div padding="0em 1em" justify="center" marginBottom="1em">
									<ContainerPageIndex>
										{page.from > 0 ? <Button color="secondary" icon="chevron-left" onClick={() => handlePageChange()}/> : <small></small>}
										<Text.Small color="gray">{page.number}</Text.Small>
										{page.to < initialProducts.length ? <Button color="secondary" icon="chevron-right" onClick={() => handlePageChange({ next: true})}/> : <small></small>}
									</ContainerPageIndex>
								</Div>
							</Card>
						</Grid.Col>
					</Grid.Row>
				</Fade>
			</Wrapper>
		</Dive>
	) : ( <Lazy template="products" />);
}

export default React.memo(Products);