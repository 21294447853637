import styled from 'styled-components';

export const StyledOfferFeatures = styled.div`
	width: 100%;
	height: 27%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: linear-gradient(
		to bottom,
		#f2f6fc 0%,
		#ffffff 20%,
		#ffffff 80%,
		#f2f6fc 100%
	);

	@media (max-width: 1080px) {
		height: 50%;
	}

	@media (min-width: 319px) and (max-width: 600px) {
		height: 50%;
	}
`;

export const StyledFeaturesList = styled.ul`
	/* margin: 0 auto; */
	margin: auto;
	padding: 20px 0;
	list-style: none;
	width: calc(100% - 40px);
	max-width: 400px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;

export const StyledFeature = styled.li`
	width: 80%;
	margin: 0 auto;
	font-family: ${props => props.theme.mainFontFamily};
	color: ${props => props.theme.darkBlueColor};
	font-size: 13px;
	font-weight: 500;
	line-height: 1.13;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1550px) and (min-width: 1080px), (max-width: 600px) {
		font-size: 12px;
	}
`;
