/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { DivGlobal } from '../../../globalStyle';

export const StyledInputRadioContainer = styled(DivGlobal)`
	cursor: pointer;
	user-select: none;
	background-color: ${props => props.isSelected
		? props.theme.sunnyBlue
		: props.theme.sunnyBlueLessOpacity};
	height: 45px;
	flex-direction: row;
	border-radius: 10px;
	display: flex;
	align-items: center;

	/* If radio input contains an image */
	${props => props.hasImage
		&& css`
		@media (min-width: 1050px){
			margin: ${props.numberOfAnswers > 4 && '5px'};
		}
			width: ${props.numberOfAnswers > 4 ? '146px' : '230px'};
			height: ${props.numberOfAnswers > 4 ? '180px' : '290px'};
			border: solid 2px
				${props.isSelected ? props.theme.sunnyBlue : '#f4f4f4'};
			box-shadow: ${props.isSelected
		? '0 10px 20px 0 rgba(90, 210, 240, 0.2)'
		: 'none'};
			transform: ${props.isSelected ? 'translateY(-12px)' : 'translateY(0px)'};
			transition: transform 0.4s;
			border-radius: 10px;
			flex-direction: column;
			background-color: ${props.theme.whiteColor};

			&:hover {
				border-color: ${props.theme.sunnyBlue};
			}

				@media (max-width: 600px) {
					${props => !props.hasImageDesktop &&
					css`
						height: inherit;
						max-height: 120px;
						padding: 17px 12px 37px 12px;
						max-width: 236px;
						min-width: 117px;
						`}
				}

				@media (max-width: 1049px) {
					${props => props.hasImageDesktop &&
						css`
						transition: 0.4s;
						box-shadow: none;
						transform: translateY(0px);
						width: 100%;
						padding: 0;
						border: none;
						cursor: pointer;
						user-select: none;
						background-color: ${props => props.isSelected
							? props.theme.sunnyBlue
							: props.theme.sunnyBlueLessOpacity};
						height: 45px;
						flex-direction: row;
						border-radius: 10px;
						display: flex;
						align-items: center;
						`}
				}
		`}
`;

export const StyledInputRadio = styled.input`
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
`;

export const StyledLabel = styled.label`
	color: ${props => props.isSelected ? props.theme.whiteColor : props.theme.sunnyBlue};
	font-family: ${props => props.theme.secondFontFamily};
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	margin-bottom: 0;

	/* If radio input contains an image */
	${props => props.hasImage
		&& css`
			color: ${props => props.isSelected ? props.theme.sunnyBlue : props.theme.darkGrey};
			font-family: ${props => props.theme.mainFontFamily};
			margin-bottom: ${props.numberOfAnswers > 4 ? '20px' : '50px'};
			font-size: ${props.numberOfAnswers > 4 ? '15px' : '16px'};
			transform: ${props => (props.isSelected ? 'scale(1.1)' : 'scale(1)')};
			transition: 0.4s all;
			font-weight: 500;

			@media (min-width: 780px) and (max-width: 1049px) {
				margin-bottom: 43px;
			}

			@media (max-width: 600px) {
				${props => !props.hasImageDesktop &&
					css`
						font-size: 12px;
						top: 25px;
						margin-bottom: 29px;
					`}
			}

			@media (max-width: 1049px) {
				${props => props.hasImageDesktop &&
					css`
						transition: 0.4s;
						transform: scale(1);
						margin: 0;
						color: ${props => props.isSelected ? props.theme.whiteColor : props.theme.sunnyBlue};
						font-family: ${props => props.theme.secondFontFamily};
						text-align: center;
						font-size: 16px;
						font-weight: bold;
						cursor: pointer;
					`}
			}

		`}
`;

export const ContainerImg = styled(DivGlobal)`
	display: ${props => (props.displayImg ? 'flex' : 'none')};
`;

export const StyledImgInputRadio = styled.img`
	width: 100%;
	height: 100%;
	transform: ${props => (props.isSelected ? 'scale(1.1)' : 'scale(1)')};
	transition: transform 0.4s;
	/* max-width: 120px; */
	max-width: 210px;

	@media (max-width: 600px) {
		max-width: 110px;
		max-height: 100px;
		width: 81%;
	}
	@media (max-width: 1049px) {
		display: ${props => props.hasImageDesktop && 'none'};
	}
`;
