import React, { useContext, useEffect, useState } from 'react';
import { StyledContainerGrid, StyledBlock, Infos } from './style';
import PropTypes from 'prop-types';
import ContainerTabs from '../form/containers/ContainerTabs/index';
import ContainerProgressBar from '../form/containers/ContainerProgressBar/index';
import ContainerInputs from './container/ContainerInputs/index';
import ContainerCheckbox from './container/ContainerCheckbox/index';
import { PlatformContext } from '../context/PlatformContext';
import { FormContext } from '../context/FormsContext';
import ListContainer from './listContainer/index';
import Conditions from './conditions/index';
import { parseAddress } from '../../controllers/tools';
import styled from 'styled-components';
import inputs from './schemaInputs.js';
import { getUser, getEstimation, postUser, assignEstimation, putPaymentInfos, postBook } from '../../controllers/book/API';

const tabs = [
	{
		id: 1,
		title: 'ESTIMATION',
		highlighted: false,
	},
	{
		id: 2,
		title: 'PAIEMENT',
		highlighted: true,
	}
];

const Button = styled.button`
	height: 10%;
	transition: opacity 0.5s ease 0s;
    background: linear-gradient(270deg, rgb(67, 115, 253) 0%, rgb(46, 78, 176) 100%);
	border: none;
	color: white;
	border-radius: 0 0 30px 30px;
`;

const checkInput = (spec, value, clickOnReceive = false) => {
	if (!spec.mandatory.condition && !value.length) {
		return '';
	}
	let errorToDisplay = '';
	if (spec.mandatory.condition && !value.length && clickOnReceive) {
		errorToDisplay = spec.mandatory.errorMessage;
	} else if (spec.regex && value.length) {
		errorToDisplay = spec.regex.condition.test(value)
			? ''
			: spec.regex.errorMessage;
	} else if (spec.length && value.length) {
		errorToDisplay =			value.length === spec.length.condition ? '' : spec.length.errorMessage;
	} else if (spec.minLength && value.length) {
		errorToDisplay =			value.length >= spec.minLength.condition
			? ''
			: spec.minLength.errorMessage;
	}
	return errorToDisplay;
};



const UserForm = ({ nextAction, offerId }) => {
	const [data, setData] = useState(null);
	const [ready, setReady] = useState(false);
	const [platform] = useContext(PlatformContext);
	const [address, setAddress] = useState(null);
	const [, , checkbox, , cgv] = useContext(FormContext);
	const useFormInput = (initialValue, spec, name) => {
		const [value, setValue] = useState(initialValue);
		const [error, setError] = useState('');
		const [isCorrect, setCorrect] = useState(false);
		const [focused, setFocus] = useState(false);
		const handleBlur = ({ clickOnReceive } = {}) => {
			const err = checkInput(spec, value, clickOnReceive);
			setFocus(false);
			setError(err);
			setCorrect(!(err.length || (spec.mandatory.condition && !value.length)));
			return {
				error,
				isCorrect: !(err.length || (spec.mandatory.condition && !value.length))
			};
		};
	
		const handleChange = (e) => {
			setError('');
			setCorrect(false);
			setValue(e.target.value);
		};
	
		const handleSelect = (event) => {
			setValue(event.e.target.value);
			setAddress(event.e.target.address_components);
			setCorrect(true);
		};
	
		const handleFocus = (e) => {
			e.preventDefault();
			if (!focused) setFocus(true);
		};
		return {
			value,
			setValue,
			setError,
			setCorrect,
			onChange: handleChange,
			onBlur: handleBlur,
			onSelect: handleSelect,
			onFocus: handleFocus,
			error,
			isCorrect,
			focused,
			address
		};
	};
	inputs.lastName.state = useFormInput('', inputs.lastName.spec, 'lastName');
	inputs.firstName.state = useFormInput('', inputs.firstName.spec, 'firstName');
	inputs.mail.state = useFormInput('', inputs.mail.spec, 'mail');
	inputs.address.state = useFormInput('', inputs.address.spec, 'address');
	inputs.phone.state = useFormInput('', inputs.phone.spec, 'phone');
	inputs.moreAddress.state = useFormInput('', inputs.moreAddress.spec, 'moreAddress');
	inputs.serialNumber.state = useFormInput('', inputs.serialNumber.spec, 'serialNumber')

	useEffect(() => {
		const fetch = async () => {
			const res = await getUser({ offerId });
			const { offer, offer_id, model } = await getEstimation({ offerId: offerId }) || {};
			const donne = {
				offer: res.offer || offer || '',
				model: res.model || model,
				offer_id: res.offer_id || offer_id || '',
				userInfos: {
					lastName: res.lastname || '',
					firstName: res.firstname || '',
					mail: res.mail || '',
					adress: '',
					phone: res.phone ? res.phone.replace('+33', '0') : ''
				}
			};
			setData(donne);
			if (donne.userInfos) {
				for (const input of Object.entries(inputs)) {
					if (!input[1].state.value && input[0] !== 'adress') {
						input[1].state.setValue(donne.userInfos[input[0]] || '');
					}
					input[1].state.onBlur();
				}
			}
		}
		fetch();
		setReady(true);
	}, [])

	const sendInfos = async () => {
		const body = {
			firstname: inputs.firstName.state.value,
			lastname: inputs.lastName.state.value,
			phone: inputs.phone.state.value,
			mail: inputs.mail.state.value,
			address_components: parseAddress(inputs.address.state.address)
		};
		body.address_components.address_complement = inputs.moreAddress.state.value;
		const user = await postUser({ body, role: 'user' });
		if (user) {
			await assignEstimation({ userId: user.id, offerIds: [offerId], serialNumber: inputs.serialNumber.state.value });
			await postBook({ body: { offer_id: offerId }, category: 'label' });
			return nextAction();
		}
	};

	const isAllOk = () => {
		let isAllInputsOk = true;
		Object.entries(inputs).map((entry) => {
			entry[1].state.onBlur({ clickOnReceive: true });
			if (!entry[1].state.isCorrect) { isAllInputsOk = false };
		});
		let isAllCheckboxOk = true;
		for (const check of Object.values(checkbox)) {
			if (!check.checked) {
				isAllCheckboxOk = false;
				break;
			}
		}
		if (isAllCheckboxOk && isAllInputsOk && cgv) {
			sendInfos();
		}
		return false;
	}
	return ready && (
		<StyledContainerGrid>
			<StyledBlock platform={platform} position="top">
				<ContainerTabs tabs={tabs} />
				<ContainerProgressBar progressBarProps="50" />
				<ContainerInputs inputs={inputs} />
				<ContainerCheckbox />
			</StyledBlock>
			<StyledBlock platform={platform} position="bottom">
				<Infos>
					<h3>Pourquoi ce prix ?</h3>
					<ListContainer />
				</Infos>
				<Conditions />
				<Button type="submit" onClick={isAllOk}>Valider</Button>
			</StyledBlock>
		</StyledContainerGrid>
	);
};

UserForm.propTypes = {
	nextAction: PropTypes.func
};

export default UserForm;
