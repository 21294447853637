import style from 'styled-components';

export const StyleContainerTabs = style.div`
	width: 100%;
	border: none;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: inherit;
	@media (max-width: 600px) {
		// height: 34px;
		height: 45px;
	}
	@media (min-width: 600px) and (max-width: 1100px){
		height: 45px;
	}
	height: 100%;
	max-height: 53px;
	justify-content: flex-start;

`;

export const StyleTab = style.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	border:none;
	border-top-left-radius: ${props => props.isFirstItem ? '20px' : 'none'};;
	background-color: ${props => props.isHighlighted ? 'inherit' : '#fafafa'};
	border-top-right-radius: ${props => props.isLastItem ? 'inherit' : 'none'};

	@media (max-width: 600px) {
		border-top-left-radius: 0px;
	}
`;

export const StyleRoundedNumber = style.span`
	@media (max-width: 1100px){
		width: 21px;
		font-size: 12px;
		height: 21px;
	}
	@media (min-width: 1101px) {
		width: 30px;
		font-size: 14px;
		height: 30px;
	}

	margin-right: 1em;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-family: ${props => props.theme.secondFontFamily};
	background-color: ${props => props.isHighlighted ? props.theme.sunnyBlue : props.theme.lightGrey};
`;

export const TitleTab = style.p`
	@media (max-width: 600px) {
		font-size: 12px;
	}
	@media (min-width: 600px) and (max-width: 1100px){
		font-size: 14px;
	}
	@media (min-width: 1101px) {
		font-size: 16px;
	}
	margin-top: 1em;
	color: ${props => props.isHighlighted ? props.theme.darkBlueColor : props.theme.lightGrey};
	font-family: ${props => props.theme.mainFontFamily || 'Raleway'};
	font-weight: bold;
`;
