import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledReceiveButton, StyleImage} from './style';
import DotSvg from '../../../../assets/three-dots.svg';
import pictoBack from '../../../../assets/form/back_round_arrow.svg';


const OfferReceiveButton = ({ valueText, goTo, medium, hasPicto, width }) => {
	const [clicked, setClicked] = useState(false);
	const click = () => {
		if (!clicked) { goTo(); }
		setClicked(!clicked);
	};
	return (
		<StyledReceiveButton onClick={click} medium={medium} hasPicto width={width}>
			{clicked ? <img src={DotSvg} alt="loading" style={{ maxWidth: '30px' }} />
				: <span style={{ display: 'flex', alignItems: 'center' }}>
					{valueText}
					{hasPicto && <StyleImage src={pictoBack} />}
				  </span>
			}
		</StyledReceiveButton>
	);
};

export default OfferReceiveButton;

OfferReceiveButton.propTypes = {
	goTo: PropTypes.func,
	price: PropTypes.number,
	medium: PropTypes.bool
};

