import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
	LinkCard,
	Card,
	ProductPhoto,
	ProductIcon,
	TitleCircleDiv,
	InsideTitleCircle,
	CircleImage,
	TitleCardHome,
	ImageBackgroundDown,
	DivCards
} from './styles';
import { DivGlobal } from '../../globalStyle';

import listDevices from './contentCards';

import blue_circle from '../../assets/cards/blue_circle.svg';

const sortDevices = ({ allowDevices }) => {

	return Object.entries(allowDevices).map(([key, device]) => {
		return {
			...device,
			redirect: key,
			name: device.name,
			image: device.image
		}
	})
}

class Cards extends Component {
	state = {
		contentCard: [],
		screenWidth: 0
	};

	componentDidMount() {
		this.setState({ contentCard: sortDevices({ allowDevices: this.props.devices }) });
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	/**
	 * This function update the state with the actual innerWidth
	 * to delete 2 TrustPilot reviews
	 */
	updateWindowDimensions = () => {
		this.setState({ screenWidth: window.innerWidth });
	};

	/**
	 * This function restyle the cards "Autres" within the acutal innerWidth
	 * @param {object} device
	 * @returns {object} the style for the card
	 */
	resizeOthersCard = device => {
		const { screenWidth } = this.state;
		if (screenWidth > 501 && screenWidth < 753 && device.toResize) {
			return {
				width: '483px',
				backgroundSize: 'cover',
				backgroundPositionY: '189px'
			};
		} else if (screenWidth > 383 && screenWidth < 502 && device.toResize) {
			return { width: '366px', backgroundSize: 'cover' };
		} else if (screenWidth > 317 && screenWidth < 384 && device.toResize) {
			return { width: '312px', backgroundSize: 'auto' };
		}
	};

	render() {
		const { contentCard } = this.state;
		const { isRevendre } = this.props;
		return (
			<DivCards isRevendre={isRevendre}>
				{contentCard.map(device => {
					const linkName = device.redirect ? `/estimators/partners/${this.props.partnerName}/${device.redirect}` : 'https://learn2launch.typeform.com/to/G24iKn';
					return (
						<LinkCard key={device.name} href={linkName} target={device.redirect ? null : '_blank'} rel={device.redirect ? null : 'noopener noreferrer'}>
							<Card style={this.resizeOthersCard(device)}>
								<DivGlobal>
									<ProductPhoto src={device.image} alt={device.name} />
									<ProductIcon src={device.icon} alt={device.name + "icon"} />
								</DivGlobal>
								<ImageBackgroundDown
									src={device.background ? device.background : null}
									toResize={!!device.toResize}
								/>
								<TitleCircleDiv>
									<InsideTitleCircle>
										<CircleImage src={blue_circle} alt="cercle_image" />
										<TitleCardHome>
											<span>{device.name}</span>
										</TitleCardHome>
									</InsideTitleCircle>
								</TitleCircleDiv>
							</Card>
						</LinkCard>
					);
				})}
			</DivCards>
		);
	}
}

export default Cards;
