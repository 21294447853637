/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// To replace with real input components
import DropdownInput from '../../DropdownInput';
import { StyleContainerInputs, TextInput } from './style';
import InputRadio from '../../InputRadio/index';

/**
 * Container which render the correct amount of input/buttons according to the questions
 * @param {Object} props object
 * @param {Object} props.arrOfAnswers array of answers
 * @param {String} props.typeInput type of input that will be rendered (radio, checkbox, text)
 * @param {Function} props.handleClick function triggered when user click on answer
 */
const ContainerInputs = ({
	isActive,
	arrOfAnswers,
	typeInput,
	handleClick
}) => {
	const [answers, setAnswers] = useState(arrOfAnswers);
	useEffect(() => {
		setAnswers(arrOfAnswers);
	}, [arrOfAnswers]);

	const updateAnswers = ({ keyAnswer }) => {
		const arrNewAnswers = answers;
		return arrNewAnswers.map((answer) => {
			if (keyAnswer === answer.key) {
				answer = { ...answer, isSelected: !answer.isSelected };
			}
			if (['radio'].includes(typeInput) && keyAnswer !== answer.key) {
				answer = { ...answer, isSelected: false };
			}
			return answer;
		});
	};

	const onClick = ({ answerClicked } = {}) => {
		if (!isActive) { return; }
		setAnswers(updateAnswers({ keyAnswer: answerClicked.key }));
		handleClick({ answerClicked });
	};

	const renderAnswers = ({ answers, typeOfInput }) => {
		if (['dropdown'].includes(typeOfInput)) {
			return <DropdownInput arrOfAnswers={answers} handleClick={handleClick} isActive={isActive} />;
		}
		const arrayToReturn = [];
		const TypeInput = {
			radio: InputRadio,
			radioImage: InputRadio,
			text: TextInput
		}[typeOfInput];

		for (const answer of answers) {
			const answerIndex = answers.indexOf(answer);
			arrayToReturn.push(
				<TypeInput
					key={answer.key}
					inputProps={{
						isActive,
						key: answer.key,
						pathImg: answer.pathImg,
						imgDesktop: answer.imgDesktop,
						value: answer.value,
						isSelected: answer.isSelected,
						handleClick: onClick,
						answerIndex,
						numberOfAnswers: answers.length
					}}
				/>
			);
		}
		return arrayToReturn;
	};

	const isSubtitleInput = answers.find((answer) => {
		return answer.subtitle;
	});

	const defineTypeOfInput = ({ answers, type }) => {
		const isAnswerImage = arrOfAnswers[0].pathImg;
		if (isAnswerImage) {
			return 'radioImage';
		}
		if (answers.length > 3) {
			return 'dropdown';
		}
		return type;
	};

	const type = defineTypeOfInput({ answers, type: typeInput });
	return (
		<StyleContainerInputs
			isSubtitleInput={!!isSubtitleInput}
			isRadioImage={['radioImage'].includes(type)}
			imgDesktop={arrOfAnswers[0].imgDesktop}
			columns={answers.length}
		>
			{renderAnswers({ answers, typeOfInput: type })}
		</StyleContainerInputs>
	);
};

ContainerInputs.propTypes = {
	isActive: PropTypes.bool,
	arrOfAnswers: PropTypes.array,
	typeInput: PropTypes.string,
	handleClick: PropTypes.func
};

export default ContainerInputs;
