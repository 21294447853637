/* eslint-disable import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';
import {
	StyledInputRadioContainer,
	StyledInputRadio,
	StyledImgInputRadio,
	ContainerImg,
	StyledLabel
} from './style';

const InputRadio = ({ inputProps }) => {
	const handleClick = () => {
		inputProps.handleClick({ answerClicked: inputProps });
	};

	return (
		<StyledInputRadioContainer
			hasImage={!!inputProps.pathImg}
			hasImageDesktop={!!inputProps.imgDesktop}
			isActive={inputProps.isActive}
			isSelected={inputProps.isSelected}
			onClick={handleClick}
			numberOfAnswers={inputProps.numberOfAnswers}
		>
			<StyledLabel
				isSelected={inputProps.isSelected}
				hasImage={!!inputProps.pathImg}
				hasImageDesktop={!!inputProps.imgDesktop}
				isActive={inputProps.isActive}
				numberOfAnswers={inputProps.numberOfAnswers}
			>
				{inputProps.value}
			</StyledLabel>
			<StyledInputRadio
				type="radio"
				name={inputProps.value}
				value={inputProps.value}
				isActive={inputProps.isActive}
			/>
			<ContainerImg displayImg={inputProps.pathImg}>
				<StyledImgInputRadio
					src={
						inputProps.pathImg
						&& require(`../../../assets/${inputProps.pathImg}`)
					}
					hasImage={!!inputProps.pathImg}
					hasImageDesktop={!!inputProps.imgDesktop}
					alt={inputProps.value}
					isSelected={inputProps.isSelected}
				/>
			</ContainerImg>
		</StyledInputRadioContainer>
	);
};

export default InputRadio;

InputRadio.propTypes = {
	inputProps: PropTypes.object
};
