/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import {
	StyleInformation,
	TitleInfo,
	ContainerParagraphs,
	ContainerParagraph,
	Sentence,
	ContainerSentences,
	Url,
	Picto,
	Separator,
	StyleSquare,
	WrapperContent,
	GlobalContainer,
	StyleEmojiGlobal,
	StyleEmojiGlobalSpan,
	SentenceUrl,
	ContainerIframe
} from './style';
import StyleBlueButton from '../../BlueButtonInfo';
import PictoWarning from '../../../../assets/picto/picto-warning.png';
import PictoCheck from '../../../../assets/picto/picto-check.png';
import Apple from '../../../../assets/picto/pomme.png';
import Systeme from '../../../../assets/picto/systeme.svg';

const Information = ({
	highlighted,
	title,
	arrContent,
	modalDisplay,
	marginTitle,
	urlColissimo,
	handleURLClick,
	isCongrats,
	stylePage
}) => {
	const onURLClick = (e, link) => {
		e.preventDefault();
		if (link.includes('https://support.hellozack.fr/fr/article/')) {
			handleURLClick(`${link}reader`);
		} else handleURLClick(link);
	};

	const getPicto = ({ path }) => {
		return {
			greenCheck: PictoCheck,
			redWarning: PictoWarning,
			Pomme: Apple,
			Preférences: Systeme
		}[path];
	};

	const generateSentences = ({ sentences }) => {
		const arrOfSentences = [];
		sentences.map((sentence, index) => {
			const {
				color,
				weight,
				padding,
				fontSize,
				family,
				pathPicto,
				text,
				maxWidth,
				opacity,
				url
			} = sentence;
			if (text && url) {
				arrOfSentences.push(
					<SentenceUrl
						key={sentence.id}
						href={url}
						color={color}
						target="_blank"
						rel="noopener noreferrer"
					>
						{text}
					</SentenceUrl>
				);
			} else if (text) {
				arrOfSentences.push(
					<Sentence
						color={color}
						size={fontSize}
						family={family}
						key={sentence.id}
						opacity={opacity}
						weight={weight}
						padding={padding}
					>
						{text}
					</Sentence>
				);
			}
			if (pathPicto) {
				arrOfSentences.push(
					<Picto
						maxWidth={maxWidth}
						src={getPicto({ path: pathPicto })}
						alt={pathPicto}
						key={sentence.id}
					/>
				);
			}
		});
		return arrOfSentences;
	};

	const generateContent = () => {
		const arrOfContent = [];
		arrContent.map((paragraph, index) => {
			const {
				pathPicto,
				sentence,
				type,
				link,
				videoUrl,
				separator,
				emojiSquare,
				emojiGlobal,
				margin
			} = paragraph;
			arrOfContent.push(
				<div key={paragraph.id} style={{ margin: type === 'video' && '20px 0' }}>
					{emojiGlobal ? null : (
						<WrapperContent margin={margin} isIframe={type === 'video'}>
							<ContainerParagraph
								isGrid={emojiSquare ? 1 : 0}
								isLastOne={index === arrContent.length - 1}
								key={paragraph.id}
							>
								{pathPicto ? (
									<Picto src={getPicto({ path: pathPicto })} alt={pathPicto} />
								) : null}
								{emojiSquare ? <StyleSquare>{emojiSquare}</StyleSquare> : null}
								{type === 'text' && (
									<ContainerSentences stylePage={stylePage}>
										{generateSentences({ sentences: sentence })}
									</ContainerSentences>
								)}
								{type === 'url' &&
									((modalDisplay && (
										<Url
											target="_blank"
											rel="noopener noreferrer"
											color={sentence[0].color}
											href={link || sentence[0].link}
											align={sentence[0].align && sentence[0].align}
										>
											{sentence[0].text}
										</Url>
									)) ||
										((!modalDisplay &&
											((link || sentence[0].link) &&
												((link || sentence[0].link).startsWith(
													'https://www.laposte.fr'
												) ||
													sentence[0].text ===
														'Besoin de plus d’informations ?') && (
													<Url
														target="_blank"
														rel="noopener noreferrer"
														color={sentence[0].color}
														href={link || sentence[0].link}
														align={sentence[0].align && sentence[0].align}
													>
														{sentence[0].text}
													</Url>
												))) || (
											<Url
												rel="noopener noreferrer"
												color={sentence[0].color}
												href="true"
												align={sentence[0].align && sentence[0].align}
												onClick={e => onURLClick(e, link || sentence[0].link)}
											>
												{sentence[0].text}
											</Url>
										)))}

								{type === 'video' && (
									<ContainerIframe>
										<iframe
											width="100%"
											// width="-webkit-fill-available"
											height="100%"
											src={videoUrl}
											frameBorder="30px"
											allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
											style={{
												borderRadius: '17px',
												border: '11px solid #b0b7c0',
												backgroundColor: '#b0b7c0'
											}}
										/>
									</ContainerIframe>
								)}
								{type === 'button' && (
									<StyleBlueButton
										type="button"
										value={sentence[0].value}
										pathImg={sentence[0].pathImg}
										redirect={urlColissimo}
										stylePage={stylePage}
									/>
								)}
							</ContainerParagraph>
						</WrapperContent>
					)}
					{separator ? (
						<Separator
							stylePage={stylePage}
							emojiGlobal={!!arrContent[0].emojiGlobal}
							isCongrats={!!isCongrats}
						/>
					) : null}
				</div>
			);
		});
		return arrOfContent;
	};

	return (
		<GlobalContainer
			justifyContent={
				arrContent[0].justifyContent && arrContent[0].justifyContent
			}
			highlighted={highlighted}
			stylePage={stylePage}
		>
			{title && (
				<TitleInfo stylePage={stylePage} margin={marginTitle}>
					{title}
				</TitleInfo>
			)}
			<StyleInformation
				modalDisplay={modalDisplay}
				highlighted={highlighted}
				paddingParagraph={arrContent[0].emojiGlobal && '30px 0'}
				stylePage={stylePage}
			>
				{arrContent[0].emojiGlobal ? (
					<StyleEmojiGlobal>
						<StyleEmojiGlobalSpan>
							{arrContent[0].emojiGlobal}
						</StyleEmojiGlobalSpan>
					</StyleEmojiGlobal>
				) : null}
				<ContainerParagraphs stylePage={stylePage}>
					{generateContent()}
				</ContainerParagraphs>
			</StyleInformation>
		</GlobalContainer>
	);
};

Information.propTypes = {
	highlighted: PropTypes.bool,
	title: PropTypes.string,
	arrContent: PropTypes.array,
	marginTitle: PropTypes.string,
	urlColissimo: PropTypes.string,
	handleURLClick: PropTypes.func,
	modalDisplay: PropTypes.bool
};

export default Information;
