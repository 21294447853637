/* eslint-disable no-continue */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ContainerQuestion from '../ContainerQuestion/index';
import ContainerTabs from '../ContainerTabs/index';
import {
	StyledOuterWrapper,
	StyledInnerWrapper,
	ContainerQuestions
} from './style';
import ContainerNavButtons from '../ContainerNavButtons';

import ContainerProgressBar from '../ContainerProgressBar';
import ContainerOffer from '../ContainerOffer';
import { PlatformContext } from '../../../context/PlatformContext';

const homogenizeFormState = ({ formState, actualSet }) => {
	const objectToReturn = {};
	Object.entries(formState).map((question) => {
		actualSet.arrSchemaQuestions.map((q) => {
			if (question[1].id === q.id) {
				objectToReturn[question[0]] = question[1];
			}
		});
	});
	return objectToReturn;
};

const isAllSetReady = ({ state, actualSet, formState }) => {
	const ready = true;
	for (const q of actualSet.arrSchemaQuestions) {
		if (!state[q.name].value && q.displayCondition) {
			// Look if condition is filled
			const condition = q.displayCondition;
			if (!condition[1].includes(formState[condition[0]].key)) {
				continue;
			}
		}
		if (!state[q.name].value) {
			return false;
		}
	}
	return ready;
};

const ContainerPageQuestions = ({
	offer,
	formState,
	actualSet,
	actualQuestion,
	pastQuestion,
	arrOfQuestions,
	progressBarProps,
	clickOnAnswer,
	handleFAQClick,
	handleTooltipClick,
	onClickReturnOffer,
	isModalOpen,
	nextAction
}) => {
	const [platform, setPlatform] = useContext(PlatformContext);
	useEffect(() => {
		setActualSetState(homogenizeFormState({ formState, actualSet }));
		setShowNext(
			isAllSetReady({
				formState,
				actualSet,
				state: homogenizeFormState({ formState, actualSet })
			})
		);
	}, [actualSet]);
	const [actualSetState, setActualSetState] = useState(
		homogenizeFormState({ formState, actualSet })
	);
	const [showNextButton, setShowNext] = useState(false);

	const onClick = ({ questionId, answerClicked, isNextSet, isBack }) => {
		if (actualSet.arrSchemaQuestions[actualSet.arrSchemaQuestions.length - 1].answers) {
			if (
				Object.values(
					actualSet.arrSchemaQuestions[actualSet.arrSchemaQuestions.length - 1]
						.answers
				)[0] === 'endForm'
			) {
				setShowNext(false);
			}
		}
		if (isNextSet) {
			const formStateCopy = { ...formState };
			let copyActualSetState = { ...actualSetState };
			if (isBack) {
				copyActualSetState = {
					...copyActualSetState,
					[pastQuestion.name]: {
						id: pastQuestion.id,
						questionSetName: formStateCopy[pastQuestion.name].questionSetName,
						value: null,
						key: null
					},
					[actualQuestion.name]: {
						id: actualQuestion.id,
						questionSetName: formStateCopy[actualQuestion.name].questionSetName,
						value: null,
						key: null
					}
				};
				setShowNext(false);
				setActualSetState(copyActualSetState);
			}
			Object.entries(formStateCopy).map((answer) => {
				if (Object.keys(copyActualSetState).includes(answer[0])) {
					formStateCopy[answer[0]] = copyActualSetState[answer[0]];
				}
			});
			clickOnAnswer({
				newFormState: formStateCopy,
				actualSetState: copyActualSetState
			});
			return;
		}
		const question = arrOfQuestions.find(q => q.id === questionId);
		const newState = {
			...actualSetState,
			[question.name]: {
				value:
					answerClicked.value === actualSetState[question.name].value
						? null
						: answerClicked.value,
				key:
					answerClicked.key === actualSetState[question.name].key
						? null
						: answerClicked.key,
				id: question.id,
				questionSetName: actualSet.name
			}
		};
		setActualSetState(newState);

		if (isAllSetReady({ state: newState, actualSet, formState })) {
			if (actualSet.arrSchemaQuestions[0].answers || platform === 'mobile') {
				const isNextPage = Object.values(actualSet.arrSchemaQuestions[0].answers || {})[0] === 'nextPage';
				if (
					isNextPage || platform === 'mobile'
					&& questionId === actualQuestion.id
					&& newState[actualQuestion.name].value
				) {
					return clickOnAnswer({
						questionId,
						answerClicked,
						actualSetState: newState
					});
				}
			}
			return setShowNext(true);
		}
		setShowNext(false);
		if (
			questionId === actualQuestion.id
			&& newState[actualQuestion.name].value
		) {
			clickOnAnswer({ questionId, answerClicked, actualSetState: newState });
		}
	};

	const clickOnNav = ({ isBack, actualSet }) => {
		onClick({ isNextSet: true, isBack });
	};

	const getTooltipContent = (questionName) => {
		if (actualSet.arrInformations.length > 0) {
			for (const information of actualSet.arrInformations) {
				if (information.linkedWith && information.linkedWith.includes(questionName)) {
					return {
						title: information.title,
						content: information.content
					};
				}
			}
		}
	};

	const numberOfAnswersInModel = !!(
		arrOfQuestions[0].name === 'model' && arrOfQuestions[0].choices.length > 4
	);

	const generateQuestions = () => {
		const arrResult = [];
		arrOfQuestions.map((question) => {
			arrResult.push(
				<ContainerQuestion
					show
					tooltipContent={getTooltipContent(question.name)}
					key={question.id}
					actualQuestion={actualQuestion}
					questionSchema={question}
					arrOfAnswers={question.choices}
					typeInput={question.type}
					handleClick={onClick}
					handleTooltipClick={handleTooltipClick}
					handleFAQClick={handleFAQClick}
				/>
			);
		});
		return arrResult;
	};

	const checkLargeWrapper = () => {
		for (const question of arrOfQuestions) {
			const isLarge = question.choices.find(
				choice => choice.subtitle || choice.pathImg
			);
			if (isLarge) {
				return true;
			}
		}
		return false;
	};
	return (
		<StyledOuterWrapper numberOfAnswersInModel={numberOfAnswersInModel}>
			<ContainerTabs />
			<ContainerProgressBar progressBarProps={progressBarProps} />
			{offer && platform !== 'desktop' ? (
				<ContainerOffer offer={offer} onClickReturnOffer={onClickReturnOffer} nextAction={nextAction} />
			) : (
				<StyledInnerWrapper large={checkLargeWrapper()}>
					<ContainerQuestions offer={offer}>{generateQuestions()}</ContainerQuestions>
					<ContainerNavButtons
						handleClick={clickOnNav}
						isPastQuestion={!!pastQuestion}
						isInfo={false}
						displayNext={showNextButton}
						handleFAQClick={handleFAQClick}
						formState={formState}
						isModalOpen={isModalOpen}
					/>
				</StyledInnerWrapper>
			)}
		</StyledOuterWrapper>
	);
};

ContainerPageQuestions.propTypes = {
	arrOfQuestions: PropTypes.array,
	actualQuestion: PropTypes.object,
	pastQuestion: PropTypes.object,
	formState: PropTypes.object,
	progressBarProps: PropTypes.number,
	clickOnAnswer: PropTypes.func,
	actualSet: PropTypes.object,
	handleFAQClick: PropTypes.func,
	handleTooltipClick: PropTypes.func,
	offer: PropTypes.object,
	onClickReturnOffer: PropTypes.func,
	isModalOpen: PropTypes.bool,
	nextAction: PropTypes.func
};

export default ContainerPageQuestions;
