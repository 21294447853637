const FontFaceObserver = require('fontfaceobserver');

const Fonts = () => {
	const raleway = document.createElement('link');
	raleway.href = 'https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap';
	raleway.rel = 'stylesheet';

	const lato = document.createElement('link');
	lato.href = 'https://fonts.googleapis.com/css?family=Lato:400,300,700';
	lato.rel = 'stylesheet';

	document.head.appendChild(raleway);
	document.head.appendChild(lato);

	const Raleway = new FontFaceObserver('Raleway');
	const Lato = new FontFaceObserver('Lato');

	return Promise.all([Raleway.load(), Lato.load()]).then((data) => {
		return data;
	});
};

export default Fonts;
