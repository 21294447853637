/* eslint-disable camelcase */
export const parseAddress = (address_components) => {
	const obj = {
		address: '',
		address_complement: '',
		zipcode: '',
		city: '',
		country: '',
		country_code: ''
	};
	for (const component of address_components) {
		if (component.types.includes('street_number')) { obj.address = `${component.long_name}${component.long_name && obj.address ? ' ' : ''}${obj.address}`; }
		if (component.types.includes('route')) { obj.address = `${obj.address} ${component.long_name}`; }
		if (component.types.includes('locality')) { obj.city = `${component.long_name}`; }
		if (component.types.includes('country')) {
			obj.country = `${component.long_name}`;
			obj.country_code = `${component.short_name}`;
		}
		if (component.types.includes('postal_code')) { obj.zipcode = `${component.long_name}`; }
	}
	return obj;
};

const getFormName = (model) => {
	if (model.includes('iPhone')){ return 'iphone'; }
	if (model.includes('MacBook')){ return 'mac'; }
	if (model.includes('Watch')){ return 'mac'; }
	if (model.includes('iPad')){ return 'mac'; }
}

export const translateOffer = ({ parameter, offer, model, onlyCom }) => {
	if (!parameter) { return offer }
	parameter = parameter.type ? parameter : parameter[getFormName(model)].comission;
	if (!parameter) { return 0 }
	return {
		"variable": (offer * `${
			onlyCom ? parameter.amount / 100
			: 1 - (parameter.amount / 100)}`
			).toFixed(0),
		"fixed": onlyCom ? parameter.amount :(offer - parameter.amount).toFixed(0)
	}[parameter.type];
}

export const getCorrectId = ({ user }) => {
	return {
		zacker: user.is_admin ? user.id : user.partner_admin,
		partner: user.is_admin ? user.partner_admin || user.id : user.partner_admin
	}[user.role]
}
