import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import Navbar from '../components/Navbar/index';
import NavbarTab from '../components/NavbarTab/index';
import { Icon } from 'tabler-react';
import { UserContext } from '../components/context/UserContext'



import { StyledNavButton } from '../components/form/NavButton/style';

import check from '../assets/cancel/check_cancel.svg';

const wordingSchema = {
	// annulation: {
	// 	picto: check,
	// 	title: 'Booking annulé !',
	// 	sentences: [
	// 		{
	// 			content: 'Votre booking a été annulé avec succès suite à vôtre requête. Merci d\'avoir fait confiance à '
	// 		},
	// 		{
	// 			content: 'Hellozack ',
	// 			weight: '700'
	// 		},
	// 		{
	// 			content: 'pour la reprise de votre produit.'
	// 		}
	// 	],
	// 	cta: {
	// 		wording: 'Accueil',
	// 		link: '/'
	// 	}
	// },
	// rebook: {
	// 	picto: check,
	// 	sentences: [
	// 		{
	// 			content: 'Merci pour votre confiance. '
	// 		},
	// 		{
	// 			content: 'Hellozack ',
	// 			weight: '700'
	// 		},
	// 		{
	// 			content: 'fait peau neuve, veuillez faire votre estimation de nouveau sur le lien ci-dessous.'
	// 		}
	// 	],
	// 	cta: {
	// 		wording: 'Revendre',
	// 		link: '/revendre'
	// 	}
	// },
	confirm: {
		picto: check,
		title: 'Reprise confirmée !',
		sentences: [
			{
				content: 'Elle est dorénavant visible dans l\'onglet "Produits"',
				lineBreak: true
			}
		],
		ctas: [
			{
				wording: 'Nouvelle estimation',
				link: '/estimer',
				picto: 'arrow-right'
			},
			{
				wording: 'Produits', 
				link: '/products',
				picto: 'arrow-right'
			}
		]
	},
	confirmDefault: {
		picto: check,
		title: 'Reprise confirmée !',
		sentences: [],
		ctas: [
			{
				wording: 'Nouvelle estimation',
				link: '/estimer',
				picto: 'arrow-right'
			}
			// {
			// 	wording: 'Produits', 
			// 	link: '/products',
			// 	picto: 'arrow-right'
			// }
		]
	},
	// estimationRefused: {
	// 	picto: check,
	// 	title: 'Refus bien pris en compte !',
	// 	sentences: [
	// 		{
	// 			content: 'Nous avons bien pris en compte le refus de notre offre.',
	// 			lineBreak: true
	// 		},
	// 		{
	// 			content: 'N\'hésitez pas à estimer à nouveau, en cliquant sur le bouton ci dessous',
	// 		}
	// 	],
	// 	cta: {
	// 		wording: 'Revendre',
	// 		link: '/revendre'
	// 	}
	// }
};


const CancelContainer = styled.div`
	display: flex;
	height: calc(100% - 120px);
	align-items: center;
	margin: auto;
	width: 57%;
`;

const Div = styled.div`
	width: 100%;
	max-height: 500px;
	padding: 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	box-shadow: 0 10px 20px 0 rgba(67, 115, 253, 0.1);
	border: solid 1px #d4e1f4;
	background-color: #ffffff;
`;

const ContainerCTAs = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const Check = styled.img`
	max-width: 70px;
	object-fit: contain;
	@media (max-width: 600px) {
		max-width: 50px;
	}
`;

const Title = styled.h1`
  font-family: ${props => props.theme.mainFontFamily};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.darkBlueColor};
  @media (max-width: 600px) {
	font-size: 20px;
}
`;

const Text = styled.p`
	text-align: center;
	font-family: ${props => props.theme.mainFontFamily};
	font-size: 20px;
	font-weight: 300;
	color: ${props => props.theme.darkBlueColor};
	@media (max-width: 600px) {
		font-size: 14px;
		line-height: 22px;
	}
`;

const Message = (props) => {
	const { match } = props;
	const { path } = match;
	const [user] = useContext(UserContext);


	const type = {
		confirm : 'confirm'
	}[path.split('/')[1]];

	const { picto, title, sentences, ctas } = wordingSchema[user.role ? type : 'confirmDefault'];

	const handleClick = (link) => {
		props.history.push(link);
	};
	const [ready, setReady] = useState(false);
	useEffect(() => {
		setReady(true);
	}, []);

	const generateText = (arrOfSentences) => {
		const arrToReturn = [];
		for (const sentence of arrOfSentences) {
			arrToReturn.push(
				<span style={{ fontWeight: sentence.weight || '300' }}>
					{sentence.content}
				</span>
			);
			if (sentence.lineBreak) { arrToReturn.push(<br />); }
		}
		return arrToReturn;
	};
	const generateButtons = () => {
		if (!ctas.length) { return null; }
		const arrToReturn = [];
		ctas.map((cta) => {
			arrToReturn.push(
				<StyledNavButton direction="previous" onClick={() => handleClick(cta.link) }>
					<p>{cta.wording}</p>
					<Icon alt="cta" name={cta.picto} style={{ width: '20px' }} />
				</StyledNavButton>
			)
		})
		return arrToReturn;
	}

	const generateContent = () => {
		return (
			<Div>
				{picto ? <Check src={picto} alt="check" /> : null}
				{title ? <Title>{title}</Title> : null}
				<Text>
					{generateText(sentences)}
				</Text>
				<ContainerCTAs>
					{generateButtons()}
				</ContainerCTAs>
			</Div>
		);
	};

	return ready ? (
		<>
			{ user.role ?
				<>
					<Navbar />
					<NavbarTab />
				</>
				: null
			}
			<CancelContainer>
				{generateContent()}
			</CancelContainer>
		</>
	) : null;
};

export default withRouter(Message);
