/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const StyledReceiveButton = styled.button`
	width: 100%;
	height: 80px;
	line-height: 10%;
	cursor: pointer;
	outline: none;
	border: none;
	background-image: linear-gradient(to left, #4373fd, #2e4eb0);
	font-family: ${props => props.theme.secondFontFamily};
	color: #fff;
	font-size: 20px;
	font-weight: 900;
	@media (max-width: 600px) {
		font-size: 12px;
	}

	display: ${props => props.hasPicto && 'flex'};
	justify-content: ${props => props.hasPicto && 'center'};
	align-items: ${props => props.hasPicto && 'center'};

	${props => props.medium
		&& css`
			width: 430px;
			height: 80px;
			border-radius: 15px;
			font-size: 20px;

			@media (max-width: 600px) {
				width: ${props => props.width || '150px'};
				height: 40px;
				border-radius: 10px;
				font-size: 13px;
			}
		`}

		@media (min-width: 599px) and (max-width: 1079px){
			width: 329px;
			height: 55px;
		}
`;

export const StyleImage = styled.img`
	width: 26px;
	margin-left: 20px;
`;
