export default {
	development: {
		baseAPI: 'http://localhost:5000/partner',
		baseUrl: 'http://localhost:5000',
		estimator: 'http://localhost:3003',
		pricing: 'http://localhost:5000/api/v2'
	},
	production: {
		baseAPI: 'https://api.hellozack.fr/partner',
		baseUrl: 'https://api.hellozack.fr',
		estimator: 'https://estimator.hellozack.fr',
		pricing: 'https://api.hellozack.fr/api/v2'
	},
	test: {
		baseAPI: 'http://134.209.194.88:5000/partner',
		baseUrl: 'http://134.209.194.88:5000',
		estimator: 'http://134.209.194.88:3003',
		pricing: 'http://134.209.194.88:5000/api/v2'
	}
}