import React, { useState } from 'react'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './custom_date.css';

import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

const DatePicker = ({ filterDate, setFilterDate }) => {
    const { start, end } = filterDate;

    const [focus, setFocus] = useState (null)


    const handleDateChange = ({ startDate, endDate }) => {
        setFilterDate((previous_dates) => {
            return {
                start: startDate,
                end: endDate,
                key: previous_dates.key + 1
            }
        })
    }

    return (
        <DateRangePicker
            showDefaultInputIcon
            startDatePlaceholderText="Début"
            endDatePlaceholderText="Fin"
            isOutsideRange={() => false}
            showClearDates
            onClose={() => setFocus(null)}
            displayFormat={'DD/MM/YYYY'}
            startDate={start} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={end} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => handleDateChange({ startDate, endDate })} // PropTypes.func.isRequired,
            focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => setFocus(focusedInput)} // PropTypes.func.isRequired,
        />
    );
}

export default DatePicker;