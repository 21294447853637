import React from 'react';
import PropTypes from 'prop-types';
import previous from '../../../assets/form/navButtons/previous.svg';
import next from '../../../assets/form/navButtons/next.svg';

import { StyledNavButton } from './style';

/**
 * NavButton component aims to navigate through the forms going to the previous question or forward
 * @param {Object} props
 * @param {String} props.direction previous or next
 * @param {String} props.handleClick Deal with the click on button
 */
const NavButtons = ({ direction, handleClick, wording }) => {
	const buttonText = direction === 'previous' ? 'Retour' : 'Suite';
	const onClick = () => {
		handleClick({ isBack: direction === 'previous' }); // ['forward', 'previous']
	};

	return (
		<StyledNavButton onClick={onClick} title={buttonText} direction={direction}>
			<p style={{ fontSize: '19px' }}>{wording || buttonText}</p>
			{(!wording && (
				<img
					src={direction === 'previous' ? previous : next}
					alt={buttonText}
					width={direction === 'next' ? '10px' : '20px'}
				/>
			))
				|| null}
		</StyledNavButton>
	);
};

export default NavButtons;

NavButtons.propTypes = {
	wording: PropTypes.string,
	direction: PropTypes.string,
	handleClick: PropTypes.func
};
