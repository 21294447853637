/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyleProgressBar = styled.div`
  width: ${props => props.themeProps.width};
  height: ${props => props.themeProps.height};
  background-color: ${props => props.themeProps.backgroundColor};
`;

export const Indicator = styled.div`
  width: ${props => props.theme.width}%;
  height: 100%;
  background-color: #8ad086;
  transition: 1s;
`;
