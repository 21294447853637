import styled from 'styled-components';

export const Tab = styled.span`
	padding: 1rem 0;
	margin-right: 1.5em;
	cursor: pointer;
	display: flex;
	align-items:center;
	i {
		margin-right: 0.2em;
		color: #9aa0ac;
	}
	${(props) => {
		if (props.active) {
			return (
				`
				border-bottom: 1px solid #467fcf;
				i, span {
					color: #467fcf;
				}
				`
			)
		} else {
			return (
				`
				border: none;
				`
			)
		}
	}}
`;

export const Title = styled.span`
	color :#9aa0ac;
	font-family: Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;
    font-size: .9375rem;
	font-weight: 400;
`;

export const StyledContainerTabs = styled.div`
	display: flex;
	align-items: center;
	max-width: 100%;
`;