/* eslint-disable quotes */
/**
 * Do the correspondance between screen and case question to a specific state
 * First index correspond to the model
 * Second index correspond to the functionnal question
 * Third index correspond to the screen state
 * Fourth index correspond to the case state
 *
 * 1 -> Shiny / Comme neuf
 * 2 -> Or / Comme neuf
 * 3 -> Argent / Fonctionnel
 * 4 -> Bronze / Fonctionnel
 * 5 -> Stallone / Fonctionnel
 * 6 -> HS / HS
 */
module.exports = {
	smartphone: {
		1: {
			1: {
				1: 6,
				2: 6,
				3: 6
			},
			2: {
				1: 6,
				2: 6,
				3: 6
			},
			3: {
				1: 6,
				2: 6,
				3: 6
			}
		},
		2: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 5,
				3: 5
			},
			3: {
				1: 5,
				2: 5,
				3: 5
			}
		},
		3: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 4,
				3: 3
			},
			3: {
				1: 5,
				2: 3,
				3: 2
			}
		}
	},
	tablet: {
		1: {
			1: {
				1: 6,
				2: 6,
				3: 6
			},
			2: {
				1: 6,
				2: 6,
				3: 6
			},
			3: {
				1: 6,
				2: 6,
				3: 6
			}
		},
		2: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 5,
				3: 5
			},
			3: {
				1: 5,
				2: 5,
				3: 5
			}
		},
		3: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 3,
				3: 3
			},
			3: {
				1: 5,
				2: 3,
				3: 2
			}
		}
	},
	watch: {
		1: {
			1: {
				1: 6,
				2: 6,
				3: 6
			},
			2: {
				1: 6,
				2: 6,
				3: 6
			},
			3: {
				1: 6,
				2: 6,
				3: 6
			}
		},
		2: {
			1: {
				1: 6,
				2: 6,
				3: 6
			},
			2: {
				1: 6,
				2: 6,
				3: 6
			},
			3: {
				1: 6,
				2: 6,
				3: 6
			}
		},
		3: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 3,
				3: 3
			},
			3: {
				1: 5,
				2: 3,
				3: 2
			}
		}
	},
	"laptop computer": {
		1: {
			1: {
				1: 6,
				2: 6,
				3: 6
			},
			2: {
				1: 6,
				2: 6,
				3: 6
			},
			3: {
				1: 6,
				2: 6,
				3: 6
			}
		},
		2: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 5,
				3: 5
			},
			3: {
				1: 5,
				2: 5,
				3: 5
			}
		},
		3: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 3,
				3: 3
			},
			3: {
				1: 5,
				2: 3,
				3: 2
			}
		}
	},
	"all in one computer": {
		1: {
			1: {
				1: 6,
				2: 6,
				3: 6
			},
			2: {
				1: 6,
				2: 6,
				3: 6
			},
			3: {
				1: 6,
				2: 6,
				3: 6
			}
		},
		2: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 5,
				3: 5
			},
			3: {
				1: 5,
				2: 5,
				3: 5
			}
		},
		3: {
			1: {
				1: 5,
				2: 5,
				3: 5
			},
			2: {
				1: 5,
				2: 4,
				3: 3
			},
			3: {
				1: 5,
				2: 3,
				3: 2
			}
		}
	},
	"desktop computer": {
		1: {
			3: {
				1: 6,
				2: 6,
				3: 6
			}
		},
		2: {
			3: {
				1: 5,
				2: 5,
				3: 5
			}
		},
		3: {
			3: {
				1: 5,
				2: 3,
				3: 2
			}
		}
	}
};