/* eslint-disable import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleBlueButton, ContainerBlueButton } from './styles';

const BlueButtonInfo = ({ value, pathImg, redirect, stylePage }) => {
	return (
		<ContainerBlueButton stylePage={stylePage}>
			<a href={redirect} download>
				<StyleBlueButton stylePage={stylePage}>
					{value}
					<img src={require(`../../../assets/${pathImg}`)} alt="download_icon" />
				</StyleBlueButton>
			</a>
		</ContainerBlueButton>
	);
};

export default BlueButtonInfo;

BlueButtonInfo.propTypes = {
	value: PropTypes.string,
  pathImg: PropTypes.string,
	redirect: PropTypes.string,
	stylePage: PropTypes.object
};
