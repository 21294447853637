import React from 'react';
import PropTypes from 'prop-types';

import {
	StyledOfferFeatures,
	StyledFeaturesList,
	StyledFeature
} from './style';

import co2Img from '../../../../assets/form/offerCard/co2.svg';
import checkMarkImg from '../../../../assets/form/offerCard/check-mark.svg';
import expertImg from '../../../../assets/form/offerCard/expert.svg';
import lockedImg from '../../../../assets/form/offerCard/locked.svg';
import creditCardImg from '../../../../assets/form/offerCard/credit-card.svg';

const Feature = ({ children, pic }) => {
	return (
		<StyledFeature>
			<img src={pic} alt="icône plus-value" />
			{children}
			<img src={checkMarkImg} alt="coche verte" />
		</StyledFeature>
	);
};

const OfferFeatures = ({ score }) => {
	const categories = ['iphone', 'ipad', 'mac', 'watch', 'imac'];

	const categoriesCo2 = {
		iphone: 70,
		ipad: 180,
		watch: 9,
		mac: 330,
		imac: 4200
	};

	const scoreCategory = parseInt(score.charAt(0), 10) - 1;
	const category = categories[scoreCategory];

	return (
		<StyledOfferFeatures>
			<StyledFeaturesList>
				<Feature pic={co2Img}>
					{categoriesCo2[category]}
					 kg de CO2 économisés
				</Feature>
				<Feature pic={expertImg}>Un expert dédié</Feature>
				<Feature pic={lockedImg}>Vos données effacées</Feature>
				<Feature pic={creditCardImg}>Un paiement sous 10 jours</Feature>
			</StyledFeaturesList>
		</StyledOfferFeatures>
	);
};

export default OfferFeatures;

Feature.propTypes = {
	pic: PropTypes.string,
	children: PropTypes.node,
	score: PropTypes.string
};
