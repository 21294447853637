/* eslint-disable no-unused-vars */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
const bm_states = require('./bm_states');
const brands = {
	apple: 1,
	samsung: 2,
	huawei: 3,
	sony: 4,
	xiaomi: 5,
	nokia: 6,
	lg: 7,
	htc: 8,
	motorola: 9,
	oneplus: 'a',
	google: 'b',
	asus: 'c',
	acer: 'd',
	wiko: 'e',
	lenovo: 'f',
	blackberry: 'g',
	beats: 'h',
	bose: 'i',
	'canon': 'j',
	'compaq': 'k',
	'dell': 'l',
	'fujifilm': 'm',
	'gopro': 'n',
	'honor': 'o',
	'hp': 'p',
	'ibm': 'q',
	'jbl': 'r',
	'kodak': 's',
	'leica': 't',
	'microsoft': 'u',
	'nikon': 'v',
	'olympus': 'w',
	'oppo': 'x',
	'panasonic': 'y',
	'parrot': 'z',
	'philips': 'A',
	'polaroid': 'B',
	'sennheiser': 'C',
	'toshiba': 'D'
}

const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';


const translateOldToNewCategory = (category) => {
	return {
		'iphone': 'smartphone',
		'ipad': 'tablet',
		'mac': 'laptop computer',
		'imac': 'all in one computer',
		'macmini': 'desktop computer'
	}[category] || category;
}

/**
 * Define the state of the device according to the response of the user
 * @param {String} screen_state corresponding key of the response
 * @param {String} case_state corresponding key of the response
 * @param {String} device category of device
 * @param {String} isFunctionnal corresponding key of the response
 * @return {Number} correspond to the actual state of the device estimated
 * @example defineState("3", "3", "iphone", "3") : 2
 */
const defineState = ({screen_state = 3, case_state = 3, device, isFunctionnal = 3 }) => {
	if (['iphone','ipad','imac','mac','macmini'].includes(device)) {
		device = translateOldToNewCategory(device);
	}
	return isFunctionnal === '1' ? 6 : bm_states[device][isFunctionnal][screen_state][case_state];
};

/**
 * Return a number with the length defined on parameter
 * @param {Object} parameter object
 * @param {Number} parameter.number
 * @param {Number} parameter.length
 */
const number_to_score = ({ number, length } = { }) => {
	let s = '';
	for (let i = 0; i < length; i += 1) {
		const x = ((number) && (31 << (i + 0) * 5)) >> i * 5;
		s += characters[x] || '.';
	}
	return [...s].reverse().join('');
};

const getTo = ({ toSchema, stateForm }) => {
	if (!toSchema) { return null; }
	if (!toSchema.condition) { return toSchema.name; }
	const conditionFilled = stateForm[toSchema.condition.pattern[0]].key === toSchema.condition.pattern[1];
	return conditionFilled && toSchema.condition.name.patternTrue || toSchema.condition.name.patternFalse;
};

/**
 * Generate the score to send to pricing API in order to have a price
 * @param {Object} parameter object
 * @param {String} parameter.category type of device estimated (iphone, mac, ipad ...)
 * @param {Object} parameter.keys keys corresponding to the answers
 * @param {Boolean} parameter.ssd tells if capacity is in ssd
 * @param {Boolean} parameter.hdd tells if capacity is in hdd
 * @return {Number} score
 */
export const buildScore = ({ category, state, formSchema, brand = 'apple' } = { }) => {
	const order = { };
	for (const element of [
		'category',
		'state',
		'model',
		'version',
		'year',
		'ssd',
		'hdd',
		'color',
		'cellular',
		'touchbar',
		'screen_size',
		'frequency',
		'ram',
		'material',
		'accessories',
		'accessories',
		'accessories',
		'brokens',
		'brokens'
	]) {
		order[element] = 0;
	}
	order.category = {
		smartphone: 1,
		tablet: 2,
		"laptop computer": 3,
		watch: 4,
		"desktop computer": 6,
		"all in one computer": 5
	}[category] || category;
	order.state = defineState({
		screen_state: (state.screenState || {}).key,
		case_state: (state.caseState || {}).key,
		device: category,
		isFunctionnal: (state.functionnal || {}).key
	});
	order.accessories = '000';
	order.brokens = '00';
	formSchema.map((set) => {
		set.arrSchemaQuestions.map((schemaQuestion) => {
			const to = getTo({ questionState: state[schemaQuestion.name], toSchema: schemaQuestion.to, stateForm: state });
			if (!to) { return schemaQuestion; }
			order[to] = state[schemaQuestion.name].key || '0';
		});
	});

	// Object.keys(keys).map((identifier) => {
	// 	if (/[0-9]/.test(identifier)) {
	// 		keys[identifier.replace(/_?[0-9]+/g, '')] = keys[identifier];
	// 		delete keys[identifier];
	// 	}
	// 	return identifier;
	// });

	// // Define BM state
	// order.state = defineState(keys.screen_state, keys.case_state, category, keys.fonctionnel);
	// order.accessories = '000';
	// order.brokens = '00';

	// for (let key of Object.keys(keys)) {
	// 	if (key === 'capacity') {
	// 		order[ssd && 'ssd' || 'hdd'] = keys[key];
	// 		// ssd && order.ssd = keys[key];
	// 		// hdd && order.hdd = keys[key];
	// 	} else if (key === 'date') {
	// 		order.year = keys[key];
	// 	} else if (key === 'accessories') {
	// 		key = number_to_score({
	// 			number: keys[key],
	// 			length: 3
	// 		});
	// 		order.accessories = key;
	// 	} else if (key === 'promo_code' || key === 'icloud') { continue; } else {
	// 		key !== 'code' && (order[key] = keys[key] || 0);
	// 	}
	// }
	// delete order.screen_state;
	// delete order.case_state;
	// delete order.fonctionnel;

	let result = '';
	for (const key of Object.keys(order)) {
		result += (order[key]);
	}
	result = `${brand && Object.keys(brands).includes(brand) ? brands[brand] : 0}${result}`
	return result;
};

// module.exports = {
// 	buildScore
// };
