import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../components/Navbar/index';
import NavbarTab from '../components/NavbarTab/index';
import styled from 'styled-components';
import UserForm from '../components/userForm/index';
import { withRouter } from 'react-router';
import { Fade } from '../globalStyle';
import { UserContext } from '../components/context/UserContext';

const Wrapper = styled.div`
	width:100%;
	position: relative;
	height: calc(100% - 150px);
	margin-top: 16.5px;
	display: flex;
	justify-content: center;
`;

const ContainerBook = styled.div`
	position: absolute;
	max-width: 1330px;
	height: 100%;
	width: 100%;
`;

const Book = ({ history, match }) => {
	const [user] = useContext(UserContext);
	const nextAction = () => {
		history.push('/confirm');
	}
	return (
		<Fade>
			{user.role &&
				<>
					<Navbar />
					<NavbarTab />
				</>
			}
			<Wrapper>
				<UserForm nextAction={nextAction} offerId={match.params.offerId} />
			</Wrapper>
		</Fade>
	);
}

export default withRouter(Book);