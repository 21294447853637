import React, { useContext, useEffect, useState,  } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';
import { ThemeProvider } from 'styled-components';
import { FormProvider } from './components/context/FormsContext';


import { UserContext } from './components/context/UserContext';
import { GlobalStyle } from './globalStyle';
import Font from './Font';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Estimator from './pages/Estimator';
import Products from './pages/Products';
import Invoices from './pages/Invoices';
import Book from './pages/Book';
import Device from './pages/Device';
import Message from './pages/Message';
import Lazy from './pages/Lazy';
import clearToken from './controllers/toolsAPI';

const theme = {
	whiteColor: '#ffffff',
	mainBackground: '#f3f3f3',
	darkBlueColor: '#142b4a',
	darkGrey: '#707070',
	sunnyBlue: '#4373fd',
	sunnyBlueLessOpacity: 'rgba(67,115,253,0.05)',
	lightGrey: '#d3d3d3',
	mainFontFamily: 'Raleway',
	secondFontFamily: 'Lato',
	blueGrey: '#d4e1f4',
	greenGrey: '#7187A8'
};

const App = () => {
  const [ready, setReady] = useState(false);
  const [user, setUser] = useContext(UserContext);
  useEffect(() => {
    const token = localStorage.getItem('partnerToken');

		axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    Font();
    setReady(true)
  }, [user])
  return ready && (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      <GlobalStyle />
        <Router>
          <Switch>
            <Route path='/logout' exact render={() => clearToken()} />
            <Route path='/login' exact component={Login} />
            <Route path='/sign-up' exact component={Login} />
            <Route path='/confirm' exact component={Message} />
            <Route
            path='/'
            exact
            render={() => {
              return (
                user.role ?
                <Home />
                : <Redirect to="/login" />
              )
            }} />
            <Route
            path='/formulaire/:offerId'
            exact
            render={() => {
              return (
                <ThemeProvider theme={theme}>
                  <FormProvider>
                    <Book />
                  </FormProvider>
                </ThemeProvider>
              )
            }} />
            <Route
            path='/estimer'
            exact
            render={() => {
              return (
                user.role ?
                <ThemeProvider theme={theme}>
                  <Device />
                </ThemeProvider>
                : <Redirect to="/login" />
              )
            }} />
            <Route
            path='/estimators/partners/:partnerName/:formName*'
            exact
            render={() => {
              return (
                <ThemeProvider theme={theme}>
                  <Estimator />
                </ThemeProvider>
              )
            }} />
            <Route
            path='/products'
            exact
            render={() => {
              return (
                user.role ?
                <ThemeProvider theme={theme}>
                  <Products empty={false} />
                </ThemeProvider>
                : <Redirect to="/login" />
              )
            }} />
            <Route
            path='/invoices'
            exact
            render={() => {
              return (
                user.role ?
                <Invoices />
                : <Redirect to="/login" />
              )
            }} />
          </Switch>
        </Router>
    </div>
  );
}

export default App;
