import React, { useState, useContext } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Formik } from "formik";
import { LoginPage as TablerLoginPage, Alert } from "tabler-react";
import { UserContext, PartnersContext } from '../components/context/UserContext';

import { withRouter } from "react-router"


import "tabler-react/dist/Tabler.css";

import { signIn, getPartnersAdmin } from '../controllers/authentication/API';

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	> div {
		width: 400px;
		margin: 0;
	}
`

const generateErrorMessage = (status) => {
	return {
		403: {
			type: 'danger',
			content: "Accès refusé"
		},
		401: {
			type: 'danger',
			content: "Les identifiants ne correspondent pas"
		},
		200: {
			type: 'success',
			content: "Authentification réussie !"
		},
		500: {
			type: 'danger',
			content: "Une erreur s'est produite"
		}
	}[status] || ''
}

const Login = ({ history }) => {
	const [, setUser] = useContext(UserContext);
	const [, setPartners] = useContext(PartnersContext);
	const [clicked, setClicked] = useState(false);
	const [alertMessage, setAlertMessage] = useState({type: null, content: ''});
	const handleLogin = ({ email, password }) => {
		const fetch = async () => {
			const res = await signIn({ email, password });
			if (res.status !== 200){
				return setAlertMessage(generateErrorMessage(res.status))
			}
			axios.defaults.headers.common.Authorization = `Bearer ${res.headers['x-access-token']}`;
			if (res.data.user.role === 'zacker') {
				const partners = await getPartnersAdmin()
				setPartners(partners.data.partners)
				res.data.user = {
					...partners.data.partners[0],
					role: 'zacker'
				}
			}
			if (res.status === 200) {
				window.localStorage.setItem('partnerToken', res.headers['x-access-token']);
				window.localStorage.setItem('partnerRole', res.data.user.role);
				window.localStorage.setItem('partnerId', res.data.user.id);
				window.localStorage.setItem('is_admin', res.data.user.is_admin);
				window.localStorage.setItem('partner_admin', res.data.user.partner_admin || false);
				window.localStorage.setItem('partners_whitelist', res.data.user.partners_whitelist || []);

				setUser({
					token: res.headers['x-access-token'],
					role: res.data.user.role,
					email: res.data.user.email,
					id: res.data.user.id,
					is_admin: res.data.user.is_admin,
					partner_admin: res.data.user.partner_admin || false,
					partners_whitelist: res.data.user.partners_whitelist || []
				});
				history.push('/');
			}
		}
		setAlertMessage({
			type: 'primary',
			content: 'Requete en cours'
		})
		fetch();
	}
	return (
		<Wrapper>
			<Formik
				initialValues={{
					email: "",
					password: "",
				}}
				validate={values => {
					// same as above, but feel free to move this into a class method now.
					let errors = {};
					if (!values.email) {
						errors.email = "Champs requis";
					} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
						errors.email = "Format invalide";
					}
					return errors;
				}}
				onSubmit={(
					values,
					{ setSubmitting, setErrors /* setValues and other goodies */ }
				) => {
					handleLogin({ email: values.email, password: values.password })
				}}
				render={({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					
						<TablerLoginPage
							logoUrl="logo.png"
							strings={{
								title: 'Connectez vous',
								emailLabel: 'Email',
								emailPlaceholder: 'Entrez votre email',
								passwordLabel: 'Mot de passe',
								passwordPlacehoder:'Entrez votre mot de passe'
							}}
							onSubmit={handleSubmit}
							onChange={handleChange}
							onBlur={handleBlur}
							values={values}
							errors={errors}
							touched={touched}
						/>
				)}
			/>
			{alertMessage.content.length
				? <Alert type={alertMessage.type} isDismissible className="Alert">{alertMessage.content}</Alert>
				: null
			}
		</Wrapper>
	);
}

export default withRouter(Login);