import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import Navbar from '../components/Navbar/index';
import NavbarTab from '../components/NavbarTab/index';
import styled from 'styled-components';
import {
	Page,
	// Avatar,
	// Icon,
	Grid,
	// Card,
	// Text,
	// Table,
	// Alert,
	// Progress,
	// colors,
	// Dropdown,
	// Button,
	StampCard,
	StatsCard,
	// ProgressCard,
	// Badge,
  } from "tabler-react";
import { Fade } from '../globalStyle';
import Lazy from './Lazy';
import { getEstimations } from '../controllers/products/API';
import { getInvoices } from '../controllers/invoices/API';
import { getEstimatorInfo } from '../controllers/estimator/API';
import { UserContext } from '../components/context/UserContext';
import { translateOffer } from '../controllers/tools';
import clearToken from '../controllers/toolsAPI';
import { getCorrectId } from '../controllers/tools'
import DatePicker from '../components/datePicker';




const Wrapper = styled.div`
	width: 90%;
	margin: auto;
`;

const Indicator = styled.p`
	text-align: right;
	font-size: 13px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: ${props => props.isPositive ? 'green' : 'red'};
`;

const KPI = styled.p`
	text-align: center;
	font-size: 40px;
	font-weight: 700;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
`;

const Legend = styled(KPI)`
	font-weight: 400;
	font-size:16px;
`;


const Home = () => {
	const [filterDate, setFilterDate] = useState ({ start: null, end: null, key: 0 })
	const [estimations, setEstimations] = useState ([])
	const [estimData_, setEstimData] = useState (null)
	const [totalCom, setTotalCom] = useState (0)
	const [user] = useContext(UserContext);
	const [stats, setStats] = useState({
		estimations: {
			booked: {
				total: 0,
				currentMonth: 0
			},
			sold: {
				total: 0,
				currentMonth: 0
			}
		},
		comissions: {
			total: 0,
			currentMonth: 0
		},
		kgs: {
			total:0,
			currentMonth: 0
		}
	})
	const [ready, setReady] = useState(false);
	const buildStats = (arrOfEstimations, estimData, total_com) => {
		try {
			const stats = arrOfEstimations.reduce((total, estim) => {
				let { start, end } = filterDate;
				if (start && end && !moment(estim.created_at, 'DD-MM-YYYY').isBetween(moment(start), moment(end))) return total;
				let isCurrentMonth = moment(estim.created_at, 'DD-MM-YYYY').isBetween(moment().startOf('month'), moment().endOf('month'));

				total.estimations[estim.db_status].total += 1;
				total.estimations[estim.db_status].currentMonth += isCurrentMonth ? 1 : 0;
				if (estim.current_expedition && estim.current_expedition.status === 'retrieved') {
					total.estimations.retrieved = total.estimations.retrieved + 1;
				}

				if (estim.db_status !== 'sold') return total

				total.kgs.total += {
					"smartphone": 0.2,
					"laptop computer": 2,
					"tablet": 0.6,
					"watch": 0.03
				}[estim.category]
				total.kgs.currentMonth += isCurrentMonth ? {
					"smartphone": 0.2,
					"laptop computer": 2,
					"tablet": 0.6,
					"watch": 0.03
				}[estim.category] : 0;

				if (total_com) {
					total.comissions.total = total_com
				} else {
					total.comissions.total += translateOffer({ parameter: estimData.forms, offer: estim.offer, model: estim.model, onlyCom: true }) / 1;
				}
				total.comissions.currentMonth += isCurrentMonth ? translateOffer({ parameter: estimData.forms, offer: estim.offer, model: estim.model, onlyCom: true }) / 1 : 0;
				return total;
			}, {
				estimations: {
					booked: {
						total: 0,
						currentMonth: 0
					},
					sold: {
						total: 0,
						currentMonth: 0
					},
					retrieved: 0
				},
				comissions: {
					total: 0,
					currentMonth: 0
				},
				kgs: {
					total:0,
					currentMonth: 0
				}
			});
			setStats(stats);
			setReady(true);
		} catch (error) {
			console.log('Error in home', error);
			return clearToken();
		}
	}
	useEffect(() => {
		const fetch = async () => {
			const fetchEstimations = await getEstimations({ user_id: user.role === 'zacker' ? user.id : null });
			setEstimations (fetchEstimations.estimations)

			const estimData = await getEstimatorInfo({ id: getCorrectId({ user }) })
			setEstimData(estimData)

			const invoices = ['1243'].includes(user.id) ? await getInvoices ({ user_id: user.role === 'zacker' ? user.id : null }) : null

			let total_com = invoices && Object.values (invoices).reduce ((total, models) => {
				for (let details of Object.values (models)) {
					total += details.total_comission_ht
				}
				return total;
			}, 0)
			setTotalCom(total_com)
			buildStats(fetchEstimations.estimations, estimData, total_com);
		}
		fetch();
		setTimeout(() => {
			setReady(true);
		}, 1000)
		return;
	}, [])

	useEffect (() => buildStats(estimations, estimData_, totalCom), [filterDate])

	return ready ? (
		<>
			<Navbar />
			<NavbarTab />
			<Wrapper>
				<Page.Content title="Dashboard">
				<DatePicker filterDate={filterDate} setFilterDate={setFilterDate} />
					<Grid.Row cards={true}>
						<Grid.Col sm={6} lg={3}>
							<StampCard
							color="green"
							icon="shopping-cart"
							header={
								<span>
								{stats.estimations.booked.total} <small>Etiquettes générées</small>
								</span>
							}
							// footer={`${stats.estimations.booked.total} au total`}
							/>
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<StampCard
							color="blue"
							icon="check-circle"
							header={
								<span>
								{stats.estimations.sold.total} <small>Produits achetés</small>
								</span>
							}
							// footer={`${stats.estimations.sold.total} au total`}
							/>
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<StampCard
							color="green"
							icon="dollar-sign"
							header={
								<span>
								{stats.comissions.total.toFixed(2)} €<small> de comissions</small>
								</span>
							}
							// footer={`${stats.comissions.total.toFixed(2)} au total`}
							/>
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<StampCard
							color="yellow"
							icon="sun"
							header={
								<span>
								{stats.kgs.total.toFixed(2)} Kgs <small>recyclés</small>
								</span>
							}
							// footer={`${stats.kgs.total.toFixed(2)} kgs au total`}
							/>
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<StampCard
							color="yellow"
							icon="package"
							header={
								<span>
								{stats.estimations.retrieved} <small>Produits reçus</small>
								</span>
							}
							// footer={`${stats.kgs.total.toFixed(2)} kgs au total`}
							/>
						</Grid.Col>
					</Grid.Row>
				</Page.Content>
			</Wrapper>
		</>
	) : (<Lazy template="home" />);
}

export default Home;
