import React from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar/index';
import NavbarTabs from '../components/NavbarTab/index'
import LazyProducts from '../components/lazy/LazyProducts';
import LazyHome from '../components/lazy/LazyHome';
import LazyInvoices from '../components/lazy/LazyInvoices';


const Lazy = ({template}) => {
	
	return (
		<>
			<Navbar empty/>
			<NavbarTabs empty/>
			{
				{
					'products': <LazyProducts />,
					'home': <LazyHome />,
					'invocies': <LazyInvoices />
				}[template]	|| <LazyProducts />
			}
			{/* <Products empty /> */}
		</>
	);
}

export default Lazy;