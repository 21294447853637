import styled from 'styled-components';

const attr = {
	justify: String,
	wrap: String,
	align: String,
	opacity: String,
	size: String
};

export const Infos = styled.div`
	padding: 1em;
	box-sizing: content-box;
	border-radius: 20px;
	height: 100%;
	width: 100%;
	background-color: #ffffff;
`;

export const Offer = styled(Infos)`
	margin-bottom: 20px;
	height: 100px;
	background: linear-gradient(
			to top,
			rgba(67, 115, 253, 0.9) 0%,
			rgba(90, 210, 240, 0.95) 100%
		),
		url(/static/media/header_light.97e68327.png) center no-repeat;
	opacity: 0.95;
`;

export const Cards = styled.div`
	height: 45%;
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (min-width: 780px) and (max-width: 1049px) {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 10px;
	}

	@media (max-width: 779px) {
		flex-direction: column;
		align-items: center;
	}

	/* @media (min-width: 1050px) {
		margin-top: 20px;
	} */
`;

export const InputsContainer = styled.div`
	min-height: 270px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	height: 100%;

	@media (min-width: 1050px) {
		height: 270px;
		/* height: 325px; */
	}
`;

export const Title = styled.h2`
	margin-right: 3em;
	color: ${props => props.theme.darkBlueColor};
	font-family: Lato;
	font-size: 25px;
	font-weight: 700;
	margin-top: 0;

	@media (min-width: 779px) and (max-width: 1049px) {
		margin-bottom: 10px;
		margin-top: 0;
	}

	@media (min-width: 1050px) and (max-width: 1390px) {
		margin: 0 3em 0 0;
	}

	@media (max-width: 779px) {
		margin-right: 0;
		margin-bottom: 10px;
		margin-top: 0;
	}
`;

export const SubTitle = styled.h3`
	color: ${props => props.theme.darkBlueColor};
	font-family: Raleway;
	font-size: 16px;
	font-weight: 300;
	margin-top: 0;

	@media (max-width: 1049px) {
		margin-top: 0;
	}
	@media (max-width: 779px) {
		text-align: center;
	}

	@media (min-width: 1050px) and (max-width: 1390px) {
		margin-bottom: 0;
		max-width: 300px;
	}
`;

export const Flex = styled('div', attr)`
	display: flex;
	justify-content: ${attr => (attr.justify ? attr.justify : 'space-between')};
	flex-direction: ${attr => (attr.direction ? attr.direction : 'row')};
	align-items: ${attr => (attr.align ? attr.align : 'center')};
	flex-wrap: ${attr => (attr.wrap ? attr.wrap : 'wrap')};

	@media (min-width: 780px) and (max-width: 1049px) {
		width: 55%;
		padding: 15px;
	}

	@media (max-width: 779px) {
		width: 100%;
	}

	@media (min-width: 1050px) {
		height: 236px;
	}

	/* @media (min-width: 1050px) and (max-width: 1202px) {
		height: 229px;
	} */

	@media (max-width: 1049px) {
		height: 100%;
	}
`;

export const FlexInput = styled(Flex)`
	height: unset;
	padding-left: 0.7em;
	margin-top: -4px;
	margin-bottom: 7px;

	@media (max-width: 1049px) {
		align-items: baseline;
		width: 100%;
		margin-right: 0;
		padding: 0;
		position: relative;
	}
	@media (min-width: 1050px) {
		height: 25px;
	}
`;

export const Legend = styled.legend`
	padding-inline-end: 15px;
	padding-inline-start: 15px;
	color: #707070;
	font-family: Raleway;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
`;

export const TextInput = styled.input`
	width: calc(100% - 30px);
	border: none;
	color: black;
	font-family: Lato;
	font-size: ${attr => (attr.size ? attr.size : '16px')};
	font-weight: 500;
	line-height: 16px;
	margin-left: 1em;
	outline: none;
	::placeholder {
		font-size: 16px;
		color: #707070;
		opacity: 0.3;
	}
`;

export const Asterix = styled.span`
	color: #d4e1f4;
	font-family: Raleway;
	font-size: 18px;
	font-weight: 700;
	line-height: 18px;
`;

export const Error = styled.span`
	display: inline-block;
	margin-top: 8px;
	color: #ff1673;
	font-family: Lato;
	font-size: 11px;
	font-weight: 500;

	@media (max-width: 1049px) {
		position: absolute;
    bottom: -16px;
	}
`;

export const DivTablet = styled.div`
	@media (max-width: 1049px) {
		display: flex;
		overflow: hidden;
		border-bottom-right-radius: 30px;
	}

	@media (min-width: 780px) and (max-width: 1049px) {
		height: 1050px;
	}

	@media (max-width: 600px) {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	height: 100%;
`;

export const DivTabletInfo = styled.div`
	background-image: linear-gradient(to top, #ffffff, rgba(243, 243, 243, 0.5));
	width: 45%;
	@media (min-width: 1050px) {
		display: none;
	}
	@media (min-width: 780px) and (max-width: 1049px) {
		display: flex;
		flex-direction: column;
	}
	@media (max-width: 779px) {
		display: none;
	}
`;

export const GreyLine = styled.div`
	border-bottom: 1px solid #f3f3f3;
	width: 90%;
`;

export const PaymentStuffTablet = styled.div`
	@media (min-width: 1050px) {
		display: none;
	}
	@media (max-width: 1049px) {
		display: flex;
		width: 100%;
	}
`;

export const InformationsDivDesktop = styled.div`
	@media (min-width: 1050px) {
		display: flex;
	}
	@media (max-width: 1049px) {
		display: none;
	}
`;

export const DivTitleDesktop = styled.div`
	@media (min-width: 1050px) {
		display: block;
	}
	@media (max-width: 1049px) {
		display: none;
	}
`;

export const DivTitleTablet = styled.div`
	@media (min-width: 1050px) {
		display: none;
	}
	@media (min-width: 780px) and (max-width: 1049px) {
		display: block;
	}
	@media (max-width: 779px) {
		width: 100%;
		align-items: center;
		padding: 20px 20px 0;
	}
`;

export const TitleTabletInfo = styled.h3`
	font-family: Raleway;
	font-size: 15px;
	font-weight: bold;
	line-height: 2.08;
	letter-spacing: normal;
	text-align: center;
	color: #142b4a;
	margin-top: 40px;
`;
