export const buildFilter = ({ formState, questions, questionMatch }) => {
	const filterToReturn = {};
	Object.entries(formState).map((prop) => {
		if (prop[0] === questionMatch.name) { return; }
		if (prop[1].value) {
			const questionMatch = questions.find(question => question.id === prop[1].id);
			const choiceMatch = questionMatch.choices.find(choice => choice.key === prop[1].key);
			filterToReturn[questionMatch.name] = choiceMatch.catalogMatch || choiceMatch.value;
		}
	});
	return filterToReturn;
};

export const decamelized = (string) => {
	return string.replace(/([A-Za-z])([A-Z])/g, '$1_$2').toLowerCase();
};

/**
 * Filter answers that can be displayed according to the answers allready filled
 * @param {Object} parameter object
 * @param {Object} parameter.questions Correspond to the questions allready answered, will serve as filter
 * @param {String} parameter.category Correspond to the current category (mac, iphone, ipad, watch ...)
 * @param {Array} parameter.devices Correspond to all the references of the current category
 * @return {Object} Correspond to a device with all characteristics that remains after filter
 */
export const checker = ({questions, category, devices} = { }) => {
	let { cellular, year, material, version, screenSize, touchbar, model } = questions;
	let frequency = ( questions.frequency && questions.frequency.split(" ")[0] ) || null;
	category === "watch" && (model = "Apple Watch "+ questions.model);
	let object = {
		model,
		...(year && { year } || { }),
		...(material && { material } || { }),
		...(version && { version } || { }),
		...(screenSize && { screen_size: screenSize } || { }),
		...(touchbar && { touchbar } || { }),
		...(cellular && { cellular: cellular.length && cellular !== 'false' } || { }),
		...(frequency && { frequency } || { }),
	};
	let result = getIdentifier ({filters: object, devices});
	result = Object.assign(getIdentifier ({filters: object, devices}), {
		...(result.ssd && { capacity : result.ssd, ssd: true } || { }),
		...(result.hdd && { capacity: result.hdd, hdd: true } || { }),
		...(result.year && {date: result.year } || { }),
		...(result.screen_size && {screenSize: result.screen_size } || { }),
		...(result.touchbar && { date: result.touchbar } || { })
	})
	return result;
};

const getIdentifier = ({filters, devices} = { }) => {
	let to_return = [];

	for (const element of devices) {
		let it_matches = true;

		for (let filter of Object.keys(filters)) {
			if (["true", "false", "Oui", "Non"].includes (filters[filter])) {
				filters[filter] = filters[filter] === "true" || filters[filter] === "Oui";
			}
			if (element[filter] !== filters[filter]) {
				it_matches = false;
				continue;
			}
		}

		if (it_matches) {
			to_return.push (element);
		}
	}

	devices = to_return;
	to_return = { };
	for (let element of devices) {
		for (let field of Object.keys (element)) {
			if(element[field] === null){continue;}
			if (!to_return[field]) { to_return[field] = []; }
			if (typeof element[field] === "object") {
				if (!to_return[field].toString ().includes (element[field].toString ())) {
					to_return[field].push (element[field]);
				}
			}
			else {
				if (!to_return[field].includes (element[field])) {
					if (element[field].toString () === "true") { to_return[field].push (true);}
					else if (element[field].toString () === "false") {
						to_return[field].push (false);
					}
					else {
						to_return[field].push (element[field]);
					}
				}
			}
		}
	}
	for (let element of ["ssd", "screenSize", "frequency", "ram", "year", "hdd"]) {
		// for (let value in to_return[element]) {
		// 	to_return[element][value] = !isNaN ( to_return[element][value] && to_return[element][value] / 1 ) || to_return[element][value];
		// }
		if (!to_return[element]) { continue; }
		to_return[element] = to_return[element].sort ((a, b) => {
			if (a > b) { return 1; }
			if (a < b) { return -1; }
			return 0;
		});
	}

	for (let element of ["colors"]) {
		let result = [];
		if (!to_return) { break; }
        if (!to_return[element]) { to_return[element] = [ ]; }
		for (let values of to_return[element]) {
			for (let value of values) {
				if (!result.includes (value)) { result.push (value); }
			}
		}
		to_return[element] = result;
	}
	return to_return;
};
