import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { isMobile, isTablet } from 'react-device-detect';

export const StyledOverlay = styled(animated.div)`
	z-index: 1000004;
	display: ${props => (props.open ? 'flex' : 'none')};
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	/* height: 100%; */
	height: 100vh;
	overflow: scroll;
	background-color: ${props => props.bgargb || '#80808080'};
`;

export const StyledModalContainer = styled.div`
	width: 100%;
	height: 100%;
	/* position: relative; */

	@media (max-width: 599px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (max-height: 550px) {
		padding: 70px 0;
	}

	@media (min-width: 600px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const StyledModalCard = styled(animated.div)`
	/* position: absolute; */
	z-index: 101;
	width: 60vw;
	max-width: 800px;
	margin: auto;
	background-color: #fff;
	border-radius: 20px;
	opacity: 1;
	display: flex;
	background-color: ${props => (props.type === 'iframe' ? '#f5f8fb' : '#fff')};
	justify-content: center;
	align-items: center;
	padding: 0;
	height: ${props => (props.type === 'iframe' ? '70vh' : null)};

	@media (min-width: 601px) and (max-width: 1280px) {
		width: ${props => (props.type === 'iframe' ? '70vw' : '400px')};
	}

	@media (max-width: 600px) {
		border-radius: 10px;
		/* width: 80vw;
		position: ${props => (props.type === 'iframe' ? null : 'relative')};
		bottom: 10vw;
		left: 10vw; */
		display: flex;
		flex-direction: column;
		/* padding: ${props => (props.type === 'iframe' ? '0' : '40px 25px')}; */
		width: 90%;
	}
`;

export const StyledCloseButton = styled.button`
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0;
	background-color: transparent;
	position: absolute;
	z-index: 100;
	top: 20px;
	right: 20px;
`;

export const StyledFooterCloseButton = styled.button`
	outline: none;
	cursor: pointer;
	width: 100%;
	height: 50px;
	background-color: #f8f8f8;
	border: none;
	border-top: 1px solid #d4e1f4;
	border-radius: 0 0 10px 10px;
	font-family: ${props => props.theme.mainFontFamily};
	color: ${props => props.theme.darkBlueColor};
	font-size: 14px;
	font-weight: bold;
`;

export const StyledModalBody = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: ${props => (props.type === 'iframe' ? '0' : '50px 50px')};

	& h4 {
		text-align: center;
	}

	@media (max-width: 600px) {
		height: calc(100% - 50px);
		padding: ${props => (props.type === 'iframe' ? '0' : '20px 25px')};
		background-image: linear-gradient(to top, #d4e1f477, #ffffff00 5%);
	}
`;
