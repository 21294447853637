import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../components/Navbar/index';
import NavbarTab from '../components/NavbarTab/index';
import { UserContext } from '../components/context/UserContext';
import styled from 'styled-components';
import Cards from '../components/cards/index';
import { getEstimatorInfo } from '../controllers/estimator/API';
import { Fade } from '../globalStyle';
import { getCorrectId } from '../controllers/tools';
import clearToken from '../controllers/toolsAPI';

const Div = styled.div`
	display: flex;
	flex-grow: 1;
	height: calc(100% - 121px);
	flex-direction: column;
	justify-content: center;
	div {
		@media (min-width: 730px) {
			margin: 0;
		}
		a {
			top: 0;
		}
	}
	h1 {
		font-family: ${props => props.theme.mainFontFamily};
		margin-bottom: 1em;
		color: ${props => props.theme.darkBlueColor};
		font-size: 27px;
		text-align: center;
	}
`;

const Device = () => {
	const [user] = useContext(UserContext);
	const [devices, setDevices] = useState([]);
	const [partner, setPartner] = useState('');
	const [ready, setReady] = useState(false);
	useEffect(() => {
		const fetch = async () => {
			const estimator = await getEstimatorInfo({ id: getCorrectId({ user }) })
			if (estimator) {
				setDevices(estimator.forms);
				setPartner(estimator.name);
				setReady(true);
			} else {
				clearToken();
			}
		}
		fetch();
	}, [])
	return (
		<Fade>
			<Navbar />
			<NavbarTab />
			{ready
				? <Div>
					<h1>Choisissez le produit à estimer:</h1>
					<Cards isRevendre devices={devices} partnerName={partner} />
				</Div>
				: null
			}
		</Fade>
	);
}

export default Device;