import styled from 'styled-components';

export const StyledQuestionTitle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
	margin: ${props => (props.lgMarginBot ? '20px 0 44px;' : '0 0 20px')};
	& h3 {
		font-family: ${props => props.theme.mainFontFamily};
		font-size: 13px;
		font-weight: bold;
		line-height: 1;
		color: #142b4a;
	}

	@media (min-width: 600px) {
		& h3 {
			font-size: 16px;
		}
	}

	@media (max-width: 600px) {
		position: relative;
	}
`;

export const StyledSubtitleInput = styled.p`
	font-family: ${props => props.theme.secondFontFamily};
	font-size: 12px;
	font-weight: 500;
	line-height: 1.25;
	color: #777;
	text-align: center;
	margin-top: 10px;
`;

export const StyledQuestionSubtitle = styled.h4`
	margin: 0;
	font-family: ${props => props.theme.secondFontFamily};
	color: ${props => props.theme.darkBlueColor};
	opacity: 0.5;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	letter-spacing: normal;
	margin-bottom: 10px;

	@media (min-width: 1080px) {
		margin-bottom: 15px;
	}
`;

export const StyledButtonTooltip = styled.button`
	border: none;
	outline: none;
	cursor: pointer;
	background-color: transparent;

	@media (min-width: 1080px) {
		display: none;
	}

	/* @media (max-width: 600px) {
		position: absolute;
		right: -25px;
		top: 0px;
		padding: 0;
	} */
`;
