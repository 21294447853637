import styled from 'styled-components';

export const StyledContainerNavButtons = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	margin: 20px 0px;
	width: 100%;

  @media (max-width: 600px) {
    /* justify-content: ${props => props.isPastQuestion ? 'space-between' : 'flex-end'}; */
		justify-content: space-between;
    position: sticky;
    bottom: 0;
    border-top: solid 1px #d4e1f4;
    margin: 0;
    height: 70px;
    padding: 0;
		background-color: ${props => props.theme.whiteColor};
  }
  @media (max-width: 1080px) and (min-width: 601px){
    position: relative;
  }
`;

export const StyledInfoButton = styled.button`
	border: none;
	outline: none;
	cursor: pointer;
	background-color: transparent;
	position: absolute;
	left: calc(50% - 28.5px);

	& img {
		cursor: pointer;
	}
	@media (min-width: 1081px) {
		display: none;
	}

	@media (min-width: 601px) and (max-width: 1080px) {
		display: ${props => (props.isFirstQuestion ? null : 'none')};
		bottom: 60px;
	}
`;
