import styled, { css } from 'styled-components';

export const StyleContainerInputs = styled.div`
	display: block;
	${props => props.isRadioImage
		&& css`
		@media (min-width: 1050px) {
			display: ${props => props.columns > 4 ? 'flex' : 'grid'};
			flex-wrap: ${props => props.columns > 4 && 'wrap'};
		}
		display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			@media (min-width: 1136px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;

			}
			grid-gap: 20px;
			justify-items: center;

			@media (max-width: 1080px) and (orientation: portrait) {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr;
			}

			@media (max-width: 600px) {
				${props => !props.imgDesktop &&
				css`
					grid-template-columns: auto auto;
					grid-template-rows: 1fr 1fr;
				`}
			}

			@media (max-width: 1049px) {
				${props => props.imgDesktop &&
				css`
				grid-template-columns: 40vw 40vw;
				grid-template-rows: 1fr 1fr;
				`}
			}
			@media (max-width: 1244px) {
				justify-content: center;
			}
		`}

	${props => props.columns === 3
		&& css`
			display: grid;
			width: 100%;

			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 10px;
			@media (max-width: 600px) and (orientation: portrait) {
				grid-template-columns: 1fr;
				grid-template-rows: 1fr 1fr 1fr;
			}
		`}

	${props => props.columns === 2
		&& css`
			display: grid;
			width: 100%;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
			@media (max-width: 600px) and (orientation: portrait) {
				grid-template-columns: 1fr;
				grid-template-rows: 1fr 1fr;
			}
		`}

		width: ${props => props.isRadioImage ? null : '100%'};
`;

export const TextInput = styled.div`
	width: 100%;
	background-color: purple;
	text-align: center;
`;

export const DropdownInput = styled.div`
	width: 100%;
	background-color: green;
	text-align: center;
`;
