import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import schemaCheckbox from '../../schemaCheckbox';
import Checkbox from '../../../form/Checkbox';
import { FormContext } from '../../../context/FormsContext'


const StyledContainer = styled.div`
	padding: 0em 2em;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
`;

const Li = styled.li`
	display: flex;
	align-items: center;
`;

const Ul = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
`;

const Bullet = styled.span`
	height: 5px;
	width: 5px;
	border-radius: 50%;
	background-color: blue;
	margin-right: 1em;
`;

const Text = styled.p`
	font-size: 13px;
	margin: 0;
	font-family: Raleway;
	color: #707070;
`;

const Information = styled.div`
	width: 45%;
	padding: 1em 0em;
	display : flex;
	align-items: center;
`;

const Logo = styled.img`
	max-width: 30px;
	max-height: 30px;
	margin-right: 1em;
`;

const ContainerCheckbox = ({ category = 'smartphone' }) => {
	const [, , checkbox, setCheckbox] = useContext(FormContext);
	const [ready, setReady] = useState(false)
	useEffect(() => {
		let toReturn = {};
		schemaCheckbox[category].map((item) => {
			toReturn[item.id] = { checked: item.state.checked }
		});
		setCheckbox(toReturn);
		setReady(true);
	}, [])
	const generateList = (list) => {
		const arrToReturn = [];
		list.content.map((item) => {
			arrToReturn.push(
				<Li key={item.id}>
					<Bullet />
					<Text>{item.text}</Text>
				</Li>
			)
		})
		return arrToReturn;
	}
	const handleChange = ({ id }) => {
		setCheckbox({
			...checkbox,
			[id]: { checked: !checkbox[id].checked } 
		});
	}
	const generateCheckbox = () => {
		const arrToReturn = [];
		schemaCheckbox[category].map((check) => {
			arrToReturn.push(
				<Information key={check.id}>
					<Checkbox margin='0' category={category} checked={checkbox[check.id].checked} onChange={handleChange} onClick={() => { handleChange({ id: check.id }); }} />
					<Logo src={check.logo} />
					{check.text.type === 'paragraph' ?
						<Text>{check.text.content}</Text>
					:
						<Ul>
							{generateList(check.text)}
						</Ul>
					}
				</Information>
			);
		})
		return arrToReturn;
	}
	return ready && (
		<StyledContainer>
			{generateCheckbox()}
		</StyledContainer>
	);
}

export default ContainerCheckbox;