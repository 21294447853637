import styled, { keyframes } from 'styled-components';

export const LoaderDiv = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	background-color: ${props => props.background ? props.background : props.theme.darkBlueColor};
`;

export const LoaderImage = styled.img`
	width: 100px;
`;

export const StyledSVG = styled.svg`
	width: 84px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledCurvesGroup1 = styled.g`
	transform-origin: center;
	animation: ${rotate} 2s linear infinite;
`;

export const StyledCurvesGroup2 = styled.g`
	transform-origin: center;
	animation: ${rotate} 2s linear infinite reverse;
`;
