import styled from 'styled-components';
import { FlexColumn } from '../../../../globalStyle';

export const ContainerNavButtons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledOuterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: white;
	border-radius: 30px;
	align-items: center;
	@media (max-width: 600px) {
		border-radius: unset;
	}

	@media (min-width: 600px) and (max-width: 1050px) {
		margin: 0;
	}
`;

export const StyledInnerWrapper = styled.div`
	justify-content: center;
	align-items: center;
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	flex-direction: column;
	max-width: ${props => (props.large ? '1120px' : '590px')};
	box-sizing: border-box;
	width: 90%;

	@media (max-width: 600px) {
		justify-content: flex-end;
		width: 100%;
		background-image: linear-gradient(to bottom, #d4e1f477, #ffffff00 20%);
	}
`;

export const ContainerQuestions = styled(FlexColumn)`
	opacity: ${props => props.offer ? '0.5' : '1'};
	width: 100%;
	justify-content: center;
	padding-top: 15px;
	@media (max-width: 600px) {
		justify-content: flex-end;
		width: 90%;
	}
	@media (min-width: 600px) {
		flex-grow: 1;
	}
`;
