import styled from 'styled-components';

export const StyledContainerGrid = styled.div`
	display: grid;
	grid-template-columns: 62.5% 35%;
	grid-gap: 0 30px;
	box-sizing: border-box;
	width: 90%;
	height: 100%;
	@media (max-width: 1080px) {
		grid-template-columns: 100%;
		grid-template-rows: auto auto;
	}
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	box-sizing: border-box;
	padding: 1em;
	height: 60%;
	width: 100%;
	background-color: #ffffff;
	h3 {
		font-family: Raleway;
		font-size: 16px;
		font-weight: 700;
	}
	@media (min-width: 780px) and (max-width: 1049px) {
		display: ${props => props.display || 'block'};
	}
	@media (max-width: 780px) {
		display: none;
	}
`;

export const StyledBlock = styled.div`
	background-color: white;
	border-radius: ${(props) => {
		if (props.platform !== 'desktop') {
			return {
				"top": "30px 30px 0px 0px",
				"bottom": "0px 0px 30px 30px"
			}[props.position]
		}
		return '30px';
	}};
	display: flex;
	flex-direction: column;
`;