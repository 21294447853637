
import mail from '../../assets/picto/mail.png';

const noOfferInput = [
	{
		id: 1,
		picto: mail,
		placeholder: 'hellozack@apple.com',
		title: 'EMAIL',
		spec: {
			mandatory: {
				condition: true,
				errorMessage: 'Ce champs est requis'
			},
			regex: {
				condition: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				errorMessage: 'Cet email n\'est pas valide'
			}
		},
		state: {}
	}
];

export default noOfferInput;
