/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { DivGlobal } from '../../../globalStyle';

export const StyleBlueButton = styled(DivGlobal)`
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  max-width: 290px;
  height: 40px;
  border-radius: 10px;
  background-image: linear-gradient(to left, ${props => props.theme.sunnyBlue}, #2e4eb0);
  font-family: ${props => props.theme.mainFontFamily};
  font-size: 14px;
  line-height: 1.56;
  text-align: center;
  color: ${props => props.theme.whiteColor};
  width: ${props => props.stylePage.widthBlueButton};
  margin-bottom: ${props => props.stylePage.marginBottomBlueButton};
`;

export const ContainerBlueButton = styled.div`
  width: 100%;
  margin: ${props => props.stylePage.marginBlueButton};
  display: ${props => props.stylePage.namePage === 'congratsColissimo' ? props.stylePage.displayContainerBlueButton : 'none'};
  justify-content: ${props => props.stylePage.justifyContentContainerBlueButton};

  @media (min-width: 1050px) {
    display: ${props => (['congratsColissimo', 'congratsCalendar'].includes(props.stylePage.namePage)) ? 'none' : (props.stylePage.namePage === 'congratsColissimoSchema') && 'block'};
  }
`;
