import React, { useState, useContext } from 'react';
import FieldSet from '../../book/questionContainerComponent/FieldSet';
import Indicator from '../../book/questionContainerComponent/Indicator';
import { FormContext } from '../../context/FormsContext';
import GoogleAutoComplete from '../../autocomplete/GoogleAutoComplete';

import { Legend, TextInput } from '../../book/questionContainerComponent/payment/styles';

import { Asterix, FlexInput, Error } from '../../book/style';

import Remove from '../../../assets/book/picto/remove.svg';
import Check from '../../../assets/book/picto/check.svg';
import { ContainerInputText } from './styles';

const InputText = ({ input, hasnooffer, name }) => {
	return (
		<FieldSet
			focused={input.state.focused}
			error={input.state.error}
			key={input.id}
			hasnooffer={hasnooffer}
		>
			<Legend>
				{input.title}
				{input.spec.mandatory.condition ? <Asterix> *</Asterix> : null}
			</Legend>
			<FlexInput justify="flex-end" direction="row-reverse" wrap="no-wrap">
				<Indicator
					isCorrect={input.state.isCorrect}
					error={input.state.error}
					src={input.state.isCorrect ? Check : Remove}
					alt="indicator"
				/>
				{name !== 'address' ? (
				<div style={{ width: '100%' }}>
					<TextInput
						type="text"
						placeholder={input.placeholder}
						onChange={input.state.onChange}
						onBlur={input.state.onBlur}
						onFocus={input.state.onFocus}
						value={input.state.value}
					/>
				</div>
				) : (
					<GoogleAutoComplete
						placeholder={input.placeholder}
						setCorrect={input.state.setCorrect}
						onChoose={input.state.onSelect}
						onBlur={input.state.onBlur}
						onFocus={input.state.onFocus}
						onChange={input.state.onChange}
						value={input.state.value}
					/>
				)}
				<img src={input.picto} alt="logo budy" style={{marginRight: '1em'}} />
			</FlexInput>
			{input.state.error ? <Error>{input.state.error}</Error> : null}
		</FieldSet>
	);
};

export default InputText;
