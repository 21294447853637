import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PlatformContext } from '../../context/PlatformContext';

import {
	StyledQuestionTitle,
	StyledQuestionSubtitle,
	StyledSubtitleInput,
	StyledButtonTooltip
} from './style';
import Info from '../../../assets/form/infoPicto/info_icon.svg';
import Help from '../../../assets/form/infoPicto/help_icon.svg';

export const QuestionTitle = ({
	text,
	isSubtitle,
	lgMarginBot,
	handleTooltipClick,
	handleFAQClick,
	tooltipContent
}) => {
	const [platform, setPlatform] = useContext(PlatformContext);
	// Icon can be Info or Help img depending on platform
	// (desktop, tablet or mobile)

	const getIcon = () => {
		// if (platform === 'desktop') {
		// 	return Info;
		// }
		// return Help;
		return Info;
	};

	const getIconSize = () => {
		// if (platform === 'desktop') {
		// 	return '40px';
		// }
		// return '20px'; // default for non-desktop view
		return '40px';
	};

	const onTooltipClick = () => {
		const iframe = tooltipContent.content[tooltipContent.content.length - 1]
			.link
			? `${tooltipContent.content[tooltipContent.content.length - 1].link}reader`
			: null;
		if (platform === 'desktop' && iframe) {
			handleFAQClick(iframe);
		} else handleTooltipClick(tooltipContent);
	};

	return (
		<StyledQuestionTitle
			platform={platform}
			lgMarginBot={lgMarginBot}
			isSubtitle={isSubtitle}
		>
			<h3>{text}</h3>
			{tooltipContent && (
				<StyledButtonTooltip onClick={onTooltipClick}>
					<img src={getIcon()} alt="tooltip" width={getIconSize()} />
				</StyledButtonTooltip>
			)}
		</StyledQuestionTitle>
	);
};

export const QuestionSubtitle = ({ text }) => {
	return <StyledQuestionSubtitle>{text}</StyledQuestionSubtitle>;
};

// SubtitleInput is for optional information displayed under input choices
export const SubtitleInput = ({ text }) => {
	return <StyledSubtitleInput>{text}</StyledSubtitleInput>;
};

QuestionTitle.propTypes = {
	text: PropTypes.string,
	isSubtitle: PropTypes.bool,
	tooltipContent: PropTypes.object,
	lgMarginBot: PropTypes.bool,
	handleTooltipClick: PropTypes.func,
	handleFAQClick: PropTypes.func
};

QuestionSubtitle.propTypes = {
	text: PropTypes.string
};

SubtitleInput.propTypes = {
	text: PropTypes.string
};
