import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { PlatformContext } from '../components/context/PlatformContext';
import { UserContext } from '../components/context/UserContext'
import ContainerPageQuestions from '../components/form/containers/ContainerPageQuestions';
import { getFormsData, sendEstimate } from '../controllers/estimator/API';
import {
	buildFilter,
	checker,
	decamelized
} from '../controllers/estimator/tools';
import { buildScore } from '../controllers/estimator/score';
import { translateOffer } from '../controllers/tools';
import ContainerModal from '../components/form/containers/ContainerModal';
import Information from '../components/form/Informations/Information';
import Iframe from '../components/form/Iframe';
import ContainerInformations from '../components/form/containers/ContainerInformations';
import ContainerOffer from '../components/form/containers/ContainerOffer/index';
import { DivGlobal } from '../globalStyle';
import OnlyLoader from '../components/loader/onlyLoader';
import Navbar from '../components/Navbar/index';
import NavbarTab from '../components/NavbarTab/index';

const FullHeight = styled(animated.div)`
	height: 100%;
	width: 100%;

	box-sizing: border-box;
	display: grid;
	grid-template-columns: 100% 0%;
	@media (min-width: 600px) {
		/* padding: 1em; */
		/* margin: 1em; */
	}
	${(props) => {
		return props.displayinfo
			? `@media (min-width: 1080px) {
			display: grid;
			/* grid-template-columns: calc(100% - 28% - 30px) 28%; */
			grid-template-columns: calc(100% - 35% - 30px) 35%;
			grid-gap: 0 30px;
			}`
			: null;
	}}
`;

const ContainerEstimator = styled(DivGlobal)`
	height: calc(100% - 150px);
	margin-top: 16.5px;
	position: relative;
	align-items: center;
	@media (min-width: 1050px) {
		align-items: center;
	}
`;

const ContainerGlobal = styled.div`
	@media(min-width: 600px) and (max-width: 1080px){
		padding: 0 2em;
	}
	position: absolute;
	height: 100%;
	width: 100%;
	max-width: 1330px;

	@media(min-width: 1050px){
		max-height: 650px;
	}

`;
const endForm = ({ state, formSchema } = {}) => {
	for (const set of Object.entries(formSchema)) {
		for (const question of set[1].arrSchemaQuestions) {
			if (question.answers) {
				for (const answer of Object.entries(question.answers)) {
					if (state[question.name].key === answer[0]) {
						if (answer[1] === 'endForm') {
							return true;
						}
					}
				}
			}

			if (!state[question.name].value && question.displayCondition) {
				// Look if condition is filled
				const { displayCondition } = question;
				if (!displayCondition[1].includes(state[displayCondition[0]].key)) { continue; }
			}
			if (!state[question.name].value) {
				return false;
			}
		}
	}
	return true;
};

const isConditionFilled = ({ condition, relatedAnswer }) => condition[1].includes(relatedAnswer.key);

const findPastQuestion = ({ questions, actualStateForm }) => {
	const pastQuestion = Object.entries(actualStateForm)
		.filter(question => question[1].value)
		.pop();
	return (
		pastQuestion
		&& questions.find(question => question.id === pastQuestion[1].id)
	);
};

/**
 * Render Estimator component
 */
const Estimator = (props) => {
	const { router } = props;
	const [platform] = useContext(PlatformContext);
	const [user, setUser] = useContext(UserContext);
	const [initialData, setInitialData] = useState(null);
	const [actualQuestion, setActualQuestion] = useState(null);
	const [pastQuestion, setPastQuestion] = useState(null);
	const [actualSet, setActualSet] = useState({ arrInformations: [] });
	const [formState, setFormState] = useState(null);
	const [isTooltipOpen, setTooltipOpen] = useState(false);
	const [isFAQOpen, setFAQOpen] = useState(false);
	const [tooltipInfo, setTooltipInfo] = useState(null);
	const [modalIframe, setModalIframe] = useState(
		'https://support.hellozack.fr/fr/article/quel-est-letat-fonctionnel-de-mon-produit-183ljqy/reader'
	);
	const [estimationOffer, setOffer] = useState(null);
	const [isLoading, setLoading] = useState(true);

	/**
	 * Modify the current url in order to retrace the actual state of the form if user reload
	 * @param {Object} parameter object
	 * @param {String} parameter.state Current state of the form
	 */
	const updateUrl = ({ state, offer } = {}) => {
		// Partners to implement later
		let newUrl = `/estimators/partners/${props.match.params.partnerName}/${
			props.match.params.formName
		}`;
		Object.entries(state).map((question) => {
			if (question[1].value) {
				const schemaQuestion = initialData.questions.find(
					q => q.id === question[1].id
				);
				const choicePicked = schemaQuestion.choices.find(
					c => c.value === question[1].value
				);
				newUrl += `/${question[1].id}-${choicePicked.url}`;
			}
		});
		newUrl += `${offer && `?offerEstimation=${offer.model}` || ''}`;
		window.history.pushState(Object.assign({}, { as: newUrl }), null, newUrl);
	};

	const sendScore = async ({ score, state } = {}) => {
		const answers = {};
		for (const question of Object.entries(state)) {
			if (question[0] === 'functionnal') {
				// special format for Frank
				answers.functionality = question[1].value;
				continue;
			}
			answers[decamelized(question[0])] = question[1].value;
		}
		const body = {
			answers,
			score,
			partner_id: initialData.estimatorValues.partner_id
		};
		// requete api frank
		const offer = await sendEstimate({ body });
		if (user.formInfo.comission) {
			offer.offer = translateOffer({ parameter: user.formInfo.comission, offer: offer.offer, model: 'iPhone' }) / 1;
		}
		if (offer) {
			setOffer({
				...offer,
				score,
				answers
			});
			updateUrl({ state, offer });
		}
	};

	const setQuestion = ({ data, newFormState } = {}) => {
		const { questions, form } = data || initialData;
		const actualStateForm = newFormState || data.actualStateForm;
		setFormState(actualStateForm);
		for (const question of Object.entries(actualStateForm)) {
			const set = form.find(set => set.name === question[1].questionSetName);
			const questionSchema = set.arrSchemaQuestions.find(
				q => q.id === question[1].id
			);
			const hasToBeDisplay = questionSchema.displayCondition
				? isConditionFilled({
					condition: questionSchema.displayCondition,
					relatedAnswer: actualStateForm[questionSchema.displayCondition[0]]
				})
				: true;
			if (!question[1].value && hasToBeDisplay) {
				setActualSet(
					form.find(set => set.name === question[1].questionSetName)
				);
				setActualQuestion(questions.find(q => q.id === question[1].id));
				setPastQuestion(findPastQuestion({ questions, actualStateForm }));
				return;
			}
		}
		setActualSet(
			form.find(
				set => set.name
					=== actualStateForm[Object.keys(actualStateForm).pop()].questionSetName
			)
		);
		setActualQuestion(
			questions.find(q => q.name === Object.keys(actualStateForm).pop())
		);
		setPastQuestion(findPastQuestion({ questions, actualStateForm }));
	};

	const handleBackClick = () => {
		if (!initialData) {
			window.location.reload();
		}
		const copyState = { ...formState };

		Object.entries(copyState).map((e) => {
			copyState[e[0]] = {
				...copyState[e[0]],
				value: null,
				key: null
			};
		});
		const parseUrl = window.location.pathname
			.split('/')
			.filter(entry => /^[0-9]+-.+$/.test(entry));
		parseUrl.map((entry) => {
			const questionMatch = initialData.questions.find(
				question => question.id === entry.split('-')[0]
			);
			const choiceMatch = questionMatch.choices.find(
				choice => choice.url === entry.split('-')[1]
			);
			copyState[questionMatch.name] = {
				...copyState[questionMatch.name],
				value: choiceMatch.value,
				key: choiceMatch.key
			};
		});
		return setQuestion({ newFormState: copyState });
	};

	const signal = axios.CancelToken.source();
	useEffect(() => {
		window.onpopstate = handleBackClick;
		return () => {
			window.removeEventListener('onpopstate', handleBackClick);
		};
	}, []);

	useEffect(() => {
		async function fetchData() {
			try {
				const data = await getFormsData({ pathname: props.location.pathname, signal });
				setUser({
					...user,
					formInfo: data.formInfo
				});
				if (!data || (data.formInfo.is_private && !user.role)) {
					// redirect to a 404 ? Or a forbiden page ?
					return props.history.push('/login');
				}
				setInitialData(data);
				setQuestion({ data });
			} catch (error) {
				if (axios.isCancel(error)) {
					console.error(error.message);
				} else {
					setLoading(false);
				}
			}
		}
		fetchData();

		return () => {
			signal.cancel('API is being canceled');
		};
	}, []);

	const getProgression = () => {
		const index = Object.entries(formState).findIndex(
			q => q[1].id === actualQuestion.id
		);
		const formLength = Object.entries(formState).length - 1;
		return Math.round((index * 100) / formLength);
	};

	const generateAppropriateAnswers = () => {
		const arrOfQuestions = [];
		const data = { ...initialData };
		actualSet.arrSchemaQuestions.map((question) => {
			if (
				formState[question.name].key
				|| actualQuestion.name === question.name
			) {
				const questionMatch = {
					...data.questions.find(
						modelQuestion => modelQuestion.id === question.id
					)
				};
				let copyOfAnswers = [...questionMatch.choices];
				// -------- Filter the answers possible according to the previous response ------
				if (questionMatch.dynamicAnswers) {
					const filter = buildFilter({
						formState,
						questions: data.questions,
						questionMatch,
						questionsSet: actualSet.arrSchemaQuestions
					});
					const res = checker({
						questions: filter,
						category: data.category,
						devices: data.catalog
					});
					res[questionMatch.name] = res[questionMatch.name].map(answer => answer.toString());
					questionMatch.choices = copyOfAnswers = copyOfAnswers.filter(
						(choice) => {
							return res[questionMatch.name].includes(choice.catalogMatch);
						}
					);
				}
				questionMatch.choices = copyOfAnswers.map((choice) => {
					choice.isSelected = choice.key === formState[questionMatch.name].key;
					return choice;
				});
				arrOfQuestions.push(questionMatch);
			}
			return question;
		});
		return arrOfQuestions;
	};

	const clickOnAnswer = ({ answerClicked, newFormState }) => {
		setOffer(null);
		if (newFormState) {
			updateUrl({ state: newFormState });
			if (endForm({ state: newFormState, formSchema: initialData.form })) {
				sendScore({
					score: buildScore({
						category: initialData.category,
						state: newFormState,
						formSchema: initialData.form,
						brand: initialData.brand || 'apple'
					}),
					state: newFormState
				});
				// return;
			}
			return setQuestion({ newFormState });
		}
		const copyState = { ...formState };
		copyState[actualQuestion.name] = {
			...copyState[actualQuestion.name],
			key: answerClicked.key,
			value: answerClicked.value
		};
		if (endForm({ state: copyState, formSchema: initialData.form })) {
			sendScore({
				score: buildScore({
					category: initialData.category,
					state: copyState,
					formSchema: initialData.form,
					brand: initialData.brand || 'apple'
				}),
				state: copyState
			});
			return;
		}
		updateUrl({ state: copyState });
		setQuestion({ newFormState: copyState });
	};

	const showTooltip = (tooltipContent) => {
		setTooltipInfo(tooltipContent);
		if (!isTooltipOpen) setTooltipOpen(true);
	};

	const hideTooltip = () => { setTooltipOpen(false); };

	const showFAQ = (link) => {
		setModalIframe(link);
		if (!isFAQOpen) setFAQOpen(true);
	};

	const hideFAQ = () => { setFAQOpen(false); };

	const handleOfferReturn = () => {
		setOffer(null);
	};

	const getNamePage = () => {
		return {
		};
	};

	const width = useSpring({
		gridTemplateColumns:
			platform === 'desktop' && actualSet.arrInformations.length
				? '62.5% 35%'
				: '100% 0%'
	});

	const getNameDeviceSEO = () => {
		const device = {
			name: '',
			pathImage: ''
		};
		if (formState && formState.model.value) {
			if ((formState.model.value).includes('Génération') || (formState.model.value).includes('Series')) {
				device.name = `Apple Watch ${formState.model.value}`;
				device.pathImage = 'seo-watch-c6b5aa6c86dbfd6bd0e866a669f76815.png';
			} else {
				device.name = formState.model.value;
				device.pathImage = 'seo-watch-c6b5aa6c86dbfd6bd0e866a669f76815.png';
			}
		} else if (router.query.formName === 'iphone') {
			device.name = 'iPhone';
			device.pathImage = 'seo-iphone-84e2a13d21b0832ea6b02427192db28e.png';
		} else if (router.query.formName === 'ipad') {
			device.name = 'iPad';
			device.pathImage = 'seo-ipad-a63249cce0d71c3bb884334e32a7a8da.png';
		} else if (router.query.formName === 'watch') {
			device.name = 'Apple Watch';
			device.pathImage = 'seo-watch-c6b5aa6c86dbfd6bd0e866a669f76815.png';
		} else if (router.query.formName === 'mac') {
			device.name = 'MacBook';
			device.pathImage = 'seo-mac-4f42f9d8a348693a7259cb1ac8346b81.png';
		}
		return device;
	};

	const nextAction = ({ offerId }) => {
		if (offerId) {
			return props.history.push(`/formulaire/${offerId}`)
		}
		return props.history.push('/');
	}

	return (
		<div style={{ height: '100%' }}>
			{user.role &&
				<>
					<Navbar />
					<NavbarTab />
				</>
			}
			{actualQuestion
				&&
				<ContainerEstimator arrOfQuestions={generateAppropriateAnswers()}>
					<ContainerGlobal arrOfQuestions={generateAppropriateAnswers()}>
						<FullHeight displayinfo={actualSet.arrInformations.length} style={width}>
							<ContainerPageQuestions
								offer={estimationOffer}
								onClickReturnOffer={handleOfferReturn}
								actualSet={actualSet}
								formState={formState}
								arrOfQuestions={generateAppropriateAnswers()}
								actualQuestion={actualQuestion}
								pastQuestion={pastQuestion}
								progressBarProps={getProgression()}
								clickOnAnswer={clickOnAnswer}
								handleTooltipClick={tooltipContent => showTooltip(tooltipContent)}
								handleFAQClick={link => showFAQ(link)}
								isModalOpen={isTooltipOpen || isFAQOpen}
								nextAction={nextAction}
							/>
							<ContainerModal
								bgARGB="#142b4a80"
								show={isTooltipOpen || false}
								onCloseClick={hideTooltip}
								platform={platform}
								contentType={platform === 'desktop' ? 'iframe' : null}
							>
								{tooltipInfo && isTooltipOpen && (
									<Information
										highlighted
										title={tooltipInfo.title}
										arrContent={tooltipInfo.content}
										modalDisplay
										handleURLClick={link => showFAQ(link)}
										stylePage={getNamePage()}
									/>
								)}
							</ContainerModal>
							<ContainerModal
								bgARGB="#142b4a80"
								show={isFAQOpen || false}
								onCloseClick={hideFAQ}
								contentType="iframe"
								platform={platform}
							>
								<Iframe
									url={
										modalIframe
								|| 'https://support.hellozack.fr/fr/article/quel-est-letat-fonctionnel-de-mon-produit-183ljqy/reader'
									}
								/>
							</ContainerModal>

							{platform === 'desktop'
								? (actualSet.arrInformations.length && !estimationOffer && (
									<ContainerInformations
										actualQuestion={actualQuestion}
										actualSet={actualSet}
										handleURLClick={link => showFAQ(link)}
									/>
								))
							|| (estimationOffer && <ContainerOffer nameDeviceSEO={getNameDeviceSEO()} offer={estimationOffer} nextAction={nextAction} />)
								: null}
						</FullHeight>
					</ContainerGlobal>
				</ContainerEstimator>
				|| <p> Loading </p>
			}
		</div>
	);
};

Estimator.propTypes = {
	// url: PropTypes.string,
	router: PropTypes.object
};

export default withRouter(Estimator);
