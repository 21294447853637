import styled from 'styled-components';

export const StyledOfferHeader = styled.div`
	width: 100%;
	height: 34%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	@media (max-width: 1080px) {
		height: 42.5%;
		justify-content: space-around;
	}

	@media (min-width: 319px) and (max-width: 400px) {
		height: 47.5%;
	}
`;

export const StyledContainerProduct = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	@media (max-width: 1500px) {
		padding: 0 20px;
	}

	@media (max-width: 1080px) {
		align-items: center;
	}

	justify-content: center;
	width: 100%;
`;

export const StyledPictureProduct = styled.div`
	width: 110px;
	height: 90px;
	position: relative;
	overflow: hidden;
	border-bottom: 2px solid;

	border-image: linear-gradient(to right, #ffffff, #d4e1f4, #ffffff) 0 1 100% 1;
	
	& img:first-child {
		${props => {
			if (/galaxy/i.test(props.model)) {
				return `
					max-height: 100%;
					top: 50%;
					position: absolute;
					left: 50%;
					transform: translate(-50%, -50%);
				`
			} else {
				return `
					position: absolute;
					top: 15px;
					width: 100%;
					transform: ${props => props.model === 'macbook' ? 'scale(1.1)' : 'scale(1.5)'};
				`
			}
		}}
	}

	& img:nth-child(2) {
		width: 30%;
		position: absolute;
		top: 0px;
		right: 0px;
	}
`;

export const StyledGradingImage = styled.img`
	width: 20%;
	position: absolute;
	top: 0;
	right: ${props => props.right};

	@media (max-width: 1080px) {
		max-width: 50px;
	}
`;

export const StyledHeadings = styled.div`
	width: auto;
	height: 90px;
	justify-self: center;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
`;

export const StyledPriceTag = styled.div`
	position: relative;
	top: 15px;
	width: 100px;
	height: 40px;
	margin-bottom: 10px;

	& p {
		font-family: ${props => props.theme.secondFontFamily};
		font-size: 15px;
		font-weight: 900;
		padding: 0;
		margin: 0;
		position: absolute;
		color: ${props => props.theme.sunnyBlue};
		top: 27%;
		left: 20%;
		width: 70%;
		text-align: center;
		line-height: 100%;

		& span {
			font-size: 10px;
		}
	}

	@media (max-width: 600px) {
		width: 100px;

		& p {
			font-size: 14px;
			top: 25%;

			& span {
				font-size: 8px;
			}
		}
	}
`;

export const StyledGradedModel = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;

	margin: 0;
	padding: 0;

	& h3 {
		font-size: 13px;
		line-height: 1.1;
		font-family: ${props => props.theme.mainFontFamily};
		color: ${props => props.theme.darkBlueColor};
		margin-bottom: 8px;

		@media (max-width: 1550px) and (min-width: 1080px), (max-width: 600px) {
			max-width: 90px;
		}

		@media (max-width: 600px) {
			font-size: 14px;
		}
	}

	& img {
		max-height: 20px;
		margin-left: 10px;
	}
`;

export const StyledContainerLabels = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	width: 90%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	@media (max-width: 1080px) {
		max-width: 400px;
	}

	@media (min-width: 319px) and (max-width: 400px) {
		margin: 0;
	}
`;

export const StyledLabel = styled.li`
	padding: 7px 15px;
	margin: 5px;
	border-radius: 20px;
	font-family: ${props => props.theme.secondFontFamily};
	font-size: 12px;
	font-weight: bold;
	color: #838f9e;
	background-color: #f3f3f3;

	@media (max-width: 1550px) and (min-width: 1080px), (max-width: 600px) {
		padding: 7px 15px;
		font-size: 11px;
	}
`;
