import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { StyledWrapper, StyledContainerNavbar, Logo, StyledUserContainer, Info, Role, Name } from './style';
import { UserContext } from '../context/UserContext';
import { PartnersContext } from '../context/UserContext';
import { Avatar, Button, Dropdown, Header } from "tabler-react";
import defaultLogo from '../../assets/logo.png';
import { userInfo } from '../../controllers/authentication/API';
import { getEstimatorInfo } from '../../controllers/estimator/API';
import { logout } from '../../controllers/authentication/API';
import config from '../../config/endpoints';
import { EmptyLine } from '../../globalStyle';
import { getCorrectId } from '../../controllers/tools'
import clearToken from '../../controllers/toolsAPI'

const Div = styled.div`
	display: flex;
	margin-right: 1em;
`;

const Navbar = ({ empty, history }) => {
	const [user, setUser] = useContext(UserContext);
	const [partners, setPartners] = useContext(PartnersContext);
	const [currentUser, setCurrent] = useState(null)
	const [ready, setReady] = useState(false)
	const getLogo = () => {
		return currentUser.logo;
		return `${config[process.env.REACT_APP_TEST || process.env.NODE_ENV].estimator}/static${currentUser.logo}.png` || defaultLogo
	}

	useEffect(() => {
		const fetch = async () => {
			try {
				Promise.all([
					await userInfo({ id: user.id }),
					await getEstimatorInfo({ id: getCorrectId({ user }) })
				]).then((arrOfResponses) => {
					if (arrOfResponses[0].data.user && arrOfResponses[1]) {
						setCurrent({
							mail: arrOfResponses[0].data.user.mail,
							id: arrOfResponses[0].data.user.id,
							role: arrOfResponses[0].data.user.role,
							logo: arrOfResponses[1].logo || '',
							name: arrOfResponses[1].name || ''
						})
					}
					if (arrOfResponses[0] && arrOfResponses[1]){
						setReady(true);
					} else {
						clearToken();
					}
				})	
			} catch (error) {
				clearToken();
			}
		}
		if(!empty) {
			fetch();
		}
	}, [])

	const handleClick = () => {
		setUser({
			token: '',
			role: '',
			email: '',
			id: '',
			partners_whitelist: '',
			partner_admin: ''
		});
		clearToken();
	}

	const changeZackerView = (partner) => {
		window.localStorage.setItem('partnerId', partner.id);
		window.localStorage.setItem('is_admin', partner.is_admin);
		window.localStorage.setItem('partner_admin', partner.partner_admin || false);

		setUser((prev) => { return {
			...prev,
			id: partner.id,
			is_admin: partner.is_admin,
			partner_admin: partner.partner_admin || false,
		}});
		history.push('/products');
	}

	const generatePartners = () => {
		const arrToReturn = [];
		partners.map((partner) => {
			arrToReturn.push(
				<span key={partner.id}>
					<Dropdown.Item style={{ cursor: "pointer" }} onClick={() => { changeZackerView(partner) }} >{partner.firstname || ''} ({partner.id})</Dropdown.Item>
					<Dropdown.ItemDivider />
				</span>
			)
		})
		return arrToReturn;
	}

	const capitalize = (string) => {
		string = string.split('');
		let firstLetter = string.shift();
		string.unshift(firstLetter.toUpperCase());
		return string.join('');
	}

	return (
		<StyledWrapper>
			<StyledContainerNavbar>
				{ready &&
					<>
						{currentUser.logo.length
							? <Logo src={getLogo()} alt='logo' />
							: <Header.H2 class="no-margin" >{currentUser.name.toUpperCase()}</Header.H2>
						}
						<StyledUserContainer>
							<Div>
								<Avatar imageURL={getLogo()} />
								<Info>
									<Name>{currentUser.mail}</Name>
									<Role> {capitalize(currentUser.role)} ID: {currentUser.id}  </Role>
								</Info>
								{user.role === 'zacker' && <Button.Dropdown color="primary">{generatePartners()}</Button.Dropdown>}
							</Div>
							<Button icon="x-circle" color="primary" outline onClick={handleClick} >Logout</Button>
						</StyledUserContainer>
					</>
				||
					<>
						<EmptyLine width={["130px", "110px", "100px"]} light />
						<EmptyLine width={["130px", "110px", "100px"]} dark />
					</>
				}
			</StyledContainerNavbar>
		</StyledWrapper>
	);
}

export default withRouter(Navbar);