import React from 'react';
import PropTypes from 'prop-types';
import { StyleProgressBar, Indicator } from './styles';
import { DivGlobal } from '../../../globalStyle';

const ProgessBar = ({
	themeProgressBar,
	themeContent
}) => {
	return (
		<DivGlobal>
			<StyleProgressBar themeProps={themeProgressBar}>
				<Indicator theme={themeContent} />
			</StyleProgressBar>
		</DivGlobal>
	);
};

export default ProgessBar;

ProgessBar.propTypes = {
	themeProgressBar: PropTypes.object,
	themeContent: PropTypes.object
};
