import React, { useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Icon, Header, Text, Form } from 'tabler-react';
import { StyledNavButton } from '../form/NavButton/style';

const AllWindow = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1000;
`;

const Message = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 400px;
	background-color: white;
	border-radius: 20px;
    padding: 2em 2em 0em 2em;
`;

const ContainerExitCross = styled.span`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	width: 25px;
	height: 25px;
`;


const Modal = ({ data, show, setShowModal, history, template = 'expeditions' }) => {
	const generateText = () => {
		const arrToReturn = [];
		(data.response || []).map((recipient) => {
			Object.entries(recipient.expeditions).map((expedition) => {
				arrToReturn.push(
					<li key={expedition[0]}>
						<strong>{expedition[0]}: </strong>
						{expedition[1].join(', ')}
					</li>
				)
			})
		})
		return arrToReturn;
	}
	const generateInput = () => {
		const handleChange = (e) => {
			data.setUser({
				...data.user,
				mail: e.target.value
			})
		}
		return (
			<Form.Input name="email" placeholder="mon_mail@gmail.com" value={data.user.mail || ''} onChange={handleChange}/>
		)
	}
	return show && {
			'invoiceMail': (
				<AllWindow>
					<Message>
						<ContainerExitCross onClick={() => setShowModal(false)} >
							<Icon name="x-circle" style={{ fontSize: "1.5em", color: '#c0392b' }}/>
						</ContainerExitCross>
						<Header.H2>Envoyer le recap facture à :</Header.H2>
						{data &&
							generateInput()
						}
						<StyledNavButton style={{ alignSelf: 'center'}} direction="previous" onClick={() => data.handleSendInvoice (data.modalPeriod) }>
							<p>Ok</p>
							<Icon alt="cta" name="check" style={{ width: '20px' }} />
						</StyledNavButton>
					</Message>
				</AllWindow>
			),
			'expeditions': (
				<AllWindow>
					<Message>
						<ContainerExitCross onClick={() => setShowModal({ status: false, data: null })} >
							<Icon name="x-circle" style={{ fontSize: "1.5em", color: '#c0392b' }}/>
						</ContainerExitCross>
						<Header.H2>Récap des expéditions créées:</Header.H2>
						{data &&
							<ul>
								{generateText()}
							</ul>
						}
						<StyledNavButton style={{ alignSelf: 'center'}} direction="previous" onClick={() => setShowModal({ status: false, data: null }) }>
							<p>Ok</p>
							<Icon alt="cta" name="check" style={{ width: '20px' }} />
						</StyledNavButton>
					</Message>
				</AllWindow>
			)
		}[template || 'expeditions']
}

export default withRouter(Modal);