import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getPartnersAdmin } from '../../controllers/authentication/API'

export const UserContext = createContext();
export const PartnersContext = createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState({
		token: window.localStorage.getItem('partnerToken'),
		role: window.localStorage.getItem('partnerRole'),
		id: window.localStorage.getItem('partnerId'),
		is_admin: JSON.parse(window.localStorage.getItem('is_admin')),
		partners_whitelist: window.localStorage.getItem('partners_whitelist') && window.localStorage.getItem('partners_whitelist').split(','),
		partner_admin: JSON.parse(window.localStorage.getItem('partner_admin'))
	});

	return (
		<UserContext.Provider value={[user, setUser]}>
			{children}
		</UserContext.Provider>
	);
};

export const PartnersProvider = ({ children }) => {
	const [partners, setPartners] = useState([]);
	useEffect(() => {
		const fetch = async () => {
			const res = await getPartnersAdmin();
			setPartners(res.data.partners);
		}
		if (window.localStorage.getItem('partnerRole') === 'zacker') {
			fetch();
		}
	}, [])
	return (
		<PartnersContext.Provider value={[partners, setPartners]}>
			{children}
		</PartnersContext.Provider>
	);
}

UserProvider.propTypes = {
	children: PropTypes.object,
};