import axios from 'axios';
import config from '../../config/endpoints'

const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';
const baseUrl = config[env].baseUrl;

export const logout = () => {
	['partnerToken', 'partnerRole', 'partnerId', 'is_admin', 'partners_whitelist', 'partner_admin'].map((item) => {
		window.localStorage.removeItem(item);
	})
	window.location.href = '/login'
}

export const getPartnersAdmin = async () => {
	return await axios.get(`${baseUrl}/partner?is_admin=true`)
}

export const signIn = async ({ email, password }) => {
	const res = await axios({
		method: 'post',
		url: `${baseUrl}/account/partner/signin`,
		data: { email, password },
		validateStatus(status) {
			return status >= 200;
		},
	});
	return res;
}

export const userInfo = async ({ id }) => {
	const res = await axios({
		method: 'get',
		url: `${baseUrl}/account/partner/user_informations${id ? `/${id}` : ''}`,
		validateStatus(status) {
			return status >= 200;
		},
	});
	return res;
}
