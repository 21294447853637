import styled, { createGlobalStyle, keyframes } from 'styled-components';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

export const EmptyLine = styled.div`
  margin-right: 0.5em;
  width: ${(props) => props.width[0]};
  height: ${(props) => props.height ? props.height[0] : ''};
  border-radius: ${(props) => props.notRadius === 0 ? '0px' : '30px'};
  background: linear-gradient(${(props) => props.light ? 'lightgrey, darkgrey' : 'darkgrey, lightgrey' });
  opacity: 0.3;
  margin-bottom: ${(props) => props.marginBottom ? '1.5em' : ''};
  @media (max-width: 1080px) {
    width: ${(props) => props.width[1]};
    height: ${(props) => props.height ? props.height[1] : ''};
  }
  @media (max-width: 600px) {
    width: ${(props) => props.width[2]};
    height: ${(props) => props.height ? props.height[2] : ''};
  }
`;

export const Fade = styled.div`
  height: 100%;
	animation: ${fadeIn} 0.5s linear;
`; 

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0;
		height: 100%;
    width: 100%;
    overflow-y: hidden;
    background-color: ${(props) => props.background ? props.background : '#ecf0f1'};
    line-height: 1;  
  }
  a {
    text-decoration: none;
  }
  label {
    margin-bottom: 0;
  }
`;

export const DivGlobal = styled.div`
	display: flex;
	justify-content: center;
	align-items: ${props => (props.align ? props.align : '')};
`;

export const FlexColumn = styled(DivGlobal)`
	flex-direction: column;
`;
