import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

import { QuestionTitle, QuestionSubtitle } from '../../InputTitles';
import { StyleContainerQuestion, StyleContainerAnimation } from './style';
import ContainerInputs from '../ContainerInputs/index';

const ContainerQuestion = ({
	questionSchema,
	actualQuestion,
	arrOfAnswers,
	show,
	typeInput,
	handleClick,
	tooltipContent,
	handleTooltipClick,
	handleFAQClick
}) => {
	const isActive = () => {
		return actualQuestion.id === questionSchema.id;
	};
	const onClick = ({ answerClicked }) => {
		handleClick({
			questionId: questionSchema.id,
			answerClicked
		});
	};

	const checkImgInputs = () => {
		for (const answer of arrOfAnswers) {
			const isImgRadio = !!answer.pathImg;
			return isImgRadio;
		}
		return false;
	};
	const fadeIn = useSpring({
		from: { opacity: 0, marginBottom: -500 },
		to: { opacity: 1, marginBottom: 0 }
	});

	return (
		<StyleContainerQuestion show={show} isActive={isActive()}>
			<StyleContainerAnimation
				style={fadeIn}
				isimgradio={checkImgInputs().toString()}
			>
				<QuestionTitle
					text={questionSchema.title}
					isSubtitle={!!questionSchema.subtitle}
					lgMarginBot={checkImgInputs()}
					tooltipContent={tooltipContent}
					handleTooltipClick={handleTooltipClick}
					handleFAQClick={handleFAQClick}
				/>
				{questionSchema.subtitle && (
					<QuestionSubtitle text={questionSchema.subtitle} />
				)}

				<ContainerInputs
					isActive={isActive()}
					arrOfAnswers={arrOfAnswers}
					typeInput={typeInput}
					handleClick={onClick}
				/>
			</StyleContainerAnimation>
		</StyleContainerQuestion>
	);
};

ContainerQuestion.propTypes = {
	questionSchema: PropTypes.object,
	actualQuestion: PropTypes.object,
	show: PropTypes.bool,
	arrOfAnswers: PropTypes.array,
	typeInput: PropTypes.string,
	handleClick: PropTypes.func,
	handleTooltipClick: PropTypes.func,
	handleFAQClick: PropTypes.func,
	tooltipContent: PropTypes.object
};

export default ContainerQuestion;
