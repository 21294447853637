import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  box-sizing: content-box;
  vertical-align: unset;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? '#8ad086' : 'white')};
  border: solid 1px ${props => (props.checked ? 'rgba(138,208,134,0.16)' : '#707070')};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 25px;
    height: 25px;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

const Checkbox = ({ className, margin, checked, category, ...props }) => {
  const CheckboxContainer = styled.div`
    margin-right: 1em;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 1049px) {
      margin: ${margin}
    }
`;

	return (
		<CheckboxContainer className={className} isLabel={category === 'label'}>
			<HiddenCheckbox checked={checked} {...props} />
			<StyledCheckbox checked={checked} {...props}>
				<Icon viewBox="0 0 24 24">
					<polyline points="20 6 9 17 4 12" />
				</Icon>
			</StyledCheckbox>
		</CheckboxContainer>
	)
}

export default Checkbox;
