import React from 'react';
import PropTypes from 'prop-types';
import { isMobileSafari } from 'react-device-detect';
import {
	StyledIframe,
	ContainerIframe,
	StyledIframeMobileSafari,
	ContainerIframeMobileSafari
} from './style';

const Iframe = ({ url }) => {
	return (
		<>
			{isMobileSafari ? (
				<ContainerIframeMobileSafari>
					<StyledIframeMobileSafari src={url} frameborder="0" />
				</ContainerIframeMobileSafari>
			) : (
				<ContainerIframe>
					<StyledIframe src={url} frameborder="0" />
				</ContainerIframe>
			)}
		</>
	);
};

export default Iframe;

Iframe.propTypes = {
	url: PropTypes.string
};
