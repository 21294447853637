import React from 'react';
import PropTypes from 'prop-types';
import schemaTabs from './schemaTabs';
import { Link } from 'react-router-dom'
import { Icon, Nav, Table } from 'tabler-react';
import { StyledContainerTabs, Tab, Title } from './style';

const ContainerTabs = ({ location }) => {
	const generateTabs = () => {
		const arrToReturn = [];
		schemaTabs.map((tab) => {
			arrToReturn.push(
				<Nav.Item
					key={tab.id}
					className={tab.url === location ? 'navTab' : 'navTabHover'}
					active={tab.url === location}
					value={tab.title}
					type="div"
					icon={tab.picto}
					href={tab.url}
				/>
			)
		})
		return arrToReturn;
	}
	return (
		<Nav tabbed="true" >{generateTabs()}</Nav>
	);
}

ContainerTabs.propTypes = {
	location: PropTypes.string
}

export default ContainerTabs;