export default[
	// {
	// 	value: 'Home',
	// 	icon: 'home',
	// 	to: '/'
	// },
	// {
	// 	value: 'Estimateur',
	// 	icon: 'tag',
	// 	to: '/estimer'
	// },
	// {
	// 	value: 'Produits',
	// 	icon: 'box',
	// 	to: '/products'
	// },
	// {
	// 	value: 'Factures',
	// 	icon: 'file-text',
	// 	to: '/invoices'
	// }
	{
		id: 1,
		title: 'Home',
		picto: 'home',
		url: '/'
	},
	{
		id: 2,
		title: 'Estimateur',
		picto: 'tag',
		url: '/estimer'
	},
	{
		id: 3,
		title: 'Produits',
		picto: 'box',
		url: '/products'
	},
	{
		id: 4,
		title: 'Factures',
		picto: 'file-text',
		url: '/invoices'
	}
]