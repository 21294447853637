import styled from 'styled-components';
import image from '../../assets/cards/background_up.png';
import { DivGlobal } from '../../globalStyle';

export const DivCards = styled(DivGlobal)`
	flex-wrap: wrap;
	margin-top: ${props => props.isRevendre ? '0' : '135px'};

	@media (min-width: 1003px) and (max-width: 1255px) {
		flex-flow: wrap;
	}

	@media (min-width: 417px) and (max-width: 599px) {
		margin-top: ${props => props.isRevendre ? '0' : '55px'};
	}

	@media (max-width: 416px) {
		margin-top: ${props => props.isRevendre ? '0' : '76px'};
	}
`;

export const LinkCard = styled.a`
	display: flex;
	justify-content: center;
	margin: 10px;
	position: relative;
	top: 80px;

	@media (max-width: 1500px) {
		top: 40px;
	}

	@media (min-width: 1003px) and (max-width: 1255px) {
		flex-basis: 24%;
	}

	@media (max-width: 383px) {
		margin: 3px;
	}
`;

export const ImageBackgroundDown = styled.img`
	width: 100%;
	position: relative;
	top: 169px;

	@media (max-width: 501px) {
		margin: 0;
		position: absolute;
		top: 123px;
	}

	@media (max-width: 383px) {
		top: 110px;
	}

	@media (max-width: 752px) {
		top: ${props => props.toResize && '192px'};
	}
`;
export const ProductPhoto = styled.img`
	position: absolute;
	height: 90px;

	margin-top: 36px;
	z-index: 1;
	transition: 0.2s;

	@media (max-width: 501px) {
		height: 83px;
		margin-top: 18px;
	}

	@media (max-width: 383px) {
		height: 70px;
		margin-top: 15px;
	}
`;

export const ProductIcon = styled(ProductPhoto)`
	opacity: 0;
	transition: opacity 0.5s;
`;

export const TitleCircleDiv = styled.div`
	position: absolute;
	top: 146px;
	width: 100%;
`;

export const InsideTitleCircle = styled(DivGlobal)`
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const CircleImage = styled.img`
	margin-top: 68px;
	transition: transform 1s;

	@media (max-width: 501px) {
		margin-top: 12px;
	}
`;

export const TitleCardHome = styled.h3`
	margin: 0;
	font-family: Raleway;
	display: inline-block;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.2;
	text-align: center;
	color: #142c49;
	z-index: 1;
	padding: 10px 15px;
	border-radius: 25px;
	transition: all 0.7s;

	@media (max-width: 501px) {
		font-size: 18px;
		padding: 8px 15px;
	}

	@media (max-width: 383px) {
		font-size: 16x;
	}
`;

export const Card = styled.div`
	cursor: pointer;
	width: 229px;
	height: 290px;
	border-radius: 10px;
	border: solid 1px #e8e8e8;
	background-color: #ffffff;
	display: flex;
	box-shadow: 0 0 20px #00000022;
	flex-direction: column;
	background-image: url(${image});
	background-size: contain;
	position: relative;
	overflow: hidden;
	transition: 0.2s;

	@media (min-width: 600px) {
		&:hover ${ProductPhoto} {
			height: 100px;
		}
	}

	&:hover {
		box-shadow: 0 3px 10px 0 rgba(90, 210, 240, 0.4);
		border: solid 1px ${props => props.theme.sunnyBlue};
		cursor: pointer;
	}

	@media (max-width: 501px) {
		width: 170px;
		height: 231px;
		background-position-y: 140px;
	}

	@media (max-width: 383px) {
		width: 151px;
		height: 222px;
		background-position-y: 126px;
	}
`;
