/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';
import { FlexColumn } from '../../../../globalStyle';

const fade = keyframes`
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`;

export const StyleContainerInformations = styled(FlexColumn)`
	background-color: ${props => props.theme.whiteColor};
	border-radius: 25px;
	justify-content: ${props =>
		props.stylePage.justifyContent ? props.stylePage.justifyContent : 'start'};
	box-sizing: border-box;

	animation: ${fade} 1.1s;

	padding: ${props => props.stylePage.padding};
	@media (min-width: 600px) {
		max-height: 650px;
		contain: content;
	}
`;
