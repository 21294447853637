import React from 'react';
import PropTypes from 'prop-types';
import { StyleContainerTabs, StyleTab, StyleRoundedNumber, TitleTab } from './style';

const defaultTabs = [
	{
		id: 1,
		title: 'ESTIMATION',
		highlighted: true,
	},
	{
		id: 2,
		title: 'PAIEMENT',
		highlighted: false,
	}
];

const generateTabs = ({tabs, minimizeFormat}) => {
	const arrayOfTabs = [];
	tabs.map((tab, index) => {
		arrayOfTabs.push(
			<StyleTab
				minimizeFormat={minimizeFormat}
				isFirstItem={index === 0}
				isHighlighted={tab.highlighted}
				key={tab.id}
				isLastItem={index === tabs.length - 1}
			>
				<StyleRoundedNumber
					isHighlighted={tab.highlighted}
					minimizeFormat={minimizeFormat}
				>
					{index + 1}
				</StyleRoundedNumber>
				<TitleTab
					isHighlighted={tab.highlighted}
					minimizeFormat={minimizeFormat}
				>
					{tab.title}
				</TitleTab>
			</StyleTab>
		);
	});
	return arrayOfTabs;
};

const ContainerTabs = ({
	tabs,
	minimizeFormat
}) => {
	return (
		<StyleContainerTabs minimizeFormat={minimizeFormat} >
			{generateTabs({tabs: tabs || defaultTabs, minimizeFormat })}
		</StyleContainerTabs>
	);
};

ContainerTabs.propTypes = {
	tabs: PropTypes.array,
	minimizeFormat: PropTypes.bool
};

export default ContainerTabs;
