import user from '../../assets/book/picto/user.svg';
import mail from '../../assets/book/picto/mail.svg';
import mapPin from '../../assets/book/picto/map-pin.svg';
import map from '../../assets/book/picto/map.svg';
import phone from '../../assets/book/picto/phone.svg';

const inputs = {
	lastName: {
		id: 1,
		picto: user,
		placeholder: 'Dupont',
		title: 'NOM',
		spec: {
			minLength: {
				condition: 2,
				errorMessage: 'Nombre de caractères insuffisants'
			},
			mandatory: {
				condition: true,
				errorMessage: 'Ce champs est requis'
			}
		},
		state: {}
	},
	firstName: {
		id: 2,
		picto: user,
		placeholder: 'Zack',
		title: 'PRENOM',
		spec: {
			minLength: {
				condition: 2,
				errorMessage: 'Nombre de caractères insuffisants'
			},
			mandatory: {
				condition: true,
				errorMessage: 'Ce champs est requis'
			}
		},
		state: {}
	},
	mail: {
		id: 3,
		picto: mail,
		placeholder: 'welcome@hellozack.fr',
		title: 'EMAIL',
		spec: {
			mandatory: {
				condition: true,
				errorMessage: 'Ce champs est requis'
			},
			regex: {
				condition: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				errorMessage: 'Cet email n\'est pas valide'
			}
		},
		state: {}
	},
	address: {
		id: 4,
		picto: mapPin,
		placeholder: '17 rue des Pommiers, 75002 Paris',
		title: 'ADRESSE',
		spec: {
			mandatory: {
				condition: true,
				errorMessage: 'Ce champs est requis'
			},
			zipcode: {
				condition: true,
				errorMessage: 'Soyez plus spécifique dans votre adresse'
			}
		},
		state: {}
	},
	phone: {
		id: 5,
		picto: phone,
		placeholder: '0601020304',
		title: 'TELEPHONE',
		spec: {
			mandatory: {
				condition: true,
				errorMessage: 'Ce champs est requis'
			},
			regex: {
				condition: /^\d{10}$/,
				errorMessage: 'Le numéro de téléphone doit comporter 10 chiffres'
			}
		},
		state: {}
	},
	moreAddress: {
		id: 6,
		picto: map,
		placeholder: 'Deuxième étage',
		title: 'COMPLEMENT D ADRESSE',
		spec: {
			mandatory: {
				condition: false
			}
		},
		state: {
			isCorrect: true
		}
	},
	serialNumber: {
		id: 7,
		picto: map,
		placeholder: 'Numéro de série',
		title: 'N° de série',
		spec: {
			mandatory: {
				condition: true,
				errorMessage: 'Ce champs est requis'
			}
		},
		state: {
			isCorrect: false
		}
	}
};

export default inputs;
