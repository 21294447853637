import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';

const AnimatedIndicator = styled(animated.img)`
	position: ${props => (props.restyle ? 'absolute' : 'initial')};
	right: ${props => (props.restyle ? '12px' : 'initial')};
	top: ${props => (props.restyle ? '21px' : 'initial')};
	z-index: 10;
	margin: 1px 5px 4px 0;
`;

const Indicator = ({ isCorrect, error, src, alt, restyle }) => {
	const indicatorAnimProps = useSpring({
		transform: isCorrect || error ? 'scale(1)' : 'scale(2)',
		opacity: isCorrect || error ? 1 : 0,
		from: { opacity: 0 }
	});

	return (
		<AnimatedIndicator
			src={src}
			alt={alt}
			style={indicatorAnimProps}
			restyle={restyle ? 1 : 0}
		/>
	);
};

export default Indicator;

Indicator.propTypes = {
	isCorrect: PropTypes.bool,
	error: PropTypes.string,
	src: PropTypes.string,
	alt: PropTypes.string,
	restyle: PropTypes.bool
};
