import React from 'react';
import PropTypes from 'prop-types';
import { StyleContainerInformations } from './style';
import Information from '../../Informations/Information/index';

const ContainerInformations = ({
	actualSet,
	actualQuestion,
	handleURLClick,
}) => {

	const getNamePage = () => {
		if (actualSet.name === 'state') {
			return {
				padding: '7% 6% 3%',
				justifyContent: 'space-evenly',
				marginBottomParaph: '3%',
				marginTitle: '0 0 2%'

			};
		} if (['charac', 'optional'].includes(actualSet.name)) {
			return {
				padding: '8%',
				justifyContentGlobal: 'flex-start',
				justifyContentParaph: 'space-between',
				height: '100%',
				informationHeight: '100%'

			};
		}
	};

	const generateInformations = () => {
		const arrOfInformations = [];
		actualSet.arrInformations.map((information) => {
			arrOfInformations.push(
				<Information
					key={information.title}
					highlighted={
						information.linkedWith.includes(actualQuestion.name)
						|| !information.linkedWith
					}
					title={information.title}
					arrContent={information.content}
					handleURLClick={handleURLClick}
					stylePage={getNamePage()}
					marginTitle={getNamePage().marginTitle}
				/>
			);
		});
		return arrOfInformations;
	};


	return (
		<StyleContainerInformations stylePage={getNamePage(actualSet)}>
			{generateInformations()}
		</StyleContainerInformations>
		// Form Summary Component !!!
	);
};

ContainerInformations.propTypes = {
	actualSet: PropTypes.object,
	actualQuestion: PropTypes.object,
	handleURLClick: PropTypes.func
};

export default ContainerInformations;
