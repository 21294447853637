/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { DivGlobal, FlexColumn } from '../../../../globalStyle';

export const StyledOfferCard = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 25px;
  justify-content: flex-end;
  box-sizing: border-box;
  overflow: hidden;
`;

export const StyleTitleNoOffer = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.1;
  text-align: center;
  color: #f0875b;
  @media (max-width: 600px) {
    font-size: 18px;
    padding: 5% 0%;
  }
`;

export const ContainerContentNoOffer = styled(FlexColumn)`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: #142b4a;
  @media (max-width: 600px) {
    font-size: 16px;
    padding-bottom: 7%;
  }
`;

export const StyleContentNoOffer = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: #142b4a;
  margin: 10px 0;
  @media (max-width: 600px) {
    font-size: 15px;
    display: ${props => !props.mobileDisplay && 'none'};
  }
`;

export const ContainerNoOffer = styled(StyledOfferCard)`
  justify-content: space-between;
`;

export const SeparatorNoOffer = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d4e1f4;
  @media (max-width: 600px) {
    margin-bottom: 8%;
  }
`;

export const StyleTitleInput = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.1;
  text-align: center;
  color: #142b4a;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const StyleSubtitleInput = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.1;
  text-align: center;
  color: #142b4a;
  @media (max-width: 600px) {
    margin-bottom: 8%;
  }
`;

export const ContainerInput = styled(FlexColumn)`
  @media (max-width: 1050px){
    align-items: center;
  }
  @media (max-width: 600px) {
    margin-bottom: 5%;
  }
`;

export const ContainerContentGlobal = styled(FlexColumn)`
  height: 100%;
  justify-content: space-evenly;
  padding: 6% 6% 0;
`;

export const StyleButtonMail = styled.button`
  width: 100%;
  height: 52px;
  border-radius: 4px;
  background-color: #d4e1f4;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;

  font-family: Raleway;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: #7187a8;

  @media (min-width: 1050px) {
  margin-top: 22px;

  }
`;

export const StyleContainerMailSent = styled(DivGlobal)`
  width: 100%;
  height: 45px;
  border: solid 1px #c2f05a;
  background-color: rgba(194, 240, 90, 0.05);
  align-items: center;
  border-radius: 4px;
  margin-top: 14px;

  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  text-align: center;
  color: #c2f05a;
`;
