import React from 'react';
import PropTypes from 'prop-types';

import {
	StyledOfferNextSteps,
	StyledStepsVisuals,
	StyledStep,
	StyledStepTextsContainer,
	StyledStepText
} from './style';

import stepsArrow from '../../../../assets/form/offerCard/steps-arrow.svg';

const OfferNextSteps = ({ model }) => {
	const formatModel = (model) => {
		if (['Apple'].includes(model.split(' ')[0])) {
			return 'Apple Watch';
		}
		return model.split(' ')[0];
	};

	return (
		<StyledOfferNextSteps>
			<h3>
				Les dernières étapes
				{' '}
				<span>...</span>
			</h3>
			<StyledStepsVisuals>
				<StyledStep first>
					<div>1</div>
					<span />
				</StyledStep>
				<img src={stepsArrow} alt="flèche étape" />
				<StyledStep>
					<span />
					<div>2</div>
				</StyledStep>
			</StyledStepsVisuals>
			<StyledStepTextsContainer>
				<StyledStepText first>
					Renseignez quelques informations
				</StyledStepText>
				<StyledStepText>Recevez votre paiement sécurisé !</StyledStepText>
			</StyledStepTextsContainer>
		</StyledOfferNextSteps>
	);
};

export default OfferNextSteps;

OfferNextSteps.propTypes = {
	model: PropTypes.string
};
