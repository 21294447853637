import mask from '../../assets/calendar/picto/mask.png';
import iphone from '../../assets/calendar/picto/iphone.png';
import db from '../../assets/calendar/picto/db.png';

const informations = {
	smartphone: [
		{
			id: 1,
			logo: mask,
			text: {
				type: 'paragraph',
				content: 'Le client certifie que son iPhone n’est ni contrefait, ni volé et qu\'il a le droit de le céder dans le cadre d’une reprise.'
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		},
		{
			id: 2,
			logo: iphone,
			text: {
				type: 'paragraph',
				content: 'La carte SIM a été retiré'
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		},
		{
			id: 3,
			logo: db,
			text: {
				type: 'list',
				title: '',
				content: [
					{ id: 1, text: 'Aucun code de verrouillage' },
					{ id: 2, text: 'La fonction "localiser mon iPhone", désactivée' }
				]
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		}
	],
	tablet: [
		{
			id: 4,
			logo: mask,
			text: {
				type: 'paragraph',
				content: 'Le client certifie que son iPad n’est ni contrefait, ni volé et qu\'il a le droit de le céder dans le cadre d’une reprise.'
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		},
		{
			id: 5,
			logo: iphone,
			text: {
				type: 'paragraph',
				content: 'La carte SIM a été retiré'
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		},
		{
			id: 6,
			logo: db,
			text: {
				type: 'list',
				title: '',
				content: [
					{ id: 1, text: 'Aucun code de verrouillage' },
					{ id: 2, text: 'La fonction "localiser mon iPad", désactivée' }
				]
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		}
	],
	'laptop computer': [
		{
			id: 7,
			logo: mask,
			text: {
				type: 'paragraph',
				content: 'Le client certifie que son MacBook n’est ni contrefait, ni volé et qu\'il a le droit de le céder dans le cadre d’une reprise.'
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		},
		{
			id: 8,
			logo: db,
			text: {
				type: 'list',
				title: '',
				content: [
					{ id: 1, text: 'Aucun code de verrouillage' },
					{ id: 2, text: 'Compte iCloud désactivé' },
					{ id: 3, text: 'La fonction "localiser mon MacBook", désactivée' },
					{ id: 4, text: 'FileVault désactivé' }
				]
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		}
	],
	watch: [
		{
			id: 9,
			logo: mask,
			text: {
				type: 'paragraph',
				content: 'Le client certifie que son Apple Watch n’est ni contrefaite, ni volée et qu\'il a le droit de la céder dans le cadre d’une reprise.'
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		},
		{
			id: 10,
			logo: db,
			text: {
				type: 'list',
				title: '',
				content: [
					{ id: 1, text: 'Compte iCloud, désactivé' },
					{ id: 2, text: 'La fonction "localiser mon Apple Watch", désactivée' },
				]
			},
			validationCheckbox: true,
			state: {
				checked: false
			}
		}
	],
};

export default informations;