import styled from 'styled-components';

export const StyledWrapper = styled.div`
	background-color: white;
	width: 100%;
	padding: 1rem 0;
	border-bottom: 1px solid rgba(0, 40, 100, 0.12);
`;

export const StyledContainerNavbar = styled.div`
	width: 90%;
	margin: 0 auto;
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Logo = styled.img`
	height: 2rem;
	width: auto;
`;

export const StyledUserContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const Name = styled.span`
	color: #495057;
	box-sizing: border-box;
`;

export const Role = styled.span`
	color: #9aa0ac;
	box-sizing: border-box;
	font-size: 12px;
`;

export const Info = styled.div`
	display: flex;
	margin-right: 0.5em;
	flex-direction: column;
	justify-content: center;
	margin-left: 0.5rem;
`;