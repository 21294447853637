/* eslint-disable no-console */
import axios from 'axios';

import config from '../../config/endpoints.js';

const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';
const baseEstimator = config[env].estimator;
const basePricing = config[env].pricing;

/**
 * Perform a get request to Estimator DB and return estimator data according to id
 * @param {Object} parameter object
 * @param {String} parameter.id
 * @return {Object} Object containing all the informations needed to generate the forms
 */
async function getEstimatorInfo({ id } = {}) {
	try {
		const res = await axios.get(`${baseEstimator}/estimator/${id}`);
		return res.data.result
	} catch (error) {
		throw error;
	}
}

/**
 * Perform as many getEstimatorInfo 
 * @param {Object} parameter object
 * @param {String} parameter.ids
 * @return {Object} Object containing all the informations needed to generate the forms
 */
async function getEstimatorsInfo({ ids } = { }) {
	try {
		return await Promise.all(
			ids.map((id) => getEstimatorInfo({ id }))
		);
	} catch (error) {
		console.error(error);
		return error;
	}
}

/**
 * Perform a get request to Estimator DB and return the wanted form's data
 * @param {Object} parameter object
 * @param {String} parameter.url
 * @return {Object} Object containing all the informations needed to generate the forms
 */
async function getFormsData({ pathname, signal } = {}) {
	try {
		const res = await axios.get(`${baseEstimator}${pathname}`, { cancelToken: signal.token });
		return res.data.result
	} catch (error) {
		throw error;
	}
}

/**
 * Perform a post request to Frank's API and return offer and other details
 * @param {Object} parameter object,
 * @param {Object} parameter.body body that will be passed to request
 * @return {Object} response with offer
 */
async function sendEstimate({ body }) {
	const res = await axios({
		method: 'post',
		url: `${basePricing}/estimations`,
		data: body
	});
	if (!res) {
		console.error('No result');
	}
	return res.data;
}

export { getFormsData, sendEstimate, getEstimatorInfo, getEstimatorsInfo };
