import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

export const StyledDropdownInput = styled.div`
	font-family: ${props => props.theme.secondFontFamily};
	display: flex;
	flex-direction: column;
	cursor: pointer;
	user-select: none;
	align-items: center;
	width: 100%;
	height: ${props => (props.isOpen ? '240px' : '51px')};
	border-radius: 10px;
	overflow: hidden;
	border: ${props => (props.isOpen ? 'solid 1px #d4e1f4' : 'none')};
	background-color: ${props => (props.isOpen ? 'fff' : '#f5f8ff')};
	box-shadow: ${props => props.isOpen ? '0 3px 20px 0 rgba(67, 115, 253, 0.2);' : 'none'};
	transition: height 0.5s;
	${props => !props.isActive
		&& `background-color: ${props.theme.sunnyBlue}; cursor: auto;`}
`;

export const SelectedElementContainer = styled.div`
	width: 100%;
	height: 51px;
	position: relative;
	border-bottom: solid 1px #f3f3f3;
`;

export const DownArrowContainer = styled.div`
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	& img {
		position: absolute;
		padding: 21.2px;
		transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
		transition: transform 0.5s;
	}
`;

export const SelectedTextContainer = styled.div`
	width: 100%;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.11;
	color: ${props => (props.isActive ? props.theme.sunnyBlue : 'white')};
	p {
		margin-top: 1em;
	}

	@media (max-width: 600px) {
		font-size: 16px;
	}
`;

export const StyledSelectList = styled.div`
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-y: scroll;
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 10px;
	}
	width: 100%;
`;

export const StyledOption = styled.div`
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	padding: 15px 0;
	color: #728092;
	background-color: #fff;

	${!isMobile
		&& css`
			padding-left: 15px;
		`}

	&:hover {
		background-color: #fafbfb;
	}
`;
