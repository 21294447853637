import styled from 'styled-components';

export const StyledIframe = styled.iframe`
	height: calc(100% - 100px);
	width: calc(100% - 40px);
	border-style: none;
`;

export const ContainerIframe = styled.div`
	width: 100%;
	height: 100%;
	padding: 50px 20px;
	overflow-y: hidden;
`;

export const ContainerIframeMobileSafari = styled.div`
	height: calc(100% - 100px);
	width: 100%;
	position: fixed;
	left: 20px;
	top: 50px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	border-style: none;
`;

export const StyledIframeMobileSafari = styled.iframe`
	width: calc(100% - 40px);
	border-style: none;
`;
