import styled from 'styled-components';

export const StyledOfferNextSteps = styled.div`
	padding: 0 2rem;
	height: 27%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	& h3 {
		font-family: ${props => props.theme.mainFontFamily};
		font-size: 13px;
		margin: 5px 0;
		font-weight: bold;
		line-height: 1.28;
		color: ${props => props.theme.darkBlueColor};
		text-align: center;
	}
`;

export const StyledStepsVisuals = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
`;

export const StyledStep = styled.div`
	display: flex;
	${props =>
		props.first ? 'padding: 0 7px 0 45px;' : 'padding: 0 45px 0 7px;'}
	flex-direction: row;
	align-items: center;
	font-family: ${props => props.theme.mainFontFamily};

	& div {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: ${props =>
			(props.first && props.theme.blueGrey) || props.theme.sunnyBlue};
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		color: ${props =>
			(props.first && props.theme.greenGrey) || props.theme.whiteColor};
		line-height: 15px;
	}

	& span {
		display: block;
		width: 38px;
		height: 5px;
		background-color: ${props => props.theme.lightGrey};
	}
`;

export const StyledStepTextsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	width: 100%;
`;

export const StyledStepText = styled.p`
	padding: 0;
	margin: 0;
	font-size: 13px;
	font-family: ${props => props.theme.mainFontFamily};
	font-weight: 500;
	line-height: 1.19;
	text-align: center;
	color: ${props =>
		(props.first && props.theme.greenGrey) || props.theme.sunnyBlue};

	@media (max-width: 1550px) and (min-width: 1080px) {
		font-size: 12px;
	}
`;
