import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from '../../ProgressBar';

const ContainerProgressBar = ({
	progressBarProps
}) => {
	const themeProgressBar = {
		width: '100%',
		height: '2px',
		backgroundColor: '#f3f3f3'
	};

	const themeContent = {
		width: progressBarProps
	};
	return (
		<div style={{width: '100%'}}>
			<ProgressBar themeProgressBar={themeProgressBar} themeContent={themeContent} />
		</div>
	);
};

export default ContainerProgressBar;

ContainerProgressBar.propTypes = {
	progressBarProps: PropTypes.number
};
