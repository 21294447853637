/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { animated } from 'react-spring';
import { FlexColumn } from '../../../../globalStyle';

export const StyleContainerQuestion = styled(FlexColumn)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media (max-width: 600px) {
		margin-bottom: 30px;
		${props => !props.isActive && 'display: 0.5;'}
		display: ${props => props.isActive && 'flex' || 'none'}
	}

	@media (min-width: 601px) {
		margin-bottom: 30px;
		opacity: ${props => (props.show ? '1' : '0')};
		${props => !props.isActive && 'opacity: 0.5;'}
	}
`;

export const StyleContainerAnimation = styled(animated.div)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: ${props => props.isimgradio === 'true' ? null : '100%'};
`;