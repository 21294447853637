import React from 'react';
import styled from 'styled-components';
import { Grid, Table, Card, Button } from "tabler-react";
import { EmptyLine } from '../../globalStyle';


const Wrapper = styled.div`
	width: 90%;
	height: 100%;
	margin: 2em auto auto auto;
`;

const LazyProducts = () => {
	const generateProducts = () => {
		const arrToReturn = [];
		(Array.from(Array(7).keys())).map((product, index) => {
			arrToReturn.push(
				<Table.Row key={index}>
					<Table.Col>
						<EmptyLine width={['30px', '25px', '20px']} height={['30px', '25px', '20px']} light />
					</Table.Col>
					<Table.Col>
						<EmptyLine width={['90px', '50px', '40px']} height={['15px', '12px', '10px']} />
					</Table.Col>
					<Table.Col>
						<EmptyLine width={['90px', '50px', '40px']} height={['15px', '12px', '10px']} light />
					</Table.Col>
					<Table.Col>
						<EmptyLine width={['90px', '50px', '40px']} height={['15px', '12px', '10px']} />
					</Table.Col>
					<Table.Col>
						<EmptyLine width={['90px', '50px', '40px']} height={['15px', '12px', '10px']} light />
					</Table.Col>
					<Table.Col>
						<EmptyLine width={['90px', '50px', '40px']} height={['15px', '12px', '10px']} light />
					</Table.Col>
					<Table.Col>
						<EmptyLine width={['60px', '30px', '20px']} height={['15px', '12px', '10px']} light />
					</Table.Col>
				</Table.Row>
			)
		});
		return arrToReturn;
	}

	const generateTitles = () => {
		return(
			<Table.Row>
				<Table.Col>
					<EmptyLine width={['90px', '60px', '40px']} height={['20px', '17px', '15px']} light />
				</Table.Col>
				<Table.Col>
					<EmptyLine width={['90px', '60px', '40px']} height={['20px', '17px', '15px']} />
				</Table.Col>
				<Table.Col>
					<EmptyLine width={['90px', '60px', '40px']} height={['20px', '17px', '15px']} light />
				</Table.Col>
				<Table.Col>
					<EmptyLine width={['90px', '60px', '40px']} height={['20px', '17px', '15px']} />
				</Table.Col>
				<Table.Col>
					<EmptyLine width={['90px', '60px', '40px']} height={['20px', '17px', '15px']} light />
				</Table.Col>
				<Table.Col>
					<EmptyLine width={['90px', '60px', '40px']} height={['20px', '17px', '15px']} />
				</Table.Col>
				<Table.Col>
					<EmptyLine width={['90px', '60px', '40px']} height={['20px', '17px', '15px']} light />
				</Table.Col>
			</Table.Row>
		)
	}
	return (
		<Wrapper>
			<Grid.Row>
				<Grid.Col lg={12}>
					<Card>
						<Table classNames="card-table table-vcenter">
							<Table.Body>
								<Table.Row>
									<Table.Col>
										<EmptyLine width={['100px', '65px', '50px']} height={['20px', '17px', '15px']}/>
									</Table.Col>
								</Table.Row>
								{generateTitles()}
								{generateProducts()}
							</Table.Body>
						</Table>
					</Card>
				</Grid.Col>
			</Grid.Row>
		</Wrapper>
	);
}

export default LazyProducts;