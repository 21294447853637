import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Navbar from '../components/Navbar/index';
import styled from 'styled-components';
import NavbarTab from '../components/NavbarTab/index';
import Lazy from '../pages/Lazy';
import { Grid, Table, Card, Page, Icon, Tag, Button } from 'tabler-react';
import { UserContext } from '../components/context/UserContext';
import { getInvoices, sendCallInvoices } from '../controllers/invoices/API';
import { getEstimatorInfo } from '../controllers/estimator/API';
import clearToken from '../controllers/toolsAPI';
import { getCorrectId } from '../controllers/tools';
import Modal from '../components/modal'


const Wrapper = styled.div`
	width: 90%;
	height: 100%;
	margin: 2em auto auto auto;
`;

const Div = styled.div`
	display: flex;
	align-items: center;
	padding: ${(props) => props.padding ? props.padding : ''};
	justify-content: ${(props) => props.justify ? props.justify : 'flex-start'};
`;


const Invoices = () => {
	const [user, setUser] = useContext(UserContext);
	const [showModal, setShowModal] = useState(false)
	const [modalPeriod, setModalPeriod] = useState(null)
	const [estimatorInfo, setEstimatorInfo] = useState(null);
	const [invoices, setInvoices] = useState({ })
	const [displayDetailsInvoices, setDisplayDetailsInvoices] = useState({ })
	const [ready, setReady] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			try {
				const estimData = await getEstimatorInfo({ id: getCorrectId({ user }) })
				const invoices = await getInvoices({ user_id: user.role === 'zacker' ? user.id : null });
				setEstimatorInfo(estimData);
				setDisplayDetailsInvoices(Object.entries(invoices).reduce((result, [key, value]) => {
					let productsDefaultObject = Object.keys (value).reduce ((result, key) => {
						return {
							...result,
							[key]: false
						}
					}, {})
					result[key] = {
						sending: false,
						show: false,
						products: productsDefaultObject
					}
					return result;
				}, { }))
				setInvoices(invoices);
				setReady(true)
			} catch (error) {
				console.log('Something went wrong, Invoices', error)
				clearToken();
			}
		}
		fetch();
	}, [])

	const generateTitles = () => {
		return (
			<Table.Header key='unik'>
				<tr>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						PERIODE
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						QUANTITE
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						MONTANT HT
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						MONTANT TTC
					</Table.ColHeader>
					<Table.ColHeader style={{ verticalAlign: 'middle'}}>
						ACTION
					</Table.ColHeader>
				</tr>
			</Table.Header>
		)
	}

	const generateProductsDetails = ({ model, period}) => {
		const arr_to_return = [];
		Object.values (invoices[period][model].details).map ((product) => {
			arr_to_return.push (
				<Table.Row key={product.sku} class="deep_details_invoice">
					<Table.Col>
						<small style={{marginLeft: '4em'}}>
							<Tag color="blue">SKU: {product.sku.replace (/_A|_B/g, '')}</Tag>
						</small>
					</Table.Col>
					<Table.Col>
						<small>1</small>
					</Table.Col>
					<Table.Col>
						<small>{product.com}€</small>
					</Table.Col>
					<Table.Col>
						<small>{product.com_ttc}€</small>
					</Table.Col>
					<Table.Col />
				</Table.Row>
			)
		})
		return arr_to_return
	}

	const handleModelClick = ({ model, period }) => {
		let new_obj = {
			...displayDetailsInvoices,
			[period]: {
				...displayDetailsInvoices[period],
				products: {
					...displayDetailsInvoices[period].products,
					[model]: !displayDetailsInvoices[period].products[model]
				}
			}
		}
		setDisplayDetailsInvoices(new_obj)
	}

	const generateDetails = ({ products, period } = { }) => {
		const arrToReturn = []
		Object.entries(products).map(([model, data], index) => {
			arrToReturn.push(
				<>
					<Table.Row key={model + index}>
						<Table.Col style={{marginLeft: '1em'}}>
							<Icon style={{ marginRight: "1em", marginLeft: "1em"}} name={displayDetailsInvoices[period].products[model] ? 'zoom-out' : 'zoom-in'} onClick={() => handleModelClick({ model, period })} />
							<small>{model}</small>
						</Table.Col>
						<Table.Col>
							<small>{data.quantity}</small>
						</Table.Col>
						<Table.Col>
							<small>{data.total_comission_ht}€</small>
						</Table.Col>
						<Table.Col>
							<small>{data.total_comission_ttc}€</small>
						</Table.Col>
						<Table.Col />
					</Table.Row>
					{displayDetailsInvoices[period].products[model] ?
						generateProductsDetails({ model, period })
					: null}
				</>
			)
		})
		return arrToReturn;
	}

	const handleClick = (period) => {
		setDisplayDetailsInvoices({
			...displayDetailsInvoices,
			[period]: {
				...displayDetailsInvoices[period],
				show: !displayDetailsInvoices[period].show
			}
		})
	}

	const handleModal = (period) => {
		setModalPeriod(period)
		setShowModal(true)
	}
	const handleSendInvoice = async (period) => {
		setShowModal(false)
		setDisplayDetailsInvoices ({
			...displayDetailsInvoices,
			[period]: {
				...displayDetailsInvoices[period],
				sending: true
			}
		})
		await sendCallInvoices ({ body: { period, mail: user.mail }, partner_id: user.id })

		setTimeout (() => {
			setDisplayDetailsInvoices ({
				...displayDetailsInvoices,
				[period]: {
					...displayDetailsInvoices[period],
					sending: false
				}
			})
		}, 3000)
	}

	const generateInvoice = (products, period) => {
		return (
			<>
				<Table.Row key={period}>
					<Table.Col>
						<Icon style={{ marginRight: "1em"}} name={displayDetailsInvoices[period].show ? 'zoom-out' : 'zoom-in'} onClick={() => handleClick(period)} />
						<strong>{period}</strong>
					</Table.Col>
					<Table.Col>
						{Object.values(products).reduce((sum, product) => sum + product.quantity, 0)}
					</Table.Col>
					<Table.Col>
						{Object.values(products).reduce((sum, product) => sum + product.total_comission_ht, 0)}€
					</Table.Col>
					<Table.Col>
						{Object.values(products).reduce((sum, product) => sum + product.total_comission_ttc, 0)}€
					</Table.Col>
					<Table.Col>
						<Button color="blue" loading={displayDetailsInvoices[period].sending} onClick={() => handleModal(period)}>SEND</Button>
					</Table.Col>
				</Table.Row>
				{displayDetailsInvoices[period].show ?
					generateDetails({ products, period })
				: null}
			</>
		)
	}

	const generateTables = () => {
		const arrToReturn = [
			generateTitles()
		];
		Object.entries(invoices).map(([period, products]) => {
			arrToReturn.push(
				<Table.Body key={period}>
					{generateInvoice(products, period)}
				</Table.Body>
			)
		})
		return arrToReturn
	}

	return ready && (
		<>
			<Modal show={showModal} setShowModal={setShowModal} template='invoiceMail' data={{handleSendInvoice, user, setUser, modalPeriod}}/>
			<Navbar />
			<NavbarTab />
			<Wrapper>
				<Grid.Row>
						<Grid.Col lg={12}>
							<Card>
								<Div padding="0em 1em" justify="space-between">
									<Page.Title>Factures</Page.Title>
									{/* <Button icon="box" color="primary" onClick={handleGenerateExpeditions}>
										DO IT!
										({checkedProducts.length})
									</Button> */}
								</Div>
								<Table classNames="card-table table-vcenter">
									<Table.Header>
										<tr></tr>
									</Table.Header>
									{/* {generateTitles()} */}
									{/* <Table.Body> */}
										{generateTables()}
									{/* </Table.Body> */}
								</Table>
							</Card>
						</Grid.Col>
					</Grid.Row>
			</Wrapper>
		</>
	) || ( <Lazy template="products" /> );
}

export default Invoices;