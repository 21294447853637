import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledContainerNavButtons, StyledInfoButton } from './style';
import { StyledReceiveButton } from '../../Informations/OfferReceiveButton/style';
import NavButton from '../../NavButton';
import { StyledNavButton } from '../../NavButton/style';

import { PlatformContext } from '../../../context/PlatformContext';

const ContainerNavButtons = ({
	handleClick,
	wording,
	isPastQuestion,
	displayNext,
	handleFAQClick,
	formState,
	isModalOpen
}) => {
	const [platform, setPlatform] = useContext(PlatformContext);

	const displayCrisp = !displayNext;

	useEffect(() => {
		// if (displayCrisp && !isModalOpen) {
		// 	$crisp.push(['do', 'chat:show']);
		// } else {
		// 	$crisp.push(['do', 'chat:hide']);
		// }
	}, []);

	const onFAQClick = () => {
		handleFAQClick();
	};
	return (
		<StyledContainerNavButtons isPastQuestion={isPastQuestion}>
			{(isPastQuestion && (
				<NavButton
					direction="previous"
					title="retour page précédente"
					handleClick={handleClick}
					displayCrisp={!!displayNext}
				/>
			))}
			{/* Commented out info button. To reenable when necessary */}
			{/* {platform !== 'desktop' && handleFAQClick && (
				<StyledInfoButton onClick={onFAQClick} isFirstQuestion={!!formState.model.value}>
					<img src={infoButtonPic} alt="FAQ button" width="45px" />
				</StyledInfoButton>
			)} */}
			{(displayNext && !wording && (
				<NavButton
					direction="next"
					handleClick={handleClick}
					displayCrisp={!!displayNext}
				/>
			))
				|| null}
			{(displayNext && wording && (
				<StyledReceiveButton
					style={{ marginRight: '10px' }}
					onClick={handleClick}
					medium
				>
					{wording}
				</StyledReceiveButton>
			))
				|| null}
		</StyledContainerNavButtons>
	);
};

export default ContainerNavButtons;

ContainerNavButtons.propTypes = {
	wording: PropTypes.string,
	isPastQuestion: PropTypes.bool,
	displayNext: PropTypes.bool,
	handleClick: PropTypes.func,
	handleFAQClick: PropTypes.func,
	formState: PropTypes.object,
	isModalOpen: PropTypes.bool
};
