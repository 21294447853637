/* eslint-disable camelcase */
import axios from 'axios';

import config from '../../config/endpoints';
const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';
const baseUrl = config[env].baseUrl;

/**
 * Perform a get request to fetch user data
 * @param {Object} parameter object
 * @param {Number} parameter.offerId offer's id related to user
 */
export const getUser = async ({ offerId } = { }) => {
	if (!offerId) { return {}; }
	try {
		const res = await axios.get(`${baseUrl}/api/v2/bookings/rebooking_informations/${offerId}`);
		if (res) {
			return res.data;
		}
	} catch (err) {
		return {};
	}
};

/**
 * Perform a get request to fetch estimation data
 * @param {Object} parameter object
 * @param {Number} parameter.offerId offer's id related to his estimation
 */
export const getEstimation = async ({ offerId } = { }) => {
	if (!offerId) { return {}; }
	const res = await axios.get(`${baseUrl}/api/v2/estimations/${offerId}`);
	if (res) { return res.data; }
	return {};
};

/**
 * Perform a post request to confirm a booking
 * @param {Object} parameter object
 * @param {String} parameter.body specify the body of the request
 */
export const postBook = async ({ body, category }) => {
	try {
		const res = await axios.post(`${baseUrl}/api/v2/bookings/book/${category}`, body);
		if (res) {
			return res.data;
		}
		return false;
	} catch (err) {
		console.error({ err });
	}
};

/**
 * Perform a post request to create or update a user
 * @param {Object} parameter object
 * @param {Number} parameter.body body containing all informations of the user
 * @param {Array} parameter.role role associated to the user
 */
export const postUser = async ({ body, role } = { }) => {
	const res = await axios.post(`${baseUrl}/api/v2/users/${role || 'user'}`, body);
	if (res) {
		return res.data;
	}
};

/**
 * Perform a put request to save payment user infos
 * @param {Object} parameter object
 * @param {Number} parameter.body body containing payment infos
 * @param {Array} parameter.offer_id offer_id associated to the user
 */
export const putPaymentInfos = async ({ body, offer_id } = { }) => {
	const res = await axios.put(`${baseUrl}/api/v2/estimations/${offer_id}`, body);
	if (res) {
		return res.data;
	}
};

/**
 * Perform a put request to assign estimations to a defined user
 * @param {Object} parameter object
 * @param {Number} parameter.userId
 * @param {Array} parameter.offerIds array of offerIds to assign to the user
 */
export const assignEstimation = async ({ userId, offerIds, serialNumber } = { }) => {
	const res = await axios.put(`${baseUrl}/api/v2/users/${userId}/assign_estimations`, {
		offer_ids: offerIds,
		...( serialNumber ? { serial_number: serialNumber } : { })
	});
	if (res) { return res.data; }
};
