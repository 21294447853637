/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { DivGlobal, FlexColumn } from '../../../../globalStyle';

export const MainPayment = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 210px;
	width: 100%;
	padding-top: 3%;

	@media (max-width: 1049px) {
		display: none;
	}

	@media (min-width: 1050px) {
		/* height: 300px; */
		height: 248px;
	}
	@media (min-width: 1050px) and (max-width: 1202px) {
		height: 262px;
	}
`;

// ==================Styles for CardOffer component================= //

export const CardOfferDiv = styled.div`
	display: flex;
	width: 100%;
	display: ${props => (props.screenWidth > 780 ? 'none' : 'flex')};
	@media (max-width: 1049px) {
		justify-content: center;
	}
`;

export const CardOfferContent = styled(FlexColumn)`
	height: 100%;
	width: 100%;
	min-height: 160px;
	padding: 4px;

	@media (min-width: 1050px) {
		/* box-shadow: 0 3px 30px rgba(90, 210, 240, 0.2);
		border-radius: 10px;
		border: 1px solid ${props => props.theme.sunnyBlue}; */
		background-color: ${props => props.theme.whiteColor};
		max-height: 230px;
	}
	@media (max-width: 779px) {
		padding: 0 20px;
		margin-bottom: 15px;
	}
`;

export const ModelOffer = styled.h2`
	color: #707070;
	font-family: Raleway;
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	margin-top: 0;
`;

export const ModelOfferYour = styled(ModelOffer)`
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
	margin-top: 17px;
`;

export const ModelImg = styled.img`
	max-height: 113px;
	width: auto;
`;

export const OfferValue = styled.h2`
	color: ${props => props.theme.sunnyBlue};
	font-family: Lato;
	font-size: 27px;
	font-weight: 700;
	text-align: center;
	margin: 8px 0;
	padding-bottom: 5px;
`;

export const DivOffer = styled(DivGlobal)`
	@media (min-width: 1050px) {
		justify-content: ${props => (props.display ? 'flex-end' : 'center')};
	}
	align-items: center;
`;

export const DivValueCodeParrainAdded = styled.div`
	display: ${props => (props.display ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 10px;
	border-radius: 20px;
	border: 1px solid #f3f3f3;
	padding: 5px;
	margin: 0 7px;
	color: #8ad086;
	font-size: 13px;
	font-family: Lato;
`;

// ==================Styles for CardOffer component================= //
// ==================Styles for PaymentStuff component================= //

export const PaymentStuffDiv = styled(FlexColumn)`
	width: 48%;
	min-width: 310px;

	@media (max-width: 1049px) {
		width: 100%;
	}

	@media (min-width: 1050px) and (max-width: 1390px) {
		min-width: 250px;
	}

	@media (min-width: 1050px) {
		justify-content: flex-start;
	}
`;

export const PaymentMode = styled.div`
	display: flex;
	justify-content: start;

	width: 100%;


	@media (max-width: 1049px) {
		justify-content: flex-start;
	}

	@media (min-width: 1050px) {
		width: 100%;
	}

	@media (max-width: 779px) {
		/* margin: 0 20px; */

	}
`;

export const TitlePayment = styled.h2`
	width: max-content;
	display: flex;
	align-items: center;
	color: #142b4a;
	font-family: Raleway;
	font-size: 16px;
	font-weight: 700;

	@media (max-width: 779px) {
		padding: 0 20px;
	}
`;

export const ChoiceModeVirementDiv = styled(DivGlobal)`
	width: 100%;
	height: 40px;
	border-radius: 20px;
	background-color: ${props => props.colorBackground || '#f3f3f3;'};
	align-items: center;
	margin-right: 15px;
	cursor: pointer;
	transition: 0.4s;
	@media (max-width: 779px) {
		width: 50%;
	}
`;

export const ChoiceModePaypalDiv = styled(ChoiceModeVirementDiv)`
	margin-right: 0;
`;

export const ChoiceModeImg = styled.img`
	width: 18px;
	height: 18px;
	margin: 0 20px 0 3px;
`;

export const ChoiceModeVirement = styled.p`
	color: ${props => props.colorText || '#707070;'};
	font-family: Raleway;
	font-size: 13px;
	font-weight: 500;
`;

export const ChoiceModePaypal = styled(ChoiceModeVirement)`
	color: ${props => props.colorText || '#707070;'};
`;

export const LogoPaypal = styled.img`
	width: 57px;
	height: 16px;
	margin-left: 5px;
	display: ${props => props.displayLogo || 'none'};
`;

export const FieldSet = styled.fieldset`
	height: 44px;
	border-radius: 7px;
	border: 1px solid #d3d3d3;
	padding: 0;
	margin: 0;
	position: relative;

	@media (max-width: 780px) {
		margin: 0 20px;
	}
`;

export const Legend = styled.legend`
	width: fit-content;
	padding-inline-end: 15px;
	padding-inline-start: 15px;
	color: #707070;
	font-family: Raleway;
	font-size: 12px;
	font-weight: 500;
`;

export const TextInput = styled.input`
	width: calc(100% - 20px);
	border: none;
	border-radius: 7px;
	color: #142b4a;
	font-family: Lato;
	font-size: 16px;
	font-weight: 500;
	line-height: 18px;
	text-align: left;
	outline: none;
	margin: 1px 0 4px 0;
	text-transform: ${props => props.upperCase || 'none'};
	::placeholder {
		color: #707070;
		opacity: 0.3;
	}
`;

export const CheckTextInput = styled.img`
	opacity: ${props => (props.opacity ? '1' : '0')};
	position: absolute;
	right: 4px;
	top: 16px;
`;

export const InputInfoPayment = styled.input``;

export const TextValidationInputPayment = styled.p`
	opacity: 1;
	color: #ff1673;
	font-size: 11px;
	font-family: Raleway;
	text-align: right;
	margin: 0 2px 0 0;
	@media (max-width: 780px) {
		padding-right: 20px;
	}
`;

export const Parrainage = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	margin-top: 15px;

	@media (max-width: 779px) {
		margin: 15px 20px 0;
	}
	@media (min-width: 1050px) {
		width: 100%;
	}
`;

export const TitleParrainage = styled.p`
	color: #707070;
	font-family: Raleway;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	@media (max-width: 779px) {
		padding-right: 10px;
	}
	@media (min-width: 1050px) {
		padding-bottom: 10px;
	}
`;

export const InputParrain = styled.input`
	height: 30px;
	padding: 5px;
	border-radius: 7px;
	border: 1px solid;
	border-color: ${props => (props.focused ? '#4373fd' : '#d3d3d3')};
	background-color: #ffffff;
	outline: none;
	color: ${props => props.theme.sunnyBlue};
	font-family: Lato;
	font-size: 14px;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	letter-spacing: 1.44px;

	@media (min-width: 1050px) {
		width: 100%;
	}
	@media (max-width: 1049px) {
		margin-left: 1em;
		width: 190px;
	}
`;

export const DivInputParrainage = styled.div`
	display: flex;
	align-items: center;
	position: relative;

	@media (min-width: 1050px) {
		width: 83%;
	}
`;

export const ValidationPicto = styled.img`
	opacity: ${props => props.opacity || 0};
	position: absolute;
	right: 5px;
`;

export const TextValidationCodeParrain = styled.p`
	color: ${props => props.textColor || '#707070;'};
	opacity: ${props => props.opacity || 0};
	margin: 0;
	text-align: left;
	font-size: 11px;
	font-family: Raleway;
	margin: 4px 0 0;
	@media (max-width: 780px) {
		padding-right: 20px;
	}
`;

export const ParentGreyLine = styled(DivGlobal)`
	@media (min-width: 780px) and (max-width: 1049px) {
		width: 90%;
	}
	@media (min-width: 1050px) {
		display: none;
	}
	width: 100%;
`;

export const GreyLine = styled.div`
	border-bottom: 1px solid #f3f3f3;
	width: 100%;
`;

export const DivContainerPictos = styled.div`
	display: flex;
	@media (min-width: 1050px) {
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		height: 100%;
	}
	@media (min-width: 780px) and (max-width: 1049px) {
		flex-direction: column;
		align-items: left;
		justify-content: ;
		align-items: flex-start;
	}
	@media (max-width: 780px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

// ==================Styles for PaymentStuff component================= //
// ==================Styles for InformationsPayment component================= //

export const InformationsPaymentDiv = styled(DivGlobal)`
	width: 20%;



	align-items: center;



	@media (max-width: 1049px) {
		display: none;
	}
`;

export const TextInformationsPayment = styled(DivGlobal)`
	height: 130px;
	border-radius: 10px;
	border: 1px solid #d3d3d3;
	background-color: rgba(243, 243, 243, 0.3);
	color: #707070;
	font-family: Raleway;
	font-size: 12px;
	font-weight: 500;
	padding: 4% 7%;
	line-height: 17px;
	align-items: center;

	@media (max-width: 1500px) {
		font-size: 12px;
	}

	@media (min-width: 1050px) and (max-width: 1300px){
		font-size: 10px;
	}
`;

export const BubbleImg = styled.img`
	width: 31px;
	height: 30px;
	padding-right: 8px;

	@media (min-width: 1050px) and (max-width: 1300px){
		width: 27px;
	}
`;

export const PictoInformationsPayment = styled(DivGlobal)`
	width: 100%;
	margin: 12px 0;
	align-items: flex-start;
	justify-content: space-evenly;
	display: ${props => (props.screenWidth > 780 ? 'none' : 'flex')};
	@media (max-width: 1049px) {
		flex-direction: column;
		padding: 0 1.4vw;
	}
	@media (min-width: 780px) and (max-width: 1049px) {
		align-items: center;
	}
	@media (max-width: 780px) {
		align-items: center;
	}
	@media (max-width: 520px) {
		align-items: center;
	}
	@media (min-width: 1050px) {
		height: 100%;
	}

`;

export const DivPictoPayment = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 10px;
	border-radius: 20px;
	border: 1px solid #f3f3f3;
	padding: 10px;
	@media (max-width: 1049px) {
		margin-right: 10px;
		width: 25px;
	}
`;

export const PictoSecurised = styled.img`
	width: 17px;
	height: 27px;
`;

export const PictoTenDays = styled.img`
	width: 25px;
	height: 25px;
`;

export const ParagraphInformationsPayment = styled.p`
	color: #707070;
	font-family: Raleway;
	font-size: 12px;
	font-weight: 500;
	margin: 0;

	@media(min-width: 1050px) {
		width: 77px;
		margin-top: 10px;
	}
`;

// ==================Styles for InformationsPayment component=============

export const DivPictoText = styled(FlexColumn)`
	max-width: 95px;
	justify-content: center;
	align-items: center;
	text-align: center;
	@media (max-width: 1049px) {
		align-items: center;
		flex-direction: row;
		max-width: 100%;
		margin: 10px 0;
	}
	@media (min-width: 1050px) {
		align-items: center;
		flex-direction: column;
		max-width: 100%;
		margin: 10px 0;
	}
`;

export const CardOfferTablet = styled.div`
	display: none;
	@media (min-width: 780px) and (max-width: 1049px) {
		display: block;
		margin: 4% 0 4%;
	}
`;

export const CardOfferDesktop = styled.div`
	width: 22%;
	min-width: 170px;
	@media (min-width: 1050px) {
		display: flex;
		align-items: center;
	}
	@media (max-width: 1049px) {
		display: none;
	}
`;

export const ContainerInputPayment = styled.div`
	width: 100%;
`;

export const ContainerPayment = styled(FlexColumn)`
	@media (max-width: 1049px) {
		padding: 3%;
	}
	@media (min-width: 1050px) {
		justify-content: start;
		align-items: start;
		padding-left: 3%;
	}

`;
