import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
	const [formState, setFormState] = useState();
	const [checkbox, setCheckbox] = useState({});
	const [cgv, setCgv] = useState(false);

	return (
		<FormContext.Provider value={[formState, setFormState, checkbox, setCheckbox, cgv, setCgv]}>
			{children}
		</FormContext.Provider>
	);
};

FormProvider.propTypes = {
	children: PropTypes.object,
};