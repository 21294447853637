import React, { useContext } from 'react';
import styled from 'styled-components';
import InputText from '../../../form/InputText';
import { FormContext } from '../../../context/FormsContext';

const Wrapper = styled.div`
	padding: 2em 2em 0em 2em;
`;

const StyledContainerInputsText = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const ContainerInputs = ({ inputs }) => {
	const [formState] = useContext(FormContext);

	const generateInputsText = () => {
		const arrToReturn = [];
		Object.entries(inputs).map((input) => {
			arrToReturn.push(
				<InputText
					key={input[1].id}
					input={input[1]}
					name={input[0]}
				/>
			);
		})
		return arrToReturn;
	}
	return (
		<Wrapper>
			<h2>Informations</h2>
			<StyledContainerInputsText>
				{generateInputsText()}
			</StyledContainerInputsText>
		</Wrapper>
	);
}

export default ContainerInputs;