import styled from 'styled-components';
import { FlexColumn, DivGlobal } from '../../../globalStyle';

export const Container = styled(FlexColumn)`
	flex-grow: 1;
	justify-content: space-around;
	display: ${props => (props.display === 'tablet' ? 'none' : 'flex')} @media
		(max-width: 1049px) {
		display: ${props => (props.display === 'tablet' ? 'flex' : 'none')};
		margin-bottom: 40px;
		border: 4px solid red;
	}
`;

export const Tip = styled(DivGlobal)`
	align-items: center;
	justify-content: space-evenly;
	span {
		@media (min-width: 1050px) {
			background-image: linear-gradient(
				180deg,
				rgba(212, 225, 244, 0.3) 0%,
				rgba(255, 255, 255, 0.3) 100%
			);
		}
		box-sizing: border-box;
		padding: 0.8em;
		border-radius: 10px;
	}

	@media (max-width: 1049px) {
		justify-content: flex-start;
		padding: 0 1vw;
		span {
			padding: 1vw;
		}
	}
`;

export const Image = styled.img`
	opacity: 1;
`;

export const Text = styled.p`
	margin:0;
	padding-right: 2em;
	color: #707070;
	max-width: 306px;
	font-family: Raleway;
	font-size: 13px;
	padding-left: 1em;
	box-sizing: border-box;

	@media (max-width: 1080px) {
		max-width: 100%;
		line-height: 1.64;
		margin: 10px 0;
	}
`;
