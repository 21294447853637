import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	StyledDropdownInput,
	StyledSelectList,
	StyledOption,
	SelectedElementContainer,
	DownArrowContainer,
	SelectedTextContainer
} from './style';

import arrowDown from '../../../assets/form/dropdownInput/arrow_down.svg';
import arrowDownWhite from '../../../assets/form/dropdownInput/arrow_down_white.svg';

const DropdownInput = ({ arrOfAnswers, handleClick, isActive }) => {
	const listOfChoices = arrOfAnswers.map(choice => choice.value);
	const [currentSelection, setSelection] = useState('Sélectionnez votre réponse');
	const [isOpen, setIsOpen] = useState(0); // default open/closed state

	const onDropdownClick = (e) => {
		if (!isActive) {
			return;
		}
		e.preventDefault();
		setIsOpen(!isOpen);
	};

	const onOptionClick = (e, choice) => {
		setSelection(choice.value);
		handleClick({ answerClicked: choice });
	};
	return (
		<StyledDropdownInput
			onClick={onDropdownClick}
			isOpen={isOpen}
			isActive={isActive}
		>
			<SelectedElementContainer>
				<SelectedTextContainer isActive={isActive}>
					<p>{currentSelection}</p>
				</SelectedTextContainer>
				<DownArrowContainer isOpen={isOpen}>
					<img
						src={(isActive && arrowDown) || arrowDownWhite}
						alt="arrow down"
					/>
				</DownArrowContainer>
			</SelectedElementContainer>

			<StyledSelectList>
				{arrOfAnswers.map(choice => (
					<StyledOption
						key={choice.key}
						value={choice.value}
						onClick={e => onOptionClick(e, choice)}
					>
						{choice.value}
					</StyledOption>
				))}
			</StyledSelectList>
		</StyledDropdownInput>
	);
};

export default DropdownInput;

DropdownInput.propTypes = {
	arrOfAnswers: PropTypes.array,
	isActive: PropTypes.bool,
	handleClick: PropTypes.func
};
