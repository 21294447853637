import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';

const getBorderColor = (focused, error) => {
	let color;
	if (focused === 'true') color = '#94d9ea';
	else if (error) color = '#ff98b9';
	else color = '#d3d3d3';
	return color;
};

const AnimatedFieldSet = styled(animated.fieldset)`
	width: ${props => (props.restyle ? '100%' : '47.5%')};
	width: ${props => props.restyle ? '100%' : props.width === 'general' ? '100%' : '47.5%'};
	height: 55px;
	border-radius: 7px;
	border: 1px solid #d3d3d3;
	box-sizing: border-box;
	margin: 10px 0;
	border-color: ${props => getBorderColor(props.focused, props.error)};

	@media (max-width: 1080px) {
		padding: 0;
		width: 100%;
		border-width: 0 0 1px 0;
		border-radius: 0px;
	}

	@media (max-width: 1080px) {
		margin-right: ${props => props.hasnooffer === 0 && '3%'};
		margin-left: ${props => props.hasnooffer === 0 && '3%'};
		height: ${props => props.ispayment === 1 && '48px'};
		padding-bottom: ${props => (props.ischrome === 1 && props.hasnooffer === 0) && '8px'};
	}
`;

const FieldSet = ({
	children,
	focused,
	error,
	restyle,
	customStyle,
	width,
	ispayment,
	hasnooffer,
	ischrome
}) => {
	const ErrorAnimProps = useSpring({
		x: error ? 1 : 0,
		config: { duration: 1000 }
	});

	return (
		<AnimatedFieldSet
			ischrome={ischrome ? 1 : 0}
			ispayment={ispayment}
			hasnooffer={hasnooffer}
			width={width}
			focused={focused ? 1 : 0}
			error={error}
			restyle={restyle ? 1 : 0}
			style={{
				...customStyle,
				transform: !focused
					? ErrorAnimProps.x
						.interpolate({
							range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
							output: [0, 5, -5, 4, -4, 2, -2, 0]
						})
						.interpolate(x => `translateX(${x}px)`)
					: 'translateX(0px)'
			}}
		>
			{children}
		</AnimatedFieldSet>
	);
};

export default FieldSet;

FieldSet.propTypes = {
	children: PropTypes.node,
	focused: PropTypes.bool,
	error: PropTypes.string,
	restyle: PropTypes.bool
};
