/* eslint-disable guard-for-in */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';
import {
	StyledOfferHeader,
	StyledContainerProduct,
	StyledPictureProduct,
	StyledHeadings,
	StyledPriceTag,
	StyledGradedModel,
	StyledContainerLabels,
	StyledLabel,
	StyledGradingImage
} from './style';
import tag from '../../../../assets/form/offerCard/tag.svg';

const OfferHeader = ({ offer, platform }) => {
	const { offer: price, model, score, answers } = offer;
	// grading is picked on 'score' second digit. From 1 to 6.
	const gradingScale = ['shiny', 'or', 'argent', 'bronze', 'stallone', 'hs'];
	const grading = gradingScale[score.charAt(1) - 1];

	const getLabelToDisplay = ({ objectAnswers }) => {
		const arrToReturn = [];
		for (const element in objectAnswers) {
			if (
				![
					'screen_state',
					'functionnal',
					'case_state',
					'cellular',
					'touchbar'
				].includes(element)
			) {
				const valueElement = objectAnswers[element];
				if (valueElement) {
					arrToReturn.push(
						<StyledLabel key={valueElement}>{valueElement}</StyledLabel>
					);
				}
			}
		}
		return arrToReturn;
	};
	const product = model.toLowerCase().split(' ')[0];
	const productName =
		product === 'apple' ? model.toLowerCase().split(' ')[1] : product;
	const imageProduct = require(`../../../../assets/estimator/${productName}/${model
		.toLowerCase()
		.split(' ')
		.join('_')
		.replace(/[\350-\353]/g, 'e')}.jpg`);

	const getModel = () => {
		let right = '';
		if (['watch', 'iphone'].includes(productName)) {
			right = '35px';
		} else if (productName === 'macbook') {
			right = '10px';
		} else if (productName === 'ipad') {
			right = '20px';
		} else {
			right = '20px';
		}
		return right;
	};

	return (
		<StyledOfferHeader>
			<StyledContainerProduct>
				<StyledPictureProduct model={productName}>
					<img src={imageProduct} alt={model} />
					<StyledGradingImage
						src={require(`../../../../assets/form/offerCard/${grading}-outline.svg`)}
						alt="évaluation qualité"
						right={getModel()}
					/>
				</StyledPictureProduct>
				<StyledHeadings>
					<StyledPriceTag>
						<img src={tag} alt="étiquette de prix" />
						<p>
							&nbsp;&nbsp;
							{price} €<span> ttc</span>
						</p>
					</StyledPriceTag>
					<StyledGradedModel>
						<h3>{model}</h3>
						<img
							style={{ width: '75px' }}
							src={
								platform === 'mobile'
									? require(`../../../../assets/form/offerCard/${grading}-plaque-mobile.svg`)
									: require(`../../../../assets/form/offerCard/${grading}-plaque.svg`)
							}
							alt="évaluation qualité"
						/>
					</StyledGradedModel>
				</StyledHeadings>
			</StyledContainerProduct>
			<StyledContainerLabels>
				{getLabelToDisplay({ objectAnswers: answers })}
			</StyledContainerLabels>
		</StyledOfferHeader>
	);
};

export default OfferHeader;

OfferHeader.propTypes = {
	offer: PropTypes.object,
	platform: PropTypes.string
};

// apple_watch_1ère_génération.jpg'
