/* eslint-disable no-console */
import axios from 'axios';
import { Parser } from 'json2csv';

import config from '../../config/endpoints.js';

const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';
const baseApi = config[env].baseAPI;

async function generateCSV ({ jsonToParse, fields, fileName = 'products' }) {
	try {
		const json2csvParser = new Parser({ fields });
		const csv = await json2csvParser.parse(jsonToParse);

		const hiddenElement = document.createElement('a');
		hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
		hiddenElement.target = '_blank';
		hiddenElement.download = `${fileName}.csv`;
		hiddenElement.click();
		hiddenElement.remove()

	} catch (error) {
		console.error (error)
	}
}

async function getEstimations({ user_id } = { }) {
	try {
		const res = await axios.get(`${baseApi}/estimations${user_id ? `?user_id=${user_id}` : ''}`)
		if (res) { return res.data; }
	} catch (error) {
		console.error(error);
		return error;
	}
	return {}
}

async function generateEstimations({ offer_ids }) {
	try {
		const res = await axios.post(`${baseApi}/expeditions/generate`, {
			offer_ids
		})
		if (res) { return res.data; }
	} catch (error) {
		// clearToken();
		console.error(error);
		return error;
	}
	return {}
}

export { getEstimations, generateEstimations, generateCSV };
