/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const StyledNavButton = styled.button`
	border: none;
	user-select: none;
	width: 170px;
	height: 46px;
	border-radius: 30px;
	align-items: center;
	cursor: pointer;
	margin: 20px;
	justify-content: space-evenly;
	display: flex;

	&:focus {
		outline: 0;
	}

	${props => props.direction === 'previous'
		&& css`
			background-color: rgba(248, 248, 248, 0.6);
			color: rgba(112, 112, 112, 0.3);
			flex-direction: row-reverse;
		`}

	${props => props.direction === 'next'
		&& css`
			background-color: ${props.theme.blueGrey};
			color: ${props => props.theme.greenGrey};
			flex-direction: row;
			padding-left: 30px;
			justify-self: flex-end;
		`}

	@media (max-width: 600px) {
		width: 40px;
		height: 40px;
		border-radius: 30px;
		content: none;
		padding: 0;
		margin: 0;
		margin: ${props => props.direction === 'previous' ? '0 0 0 20px' : '0 20px 0 0'};
	}

	& p {
		text-align: center;
		font-family: ${props => props.theme.mainFontFamily};
		font-size: 20px;
		margin: 0;

		@media (max-width: 600px) {
			display: none;
		}
	}
`;
