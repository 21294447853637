import React, { useState, useEffect } from 'react';
import { StyledWrapper, StyledContainerNavbar } from '../Navbar/style'
import ContainerTabs from './containers/ContainerTabs/index';
import styled from 'styled-components';
import { withRouter } from "react-router"
import { EmptyLine } from '../../globalStyle';

const Div = styled.div`
	height: 50px;
	width: ${(props) => props.width ? props.width : '100%'};
    display: flex;
	align-items: center;
`;

const Search = styled.input`
	width: 25%;
	height: 35px;
	outline: none;
`;
const StyledWrapperTabs = styled(StyledWrapper)`
	padding: 0;
`;

const StyledContainerNavTabs = styled(StyledContainerNavbar)`
	height: auto;
	align-items: center;
`;


const NavbarTab = ({ location, empty, onSearch, isSearchBar }) => {
	const [ready, setReady] = useState(false);
	const [value, setValue] = useState('')
	useEffect(() => {
		return setReady(true);
	}, [])

	const handleEnter = (e) =>  e.key === 'Enter' && onSearch (e)

	const handleChange = (e) => {
		setValue(e.target.value);
		if (!e.target.value) return onSearch (e)
	}
	return ready && (
		<StyledWrapperTabs>
			<StyledContainerNavTabs>
				{empty &&
					<>
						<Div>
							<EmptyLine width={["50px", "45px", "40px"]} height={["24px", "20px", "16px"]} light />
							<EmptyLine width={["50px", "45px", "40px"]} height={["24px", "20px", "16px"]} />
							<EmptyLine width={["50px", "45px", "40px"]} height={["24px", "20px", "16px"]} light />
							<EmptyLine width={["50px", "45px", "40px"]} height={["24px", "20px", "16px"]} />
						</Div>
						<Div width="25%">
							<EmptyLine width={['100%', '100%', '100%']} height={['24px', '20px', '16px']} light />
						</Div>
					</>
				||
					<>
						<ContainerTabs location={location.pathname} />
						{isSearchBar && <Search placeholder="'Entree' pour valider la recherche" type="text" onKeyDown={handleEnter} onChange={handleChange} value={value} ></Search> || null}
					</>
				}
			</StyledContainerNavTabs>
		</StyledWrapperTabs>
	);
}

export default withRouter(NavbarTab);