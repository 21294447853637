import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, config } from 'react-spring';

import {
	StyledOverlay,
	StyledModalContainer,
	StyledModalCard,
	StyledModalBody,
	StyledCloseButton,
	StyledFooterCloseButton
} from './style';
import imgCross from '../../../../assets/form/menuPictos/cross.svg';

const Modal = ({
	show,
	onCloseClick,
	bgARGB,
	contentType,
	platform,
	children
}) => {
	const animFadeOverlay = useSpring({
		opacity: show ? 1 : 0
	});

	const animSlideInTop = useSpring({
		transform: show ? 'translateY(0vh)' : 'translateY(-100vh)',
		config: config.stiff
	});

	// if (show) $crisp.push(['do', 'chat:hide']);

	const node = useRef();

	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		}

		// outside click
		onCloseClick();
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
			e.preventDefault();
			onCloseClick();
		}
	};

	useEffect(() => {
		// add when mounted
		document.addEventListener('mousedown', handleClick);
		document.addEventListener('keydown', handleKeyDown);

		// return function to be called when unmounted
		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<StyledOverlay style={animFadeOverlay} open={show} bgargb={bgARGB}>
			<StyledModalContainer>
				<StyledModalCard
					ref={node}
					style={animSlideInTop}
					open={show}
					type={contentType}
				>
					{platform !== 'mobile' && (
						<StyledCloseButton type={contentType} onClick={onCloseClick}>
							<img src={imgCross} alt="Close button" />
						</StyledCloseButton>
					)}

					<StyledModalBody type={contentType}>{children}</StyledModalBody>
					{platform === 'mobile' && (
						<StyledFooterCloseButton onClick={onCloseClick}>
							Fermer
						</StyledFooterCloseButton>
					)}
				</StyledModalCard>
			</StyledModalContainer>
		</StyledOverlay>
	);
};

export default Modal;

Modal.propTypes = {
	show: PropTypes.bool,
	onCloseClick: PropTypes.func,
	bgARGB: PropTypes.string,
	contentType: PropTypes.string,
	children: PropTypes.node,
	platform: PropTypes.string
};
