const listDevices = [
	{
		name: 'Apple Watch',
		image: require('../../assets/cards/photos_products/applewatch.png'),
		background: require('../../assets/cards/background_down.png'),
		icon: require('../../assets/cards/pictos_products/applewatch-bland.svg'),
		redirect: 'watch'
	},
	{
		name: 'iPhone',
		image: require('../../assets/cards/photos_products/iphone.png'),
		icon: require('../../assets/cards/pictos_products/iphone-bland.svg'),
		redirect: 'iphone'
	},
	{
		name: 'Galaxy',
		// image: require('../../assets/cards/photos_products/iphone.png'),
		// icon: require('../../assets/cards/pictos_products/iphone-bland.svg'),
		redirect: 'galaxy'
	},
	{
		name: 'MacBook',
		image: require('../../assets/cards/photos_products/macbook.png'),
		background: require('../../assets/cards/background_down.png'),
		icon: require('../../assets/cards/pictos_products/macbook-bland.svg'),
		redirect: 'mac'
	},
	{
		name: 'iPad',
		image: require('../../assets/cards/photos_products/ipad.png'),
		icon: require('../../assets/cards/pictos_products/ipad-bland.svg'),
		redirect: 'ipad'
	},
	{
		name: 'Autres',
		image: require('../../assets/cards/photos_products/autres.png'),
		background: require('../../assets/cards/background_down.png'),
		toResize: true,
		icon: require('../../assets/cards/pictos_products/other.svg'),
	}
];

export default listDevices;
