/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container, Text, DivCheckboxText, TextCheckBox } from './style';
import Checkbox from '../../form/Checkbox';
import { FormContext } from '../../context/FormsContext';



function Conditions({ screenWidth }) {
	const [, , , , cgv, setCgv] = useContext(FormContext);
	const [ready, setReady] = useState(false);
	const handleAccept = () => {
		setCgv(!cgv);
	}
	return (
		<Wrapper
			background={cgv}
			screenWidth={screenWidth}
			isBook
		>
			<Container background={cgv}>
				<Text> Je confirme que les informations fournies sont exactes. J’ai bien compris que mon appareil sera vérifié avant de recevoir le paiement.  Si mon produit ne correspond pas à ma description, une contre-offre sera effectuée. </Text>
				<label>
					<DivCheckboxText>
						<Checkbox checked={cgv} onChange={handleAccept} />
						<TextCheckBox>
							J’accepte les&nbsp;
							<span>
								<a href="/conditions" target="blank">conditions d’utilisation</a>, de confidentialité et d’envoi.
							</span>
						</TextCheckBox>
					</DivCheckboxText>
				</label>
			</Container>
		</Wrapper>
	);
}

Conditions.propTypes = {
	accepted: PropTypes.bool,
	handleAccept: PropTypes.func,
	screenWidth: PropTypes.number,
};

export default Conditions;
