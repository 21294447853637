import React from 'react';
import styled from 'styled-components';
import {
	Page,
	// Avatar,
	// Icon,
	Grid,
	// Card,
	// Text,
	// Table,
	// Alert,
	// Progress,
	// colors,
	// Dropdown,
	// Button,
	// StampCard,
	// StatsCard,
	// ProgressCard,
	// Badge,
  } from "tabler-react";
  import { EmptyLine } from '../../globalStyle';

  const Wrapper = styled.div`
	width: 90%;
	margin: auto;
`;

const LazyHome = () => {
	return (
		<Wrapper>
				<Page.Content title="Dashboard">
					<Grid.Row cards={true}>
						<Grid.Col width={6} sm={4} lg={2}>
							<EmptyLine marginBottom width={["170px", "140px", "110px"]} height={["120px", "110px", "100px"]} light notRadius={0}/>
							{/* <StatsCard layout={1} movement={6} total="43" label="New Tickets" /> */}
						</Grid.Col>
						<Grid.Col width={6} sm={4} lg={2}>
							<EmptyLine marginBottom width={["170px", "140px", "110px"]} height={["120px", "110px", "100px"]} notRadius={0}/>
							{/* <StatsCard
							layout={1}
							movement={-3}
							total="17"
							label="Closed Today"
							/> */}
						</Grid.Col>
						<Grid.Col width={6} sm={4} lg={2}>
							<EmptyLine marginBottom width={["170px", "140px", "110px"]} height={["120px", "110px", "100px"]} light notRadius={0}/>
							{/* <StatsCard layout={1} movement={9} total="7" label="New Replies" /> */}
						</Grid.Col>
						<Grid.Col width={6} sm={4} lg={2}>
							<EmptyLine marginBottom width={["170px", "140px", "110px"]} height={["120px", "110px", "100px"]} notRadius={0}/>
							{/* <StatsCard
							layout={1}
							movement={3}
							total="27.3k"
							label="Followers"
							/> */}
						</Grid.Col>
						<Grid.Col width={6} sm={4} lg={2}>
							<EmptyLine marginBottom width={["170px", "140px", "110px"]} height={["120px", "110px", "100px"]} light notRadius={0}/>
							{/* <StatsCard
							layout={1}
							movement={-2}
							total="$95"
							label="Daily earnings"
							/> */}
						</Grid.Col>
						<Grid.Col width={6} sm={4} lg={2}>
							<EmptyLine marginBottom width={["170px", "140px", "110px"]} height={["120px", "110px", "100px"]} notRadius={0}/>
							{/* <StatsCard layout={1} movement={-1} total="621" label="Products" /> */}
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<EmptyLine width={["274px", "254px", "234px"]} height={["72px", "62px", "52px"]} light marginBottom notRadius={0}/>
							{/* <StampCard
							color="blue"
							icon="dollar-sign"
							header={
								<span>
								132 <small>Sales</small>
								</span>
							}
							footer={"12 waiting payments"}
							/> */}
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<EmptyLine width={["274px", "254px", "234px"]} height={["72px", "62px", "52px"]} notRadius={0}/>
							{/* <StampCard
							color="green"
							icon="shopping-cart"
							header={
								<span>
								78 <small>Orders</small>
								</span>
							}
							footer={"32 shipped"}
							/> */}
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<EmptyLine width={["274px", "254px", "234px"]} height={["72px", "62px", "52px"]} light notRadius={0}/>
							{/* <StampCard
							color="red"
							icon="users"
							header={
								<span>
								1,352 <small>Members</small>
								</span>
							}
							footer={"163 registered today"}
							/> */}
						</Grid.Col>
						<Grid.Col sm={6} lg={3}>
							<EmptyLine width={["274px", "254px", "234px"]} height={["72px", "62px", "52px"]} notRadius={0} />
							{/* <StampCard
							color="yellow"
							icon="message-square"
							header={
								<span>
								132 <small>Comments</small>
								</span>
							}
							footer={"16 waiting"}
							/> */}
						</Grid.Col>
					</Grid.Row>
				</Page.Content>
			</Wrapper>
	);
}

export default LazyHome;