/* eslint-disable no-console */
import axios from 'axios';

import config from '../../config/endpoints.js';

const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';
const baseApi = config[env].baseAPI;

async function sendCallInvoices({ body, partner_id }) {
	try {
		const res = await axios({
			url: `${baseApi}/invoices/ask_invoice/${partner_id}`,
			method: 'post',
			data: body,
			validateStatus: function (status) {
				return status >= 200;
			}
		})
		return res.data;
	} catch (error) {
		console.error(error);
		return error;
	}
}

async function getInvoices({ user_id }) {
	try {
		const res = await axios({
			url: `${baseApi}/invoices${user_id ? `?user_id=${user_id}` : ''}`,
			method: 'get',
			validateStatus: function (status) {
				return status >= 200;
			}
		})
		return res.data;
		
	} catch (error) {
		console.error(error);
		return error;
	}
}

async function getInvoice({ id, user_id }) {
	try {
		const res = await axios({
			url: `${baseApi}/invoices/${id}${user_id ? `?user_id=${user_id}` : ''}`,
			method: 'get',
			validateStatus: function (status) {
				return status >= 200;
			}
		})
		return res.data;
		
	} catch (error) {
		console.error(error);
		return error;
	}
}

export { getInvoice, getInvoices, sendCallInvoices };