const jwt = require('jsonwebtoken');

const secret = 'thisIsASecret';

export const createToken = (payload) => {
	// CHANGE EXPIRATION TO 5MIN
	const token = jwt.sign(payload, secret);
	return token;
};

export const traduceToken = (token) => {
	try {
		const traduced = jwt.verify(token, secret);
		return traduced;
	} catch (err) {
		console.error(err);
		return false;
	}
};
