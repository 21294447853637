/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import OfferHeader from '../../Informations/OfferHeader';
import OfferFeatures from '../../Informations/OfferFeatures';
import OfferNextSteps from '../../Informations/OfferNextSteps';
import OfferReceiveButton from '../../Informations/OfferReceiveButton';
import NavButton from '../../NavButton';
import { createToken } from '../../../../controllers/token/jwt';

import { PlatformContext } from '../../../context/PlatformContext';
import { postUser, assignEstimation } from '../../../../controllers/book/API';

import {
	StyleContentNoOffer,
	ContainerContentNoOffer,
	StyleTitleNoOffer,
	StyledOfferCard,
	ContainerNoOffer,
	SeparatorNoOffer,
	StyleTitleInput,
	StyleSubtitleInput,
	ContainerInput,
	StyleButtonMail,
	ContainerContentGlobal,
	StyleContainerMailSent
} from './styles';

import noOfferInput from '../../../../controllers/estimator/noOfferInput';
import InputText from '../../InputText';

import sendArrow from '../../../../assets/form/send_arrow.svg';

const ContainerOffer = ({ offer, onClickReturnOffer, nextAction }) => {
	const [platform, setPlatform] = useContext(PlatformContext);
	const [messageMailSent, setMessageMailSent] = useState(null);

	const valueOffer = offer.offer;
	const conditionOffer = typeof valueOffer === 'number' && valueOffer > 0;


	useEffect(() => {
		// if (platform === 'mobile') {
		// 	$crisp.push(['do', 'chat:hide']);
		// } else {
		// 	$crisp.push(['do', 'chat:show']);
		// }
	}, []);

	const goTo = async () => {
		if (conditionOffer) {
			const token = await createToken(offer);
			window.localStorage.setItem('estimatorUrl', window.location.pathname);
			nextAction({ offerId: offer.offer_id })
		} else {
			nextAction();
		}
	};

	const StyledContainerOfferFooter = styled.div`
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	`;

	const sendEmailValue = async () => {
		if (noOfferInput[0].state.isCorrect) {
			const body = {
				mail: noOfferInput[0].state.value,
			};
			const user = await postUser({ body, role: 'user' });
			if (user) {
				const res = await assignEstimation({ userId: user.id, offerIds: [offer.offer_id] });
				if (res) {
					setMessageMailSent(true);
				}
			}
		} else {
			setMessageMailSent(false);
		}
	};

const valueText = 'Valider la reprise';

	return (
		<StyledOfferCard>
			{conditionOffer
				? <StyledOfferCard>
					<OfferHeader offer={offer} platform={platform} />
					<OfferFeatures score={offer.score} />
					{platform === 'desktop' && <OfferNextSteps model={offer.model} />}
					{platform === 'desktop' ? (
						<OfferReceiveButton valueText={valueText} goTo={goTo} />
					) : (
						<StyledContainerOfferFooter>
							<NavButton
								direction="previous"
								handleClick={onClickReturnOffer}
							/>
							<OfferReceiveButton valueText={valueText} goTo={goTo} medium />
						</StyledContainerOfferFooter>
					)}
					 </StyledOfferCard>
				: <ContainerNoOffer>
					<ContainerContentGlobal>
						<StyleTitleNoOffer>
							Notre estimateur n’est actuellement pas disponible pour ce modèle.
						</StyleTitleNoOffer>
						<ContainerContentNoOffer>
							<StyleContentNoOffer mobileDisplay>
							Nous sommes désolés de cette situation mais nous savons que nous ne pourrons pas
							fournir à votre
							&nbsp;{offer.model || 'appareil'}&nbsp;
							la seconde vie qu’il mérite… pour l’instant !
							</StyleContentNoOffer>
							<StyleContentNoOffer mobileDisplay={false}>
							La bonne nouvelle ?
								{' '}
								<br />
							Nos équipes travaillent d’arrache pied et nous serons de retour au plus vite, plus en forme que jamais.
							</StyleContentNoOffer>
							<StyleContentNoOffer mobileDisplay={false}>
							On vous tient au courant très vite, promis ! 💙
							</StyleContentNoOffer>
						</ContainerContentNoOffer>
						<SeparatorNoOffer />
						<ContainerInput>
							<StyleTitleInput>On vous tient au courant ?</StyleTitleInput>
							<StyleSubtitleInput>
								Laissez-nous vos coordonnées !
							</StyleSubtitleInput>
							<InputText inputs={noOfferInput} hasnooffer={1} />
							{messageMailSent ?
								<StyleContainerMailSent>
									Votre mail a bien été enregistré
								</StyleContainerMailSent>
								:
								<StyleButtonMail onClick={sendEmailValue}>
							Envoyer
									<img src={sendArrow} style={{ marginLeft: '10px' }} alt="send_icon" />
								</StyleButtonMail>
							}
						</ContainerInput>
					</ContainerContentGlobal>
					{platform === 'desktop' ? (
						<OfferReceiveButton
							hasPicto
							valueText={valueText}
							goTo={goTo}
							height="50px"
						/>
					) : (
						<StyledContainerOfferFooter>
							<NavButton
								direction="previous"
								handleClick={onClickReturnOffer}
							/>
							<OfferReceiveButton
								valueText={valueText}
								goTo={goTo}
								medium
								width="207px"
							/>
						</StyledContainerOfferFooter>
					)}
				</ContainerNoOffer>
			}
		</StyledOfferCard>
	);
};

ContainerOffer.propTypes = {
	nextAction: PropTypes.func,
	offer: PropTypes.object,
	onClickReturnOffer: PropTypes.func
};

export default withRouter(ContainerOffer);
