const clearToken = () => {
	[
		'partnerToken',
		'partnerRole',
		'partnerId',
		'is_admin',
		'partners_whitelist',
		'partner_admin',
		'id',
		'email'
	].map((item) => {
		window.localStorage.removeItem(item);
	})
	window.location.href = '/login'
}

export default clearToken;
