import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { Container, Text, Image } from './style';
import Bills from '../../../assets/book/picto/bills.svg';
import Balloons from '../../../assets/book/picto/balloons.svg';
import France from '../../../assets/book/picto/france.svg';
import Plant from '../../../assets/book/picto/plant.svg';

const tips = [
	{
		id: 1,
		image: France,
		text:
			'Le reconditionnement et le recyclage s’effectuent sur notre beau territoire français…',
		alt: 'France flag'
	},
	{
		id: 2,
		image: Plant,
		text:
			'… dans le respect des normes DEEE (déchets d’équipements électriques et électroniques).',
		alt: 'plant'
	},
	{
		id: 3,
		image: Bills,
		text:
			'Prix professionnel garantit (sous réserve d\'une estimation conforme)',
		alt: 'bills'
	},
	// {
	// 	id: 4,
	// 	image: Bills,
	// 	text:
	// 		'On vous transfère l’argent sur votre compte, il ne vous reste plus qu’à vous faire plaisir.',
	// 	alt: 'bills'
	// }
];

const Tip = styled(animated.div)`
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	span {
		@media (min-width: 1050px) {
			background-image: linear-gradient(
				180deg,
				rgba(212, 225, 244, 0.3) 0%,
				rgba(255, 255, 255, 0.3) 100%
			);
		}
		box-sizing: border-box;
		padding: 0.8em;
		border-radius: 10px;
	}

	@media (max-width: 1080px) {
		justify-content: flex-start;
		padding: 0 1vw;
		span {
			padding: 1vw;
		}
	}
`;
function ListContainer() {
	return (
		<Container>
			{tips.map(tip => (
				<Tip
					key={tip.id}
					// style={useSpring({
					// 	transform: 'translateX(0px)',
					// 	opacity: 1,
					// 	from: { transform: 'translateX(70px)', opacity: 0 },
					// 	delay: tip.id * 500 - 500
					// })}
				>
					<span>
						<Image alt={tip.alt} src={tip.image} />
					</span>
					<Text>{tip.text}</Text>
				</Tip>
			))}
		</Container>
	);
}

export default ListContainer;
