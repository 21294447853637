/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import { TextInput } from '../book/style';

class GoogleAutoComplete extends Component {
	constructor(props) {
		super(props);
		// Declare State
		this.state = {
			value: '',
			query: ''
		};
		// Bind Functions
		this.handleScriptLoad = this.handleScriptLoad.bind(this);
		this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleScriptLoad() {
		// Declare Options For Autocomplete
		const options = { types: ['address'], componentRestrictions: { country: ['fr', 'be'] } };
		// Initialize Google Autocomplete
		/* global google */
		this.autocomplete = new google.maps.places.Autocomplete(
			document.getElementById('autocomplete'),
			options
		); // Fire Event when a suggested name is selected
		this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
	}

	handlePlaceSelect() {
		// Extract City From Address Object
		const addressObject = this.autocomplete.getPlace();
		const address = addressObject.address_components;
		const { onChoose } = this.props;
		// Check if address is valid
		if (address) {
			this.setState({
				value: addressObject.formatted_address,
				query: addressObject.formatted_address
			});
			onChoose({
				e: { target: { value: addressObject.formatted_address, address_components: address } }
			});
		}
	}

	handleChange(e) {
		const { setCorrect, onChange } = this.props;
		setCorrect(false);
		if (!e.target.value.length) { onChange(e); }
		this.setState({
			query: e.target.value
		});
	}

	render() {
		const { placeholder } = this.props;
		const { query } = this.state;
		const { onBlur, onFocus, value } = this.props;
		return (
			<div style={{ width: '100%' }}>
				<Script
					url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA7jfIwY5BLGXeQYeuLlAqMAua9a7chs2k&libraries=places&language=fr"
					onLoad={this.handleScriptLoad}
				/>
				<TextInput
					size="16px"
					id="autocomplete"
					placeholder={placeholder}
					value={query || value}
					onChange={this.handleChange}
					onBlur={onBlur}
					onFocus={onFocus}
				/>
			</div>
		);
	}
}

GoogleAutoComplete.propTypes = {
	value: PropTypes.string,
	setCorrect: PropTypes.func,
	onChoose: PropTypes.func,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	placeholder: PropTypes.string,
	onFocus: PropTypes.func
};

export default GoogleAutoComplete;
